import { useState, useEffect } from 'react'
import { Navbar, Nav, NavDropdown, Modal } from 'react-bootstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { slotAPI } from '../../api/saili'

const momentTz = require('moment-timezone')

const Index = (props) => {
  const {
    active,
    get_estimate_visibility,
    delivery_slot_visibility,
    delivery_feature_visibility,
  } = props
  const [getEstimateModalShow, setGetEstimateModalShow] = useState(false)
  const [deliverySlotModalShow, setDeliverySlotModalShow] = useState(false)
  const [deliveryFeatureModalShow, setDeliveryFeatureModalShow] = useState(
    false
  )

  return (
    <div className='app-header'>
      <div className='container-fluid'>
        <Navbar expand='lg' variant='light'>
          <Navbar.Brand href='/orders'>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.svg`}
              alt='Saili'
              className='header-logo'
            />
            <span className='logo-text'>Saili </span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto'>
              {/* {get_estimate_visibility == true ? (
                <a
                  href=''
                  onClick={(e) => {
                    e.preventDefault()
                    setGetEstimateModalShow(true)
                  }}
                  className='nav-link'
                >
                  <span className='material-icons'>calculate</span> Get an
                  Estimate
                </a>
              ) : null} */}

              {/* {delivery_slot_visibility == true ? (
                <a
                  href=''
                  onClick={(e) => {
                    e.preventDefault()
                    setDeliverySlotModalShow(true)
                  }}
                  className='nav-link'
                >
                  <span className='material-icons'>table_rows</span> Delivery
                  Slots
                </a>
              ) : null}

              {delivery_feature_visibility == true ? (
                <a
                  href=''
                  onClick={(e) => {
                    e.preventDefault()
                    setDeliveryFeatureModalShow(true)
                  }}
                  className='nav-link'
                >
                  <span className='material-icons'>assignment</span> Available
                  Features
                </a>
              ) : null} */}

              {/* <Link
                to='/inbox'
                className={active == 'inbox' ? 'nav-link active' : 'nav-link'}
              >
                <span className='material-icons'>inbox</span> Inbox
              </Link> */}
              <NavDropdown
                title={
                  <div style={{ display: 'inline-block' }}>
                    <span className='material-icons'>settings</span> Settings
                  </div>
                }
                id='basic-nav-dropdown'
              >
                <Link to='/my-profile' className='dropdown-item'>
                  <span className='material-icons'>person</span> Profile
                </Link>

                <Link to='/logout' className='dropdown-item'>
                  <span className='material-icons'>logout</span> Logout
                </Link>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <GetEstimateModal
          visible={getEstimateModalShow}
          onHide={setGetEstimateModalShow}
        />
        <DeliverySlotModal
          visible={deliverySlotModalShow}
          onHide={setDeliverySlotModalShow}
        />

        <DeliveryFeatureModal
          visible={deliveryFeatureModalShow}
          onHide={setDeliveryFeatureModalShow}
        />
      </div>
    </div>
  )
}

const GetEstimateModal = (props) => {
  const { visible, onHide } = props
  return (
    <Modal
      size='xl'
      show={visible}
      onHide={() => onHide(false)}
      aria-labelledby='get-estimate-title-xl'
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id='get-estimate-title-xl'>Get an Estimate</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-body'></Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-primary btn-lg'
          onClick={() => alert('Calculate')}
        >
          Calculate
        </button>
      </Modal.Footer>
    </Modal>
  )
}

const DeliverySlotModal = (props) => {
  const { visible, onHide } = props
  const [availableSlots, setAvailableSlots] = useState()

  useEffect(async () => {
    if (visible) {
      var availableSlots = await slotAPI.get()
      if (availableSlots.status == 'SUCCESS') {
        setAvailableSlots(availableSlots.data)
      }
    }

    return () => {}
  }, [visible])

  return (
    <Modal
      size='xl'
      show={visible}
      onHide={() => onHide(false)}
      aria-labelledby='delivery-slot-title-xl'
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id='delivery-slot-title-xl'>
          Available Delivery Slots
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-body'>
        {availableSlots ? (
          <div className='row'>
            {availableSlots.map((slotGroup, index) => {
              var slotItemBlock = slotGroup.slots.map((slot, index) => {
                return (
                  <li key={`slot-${index}`} className='bg-white'>
                    <div className='row'>
                      <div className='col'>
                        Pickup
                        <br />
                        {slot.pickup_slot_label}
                      </div>
                      <div className='col'>
                        Delivery
                        <br />
                        {slot.delivery_slot_label}
                      </div>
                      <div className='col'>
                        Expires On
                        <br />
                        {momentTz
                          .tz(slot.confirm_before, 'Asia/Kathmandu')
                          .format('Do MMM, h:mm A')}
                      </div>
                    </div>
                  </li>
                )
              })
              return (
                <div className='col-sm-12' key={`slot-group-${index}`}>
                  <h6
                    className={
                      index != 0
                        ? 'mb-3 mt-4 font-weight-bold'
                        : 'mb-3 font-weight-bold'
                    }
                  >
                    {/* {slotGroup.slot_group_name} */}
                    {moment(slotGroup.date_time).format('dddd, Do MMM, YYYY')}
                  </h6>
                  <ul className='slot-list'>{slotItemBlock}</ul>
                </div>
              )
            })}
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-secondary btn-lg'
          onClick={() => onHide(false)}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

const DeliveryFeatureModal = (props) => {
  const { visible, onHide } = props
  const [availableFeatures, setAvailableFeatures] = useState()

  useEffect(() => {
    setAvailableFeatures({
      inside_valley: [
        {
          feature_label: 'Pick Up',
          feature_description: 'Yes',
        },
        {
          feature_label: 'Delivery Type',
          feature_description: 'Slot Based',
        },
        {
          feature_label: 'Sameday Delivery',
          feature_description: 'Yes',
        },
        {
          feature_label: 'Nextday Delivery',
          feature_description: 'Yes',
        },
        {
          feature_label: 'Reverse Delivery',
          feature_description: 'Yes',
        },
        {
          feature_label: 'Cash on Delivery',
          feature_description: 'Yes',
        },
        {
          feature_label: 'Max COD Amount',
          feature_description: 'NPR. 50000',
        },
        {
          feature_label: 'COD Transfer',
          feature_description: '1-2 Business Days',
        },
        {
          feature_label: 'Max Package Weight',
          feature_description: '10 K.G.',
        },
        {
          feature_label: 'Max Package Dimension',
          feature_description: '50 CM (L) x 50 CM (W) x 50 CM (H)',
        },
      ],
      outside_valley: [
        {
          feature_label: 'Pick Up',
          feature_description: 'Yes',
        },
        {
          feature_label: 'Delivery Type',
          feature_description: '3-4 Business Days',
        },
        {
          feature_label: 'Sameday Delivery',
          feature_description: 'No',
        },
        {
          feature_label: 'Nextday Delivery',
          feature_description: 'Yes',
        },
        {
          feature_label: 'Reverse Delivery',
          feature_description: 'No',
        },
        {
          feature_label: 'Cash on Delivery',
          feature_description: 'No',
        },
        {
          feature_label: 'Max COD Amount',
          feature_description: 'Not Available',
        },
        {
          feature_label: 'COD Transfer',
          feature_description: 'Not Available',
        },
        {
          feature_label: 'Max Package Weight',
          feature_description: '2 K.G.',
        },
        {
          feature_label: 'Max Package Dimension',
          feature_description: '50 CM (L) x 50 CM (W) x 50 CM (H)',
        },
      ],
    })
    return () => {}
  }, [])

  return (
    <Modal
      size='xl'
      show={visible}
      onHide={() => onHide(false)}
      aria-labelledby='get-estimate-title-xl'
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id='get-estimate-title-xl'>
          Delivery Service Features
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-body'>
        {availableFeatures ? (
          <div className='row'>
            <div className='col'>
              <h5 className='mb-3'>Inside Valley</h5>
              <div className='delivery-feature-wrapper'>
                <ul className='delivery-feature-list'>
                  {availableFeatures.inside_valley.map((feature, index) => {
                    return (
                      <li key={`delivery-feature-${index}`}>
                        <h6>{feature.feature_label}</h6>
                        <p>{feature.feature_description}</p>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div className='col'>
              <h5 className='mb-3'>Outside Valley</h5>
              <div className='delivery-feature-wrapper'>
                <ul className='delivery-feature-list'>
                  {availableFeatures.outside_valley.map((feature, index) => {
                    return (
                      <li key={`delivery-feature-${index}`}>
                        <h6>{feature.feature_label}</h6>
                        <p>{feature.feature_description}</p>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-secondary btn-lg'
          onClick={() => onHide(false)}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default Index
