import React, { useState, useEffect } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import moment from 'moment'
import { css } from '@emotion/core'
import { BeatLoader } from 'react-spinners'

import CreateDeliveryModal from '../../../components/page-components/create-delivery'

import EditDeliveryModal from '../../../components/page-components/edit-delivery'

import InfoBoxFull from '../../page-components/cards/info-box'

import { orderAPI } from '../../../api/saili'
import { useAuth } from '../../../context/user/auth'
import { useNewOrder } from '../../../context/order/new'

const momentTz = require('moment-timezone')

const Index = () => {
  var auth = useAuth()
  var newOrder = useNewOrder()
  // console.log('Has New Order', newOrder)

  const overrideSpinner = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `

  const [filterDeliveryOrders, setFilterDeliveryOrders] = useState([])
  const [deliveryOrders, setDeliveryOrders] = useState([])
  const [disableInputs, setDisableInputs] = useState(false)
  const [processModalShow, setProcessModalShow] = useState(false)
  const [cancellingOrder, setCancellingOrder] = useState(false)
  const [errorResponse, setErrorResponse] = useState()

  const [newDeliveryModalShow, setNewDeliveryModalShow] = useState(false)
  const [editDeliveryModalShow, setEditDeliveryModalShow] = useState(false)
  const [
    editDeliveryModalOrderProps,
    setEditDeliveryModalOrderProps,
  ] = useState()

  const [displaySidePanel, setDisplaySidePanel] = useState(false)
  const [orderDetails, setOrderDetails] = useState()
  const [totalOrdersCount, setTotalOrdersCount] = useState()
  const [perPageOrder, setPerPageOrder] = useState()
  const [currentOrdersPage, setCurrrentOrdersPage] = useState()
  const [maxPageCount, setMaxPageCount] = useState()

  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchPredictions, setSearchPredictions] = useState([])

  useEffect(async () => {
    // await getSetActiveOrders()
    await ordersPerPage(1)
  }, [processModalShow])

  useEffect(async () => {
    if (newOrder.newOrder) {
      // await getSetActiveOrders()
      await ordersPerPage(1)
      setTimeout(() => {
        newOrder.resetCheckNewOrder()
      }, 500)
    }
  }, [newOrder])

  const handleOnCreateDeliveryModalHide = () => {
    newOrder.checkNewOrder()
    setNewDeliveryModalShow(false)
  }

  const handleOnEditDeliveryModalHide = () => {
    newOrder.checkNewOrder()
    setEditDeliveryModalShow(false)
  }

  const ordersPerPage = async (page) => {
    // console.log('Page ', page)
    var orderResponse = await orderAPI.getPerPage(page, auth.user)
    // console.log(orderResponse)
    setFilterDeliveryOrders(orderResponse.data)
    setDeliveryOrders(orderResponse.data)
    setTotalOrdersCount(orderResponse.totalOrders)
    setPerPageOrder(orderResponse.perPage)
    setCurrrentOrdersPage(orderResponse.currentPage)
    setMaxPageCount(
      Math.ceil(orderResponse.totalOrders / orderResponse.perPage)
    )
    handelOrderViewHide()
  }

  const handleOrderView = (orderId) => {
    var currentOrder = deliveryOrders.filter((order) => {
      return order._id == orderId
    })

    if (currentOrder) {
      setDisplaySidePanel(true)
      setOrderDetails(currentOrder[0])
    }
    // console.log('Display ', orderId)
  }

  const handelOrderViewHide = () => {
    setDisplaySidePanel(false)
    setOrderDetails()
  }

  const handleOrderEdit = async (orderId) => {
    var deliveryOrder = deliveryOrders.filter((delivery) => {
      return delivery._id == orderId
    })
    if (deliveryOrder.length > 0) {
      setEditDeliveryModalOrderProps(deliveryOrder[0])
      setEditDeliveryModalShow(true)
    }
  }

  const handleOrderDelete = async (orderId) => {
    setDisableInputs(true)
    setProcessModalShow(true)
    setCancellingOrder(true)

    var cancelResponse = await orderAPI.cancel(orderId, auth.user)

    if (cancelResponse) {
      if (cancelResponse.status == 'SUCCESS') {
        // await getSetActiveOrders()
        setDisableInputs(false)
        setProcessModalShow(false)
        setCancellingOrder(false)
      } else {
        setDisableInputs(false)
        setCancellingOrder(false)
        setErrorResponse(cancelResponse)
      }
    }
  }

  // const handleOnKeywordChange = async (value) => {
  //   setSearchKeyword(value)
  //   if (value.length > 0) {
  //     searchOrdersByRecipient(value)
  //   } else {
  //     await ordersPerPage(1)
  //   }
  // }

  const searchOrdersByRecipient = async () => {
    if (searchKeyword.length > 0) {
      setDisableInputs(true)
      var searchResponse = await orderAPI.searchOrderByRecipient(
        searchKeyword,
        auth.user
      )

      if (searchResponse) {
        // console.log(searchResponse)
        if (searchResponse.status == 'SUCCESS') {
          setSearchPredictions(searchResponse.data)
          setFilterDeliveryOrders(searchResponse.data)
          setDisableInputs(false)
        } else {
          setDisableInputs(false)
        }
      }
    }
  }

  const resetSearch = async () => {
    setSearchKeyword('')
    setSearchPredictions([])
    await ordersPerPage(1)
  }

  return (
    <>
      <div className='row'>
        <div className='col-sm-12'>
          <article className='current-orders-list-wrapper'>
            <header className='current-orders-list-header'>
              <div className='row'>
                <div className='col-sm-7'>
                  <div
                    className='btn btn-primary'
                    onClick={() => setNewDeliveryModalShow(true)}
                  >
                    New Delivery Order
                  </div>
                  {newDeliveryModalShow ? (
                    <CreateDeliveryModal
                      visible={newDeliveryModalShow}
                      onHide={handleOnCreateDeliveryModalHide}
                    />
                  ) : null}
                </div>
                <div className='col-sm-5'>
                  <div className='input-group'>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      placeholder="Recipient's name or number"
                      aria-describedby='button-addon-search'
                      value={searchKeyword}
                      onChange={(e) => setSearchKeyword(e.target.value)}
                      readOnly={disableInputs}
                    />
                    <div className='input-group-append'>
                      <button
                        className='btn btn-outline-secondary'
                        type='button'
                        id='button-addon-search'
                        onClick={() => searchOrdersByRecipient()}
                        disabled={disableInputs}
                      >
                        Search
                      </button>
                      <button
                        className='btn btn-outline-secondary'
                        type='button'
                        id='button-addon-search'
                        onClick={() => resetSearch()}
                        disabled={disableInputs}
                      >
                        Reset
                      </button>
                      {/* {searchPredictions.length > 0 ? (
                        
                      ) : (
                        
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <section>
              {filterDeliveryOrders ? (
                <>
                  {filterDeliveryOrders.length > 0 ? (
                    <div className='row'>
                      <div className={displaySidePanel ? 'col-sm-8' : 'col'}>
                        <div className='delivery-history-wrapper'>
                          <div className='orders-header'>
                            <div className='row no-gutters'>
                              <div className='col-sm-2'>
                                <div className='orders-column-header'>
                                  Sender
                                </div>
                              </div>
                              <div className='col-sm-2'>
                                <div className='orders-column-header'>
                                  Package Info
                                </div>
                              </div>
                              <div className='col-sm-3'>
                                <div className='orders-column-header'>
                                  Recipient
                                </div>
                              </div>
                              <div className='col-sm-1'>
                                <div className='orders-column-header'>COD</div>
                              </div>
                              <div className='col-sm-1'>
                                <div className='orders-column-header'>
                                  Charge
                                </div>
                              </div>
                              <div className='col-sm-2'>
                                <div className='orders-column-header'>
                                  Status
                                </div>
                              </div>

                              <div className='col-sm-1'>
                                <div className='orders-column-header'></div>
                              </div>
                            </div>
                          </div>
                          <div className='orders-body'>
                            {filterDeliveryOrders.map((order, index) => {
                              let firstItem =
                                index == 0 ? 'order-item-first' : ''
                              return (
                                <div
                                  className={`order-item ${firstItem}`}
                                  key={order._id}
                                >
                                  <div className='row no-gutters'>
                                    <div className='col-md-2'>
                                      <div className='column-group'>
                                        <ul className='order-item-detail-list'>
                                          <li>
                                            <strong>{order.sender.name}</strong>
                                          </li>

                                          <li>{order.sender.phone}</li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className='col-md-2'>
                                      <div className='column-group'>
                                        <ul className='order-item-detail-list'>
                                          <li>
                                            <strong>
                                              {order.package.title}
                                            </strong>
                                          </li>
                                          <li>
                                            {order.package.weight.value}{' '}
                                            <span className='text-uppercase'>
                                              {order.package.weight.unit}
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className='col-sm-3'>
                                      <div className='column-group'>
                                        <ul className='order-item-detail-list'>
                                          <li>
                                            <strong>
                                              {order.recipient.full_name}
                                            </strong>
                                            {' - '}
                                            {order.recipient.mobile_number}
                                            {order.recipient.alt_contact_number
                                              ? ', ' +
                                                order.recipient
                                                  .alt_contact_number
                                              : ''}
                                          </li>
                                          <li>{order.recipient.address}</li>
                                        </ul>
                                      </div>
                                    </div>

                                    <div className='col-sm-1'>
                                      <div className='column-group'>
                                        <ul className='order-item-detail-list'>
                                          <li>
                                            {order.cod.requested ? (
                                              <>
                                                <span className='text-uppercase'>
                                                  {order.package.price.currency}
                                                </span>{' '}
                                                <strong>
                                                  {order.package.price.value}
                                                </strong>
                                              </>
                                            ) : (
                                              'No'
                                            )}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>

                                    <div className='col-sm-1'>
                                      <div className='column-group'>
                                        <ul className='order-item-detail-list'>
                                          <li>
                                            <span className='text-uppercase'>
                                              {order.charge.currency}
                                            </span>{' '}
                                            <strong>
                                              {order.charge.total}
                                            </strong>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className='col-sm-2'>
                                      <div className='column-group'>
                                        <ul className='order-item-detail-list'>
                                          <li>{order.info.status}</li>
                                        </ul>
                                      </div>
                                    </div>

                                    <div className='col-sm-1'>
                                      <div className='action-group'>
                                        {order.info.status == 'PENDING' ||
                                        order.info.status ==
                                          'PICKED_UP_WAREHOUSE' ? (
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              variant='outline-dark btn-sm'
                                              id='dropdown-basic'
                                            >
                                              <span className='material-icons'>
                                                menu
                                              </span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleOrderView(order._id)
                                                }
                                              >
                                                View Order
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleOrderEdit(order._id)
                                                }
                                              >
                                                Edit Order
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleOrderDelete(order._id)
                                                }
                                              >
                                                Delete Order
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        {maxPageCount ? (
                          <div
                            className={
                              searchPredictions.length > 0
                                ? 'd-none'
                                : 'pagination-wrapper'
                            }
                          >
                            <nav>
                              <ul className='pagination justify-content-center'>
                                {currentOrdersPage ? (
                                  <>
                                    {currentOrdersPage == 1 ? (
                                      <>
                                        <li className='page-item disabled'>
                                          <a className='page-link'>First</a>
                                        </li>
                                        <li className='page-item disabled'>
                                          <a className='page-link'>Previous</a>
                                        </li>
                                      </>
                                    ) : (
                                      <>
                                        <li className='page-item'>
                                          <a
                                            className='page-link'
                                            onClick={() => ordersPerPage(1)}
                                          >
                                            First
                                          </a>
                                        </li>
                                        <li className='page-item'>
                                          <a
                                            className='page-link'
                                            onClick={() =>
                                              ordersPerPage(
                                                currentOrdersPage - 1
                                              )
                                            }
                                          >
                                            Previous
                                          </a>
                                        </li>
                                      </>
                                    )}

                                    {currentOrdersPage > 2 ? (
                                      <li className='page-item'>
                                        <span className='page-link'>...</span>
                                      </li>
                                    ) : null}
                                    <li className='page-item active'>
                                      <a className='page-link'>
                                        {currentOrdersPage}
                                      </a>
                                    </li>
                                    {currentOrdersPage != maxPageCount ? (
                                      <li className='page-item'>
                                        <a
                                          className='page-link'
                                          onClick={() =>
                                            ordersPerPage(currentOrdersPage + 1)
                                          }
                                        >
                                          {currentOrdersPage + 1}
                                        </a>
                                      </li>
                                    ) : null}
                                    {maxPageCount > currentOrdersPage + 1 ? (
                                      <li className='page-item'>
                                        <span className='page-link'>...</span>
                                      </li>
                                    ) : null}

                                    {currentOrdersPage == maxPageCount ? (
                                      <>
                                        <li className='page-item disabled'>
                                          <a className='page-link'>Next</a>
                                        </li>
                                        <li className='page-item disabled'>
                                          <a className='page-link'>Last</a>
                                        </li>
                                      </>
                                    ) : (
                                      <>
                                        <li className='page-item'>
                                          <a
                                            className='page-link'
                                            onClick={() =>
                                              ordersPerPage(
                                                currentOrdersPage + 1
                                              )
                                            }
                                          >
                                            Next
                                          </a>
                                        </li>
                                        <li className='page-item'>
                                          <a
                                            className='page-link'
                                            onClick={() =>
                                              ordersPerPage(maxPageCount)
                                            }
                                          >
                                            Last
                                          </a>
                                        </li>
                                      </>
                                    )}
                                  </>
                                ) : null}
                              </ul>
                            </nav>
                          </div>
                        ) : null}
                      </div>
                      {displaySidePanel ? (
                        <>
                          <div className='col-sm-4'>
                            <div className='delivery-item-details-wrapper'>
                              <div className='delivery-item-details-header'>
                                <div className='row'>
                                  <div className='col'>Order Details</div>
                                  <div className='col text-right'>
                                    <div
                                      className='delivery-item-details-close'
                                      onClick={() => handelOrderViewHide()}
                                    >
                                      <span className='material-icons'>
                                        close
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {orderDetails ? (
                                <>
                                  <div className='delivery-item-details-group'>
                                    <h6>Delivery Status</h6>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        <strong>
                                          {orderDetails.info.status}
                                        </strong>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className='delivery-item-details-group'>
                                    <h6>Pacakge Info</h6>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        <strong>
                                          {orderDetails.package.title}
                                        </strong>
                                      </li>
                                      <li>
                                        Weight :{' '}
                                        {orderDetails.package.weight.value}{' '}
                                        <span className='text-uppercase'>
                                          {orderDetails.package.weight.unit}
                                        </span>
                                      </li>

                                      <li>
                                        COD Request :{' '}
                                        {orderDetails.cod.requested
                                          ? 'Yes'
                                          : 'No'}
                                      </li>
                                      <li>
                                        Price :{' '}
                                        <span className='text-uppercase'>
                                          {orderDetails.package.price.currency}
                                        </span>{' '}
                                        {orderDetails.package.price.value}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className='delivery-item-details-group'>
                                    <h6>Recipient Info</h6>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        <strong>
                                          {orderDetails.recipient.full_name}
                                        </strong>
                                      </li>
                                      <li>
                                        Mobile Number :{' '}
                                        {orderDetails.recipient.mobile_number}
                                      </li>
                                      <li>
                                        Alt Contact Number :{' '}
                                        {
                                          orderDetails.recipient
                                            .alt_contact_number
                                        }
                                      </li>
                                      <li>
                                        Address :{' '}
                                        {orderDetails.recipient.address}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className='delivery-item-details-group'>
                                    <h6>Sender Info</h6>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        <strong>
                                          {orderDetails.sender.name}
                                        </strong>
                                      </li>
                                      <li>
                                        Contact Number :{' '}
                                        {orderDetails.sender.phone}
                                      </li>
                                    </ul>
                                  </div>
                                  {orderDetails.rider ? (
                                    <div className='delivery-item-details-group'>
                                      <h6>Rider Info</h6>
                                      <ul className='order-item-detail-list'>
                                        <li>
                                          <strong>
                                            {orderDetails.rider.name}
                                          </strong>
                                        </li>
                                        <li>
                                          Contact Number :{' '}
                                          {orderDetails.rider.contact}
                                        </li>
                                      </ul>
                                    </div>
                                  ) : null}
                                  <div className='delivery-item-details-group'>
                                    <h6>Delivery Charge</h6>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        <strong>
                                          <span className='text-uppercase'>
                                            {orderDetails.charge.currency}
                                          </span>{' '}
                                          {orderDetails.charge.total}
                                        </strong>
                                      </li>
                                      <li>Base : {orderDetails.charge.base}</li>
                                      <li>
                                        Extra : {orderDetails.charge.extra}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className='delivery-item-details-group'>
                                    <h6>History</h6>
                                    {orderDetails.info.history.length > 0
                                      ? orderDetails.info.history.map(
                                          (history) => {
                                            return (
                                              <ul
                                                className='order-item-detail-list'
                                                key={history._id}
                                              >
                                                <li>
                                                  <strong>
                                                    {history.action}
                                                  </strong>
                                                </li>
                                                <li>
                                                  {moment(
                                                    history.date
                                                  ).calendar()}
                                                </li>
                                              </ul>
                                            )
                                          }
                                        )
                                      : 'Not Found'}
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ) : (
                    <div
                      style={{ position: 'relative', minHeight: 600 + 'px' }}
                    >
                      <InfoBoxFull
                        icon='shopping_bag'
                        title='No Delivery Orders'
                        description="You don't have any delivery orders."
                      />
                    </div>
                  )}
                </>
              ) : null}
            </section>
          </article>
        </div>
      </div>
      <Modal
        size='xl'
        backdrop='static'
        keyboard={false}
        show={processModalShow}
        onHide={() => setProcessModalShow(false)}
        aria-labelledby='action-delivery-title-xl'
        animation={false}
      >
        <Modal.Header closeButton={disableInputs ? false : true}>
          <Modal.Title id='action-delivery-title-xl'>
            {cancellingOrder ? 'Cancelling Delivery Order' : ''}
            {errorResponse ? 'Error' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-body'>
          {cancellingOrder ? (
            <div className='dc-processing-indicator text-center'>
              <h6>Cancelling</h6>
              <p>Please wait, your request is being processed.</p>
              <BeatLoader
                color='#0098e3'
                loading={true}
                css={overrideSpinner}
              />
            </div>
          ) : null}
          {errorResponse ? (
            <div className='alert alert-danger' role='alert'>
              <h4 className='alert-heading'>{errorResponse.title}</h4>
              <p>{errorResponse.message}</p>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>

      {editDeliveryModalShow ? (
        <EditDeliveryModal
          orderInfo={editDeliveryModalOrderProps}
          visible={editDeliveryModalShow}
          onHide={handleOnEditDeliveryModalHide}
        />
      ) : null}
    </>
  )
}

export default Index
