const degreeModule = {
  parse: (value) => {
    var parts = value.split(/[^\d\w\.]+/)
    return {
      lat: {
        degree: parts[0],
        minutes: parts[1],
        seconds: parts[2],
        direction: parts[3],
      },
      lng: {
        degree: parts[4],
        minutes: parts[5],
        seconds: parts[6],
        direction: parts[7],
      },
    }
  },
  convert: (value) => {
    var latDd =
      parseFloat(value.lat.degree) +
      parseFloat(value.lat.minutes) / 60 +
      parseFloat(value.lat.seconds) / 3600
    var lngDd =
      parseFloat(value.lng.degree) +
      parseFloat(value.lng.minutes) / 60 +
      parseFloat(value.lng.seconds) / 3600

    if (value.lat.direction == 'S' || value.lat.direction == 'W') {
      latDd *= -1
    }

    if (value.lng.direction == 'S' || value.lng.direction == 'W') {
      lngDd *= -1
    }
    return {
      Latitude: parseFloat(latDd).toFixed(6),
      Longitude: parseFloat(lngDd).toFixed(6),
      Position:
        parseFloat(latDd).toFixed(6) + ',' + parseFloat(lngDd).toFixed(6),
    }
  },
}

export const degreeConverter = {
  dmsToDd: (value) => {
    var parsedValue = degreeModule.parse(value)
    var convertedValue = degreeModule.convert(parsedValue)
    return convertedValue
  },
}
