import { createContext, useContext, useState } from 'react'

const newCustomerContext = createContext()

export const ProvideNewCustomer = ({ children }) => {
  const customer = useProvideNewCustomer()
  return (
    <newCustomerContext.Provider value={customer}>
      {children}
    </newCustomerContext.Provider>
  )
}

export const useNewCustomer = () => {
  return useContext(newCustomerContext)
}

function useProvideNewCustomer() {
  const [newCustomer, setNewCustomer] = useState(false)

  const checkNewCustomer = () => {
    setNewCustomer(true)
  }

  const resetCheckNewCustomer = () => {
    setNewCustomer(false)
  }

  return {
    newCustomer,
    checkNewCustomer,
    resetCheckNewCustomer,
  }
}
