export const sailiValidate = {
  isValidPlusCodeCharacters: (string) => {
    return /^[a-z0-9]+$/i.test(string)
  },
  isValidPlusCode: (plusCode) => {
    plusCode = plusCode.trim()
    var plusCodeLength = plusCode.length
    if (plusCodeLength > 6) {
      var plusCharIndex = plusCode.indexOf('+')
      if (plusCharIndex == 4 || plusCharIndex == 8) {
        var beforePlus = ''
        var afterPlus = ''
        var plusCodeType = ''

        switch (plusCharIndex) {
          case 4:
            beforePlus = plusCode.substring(0, 4)
            afterPlus = plusCode.substring(5, 7)
            plusCodeType = 'COMPOUND'
            break

          case 8:
            beforePlus = plusCode.substring(0, 8)
            afterPlus = plusCode.substring(9, 11)
            plusCodeType = 'GLOBAL'
            break

          default:
            break
        }

        if (
          this.isValidPlusCodeCharacters(beforePlus) &&
          this.isValidPlusCodeCharacters(afterPlus)
        ) {
          return { status: true, type: plusCodeType }
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  },
}
