import { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import polyline from '@mapbox/polyline'
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
  Circle,
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import L, { divIcon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import markerbg from '../../../assets/images/ic_loc_white.png'

import { sailiFormatting, timeFormatting } from '../../../functions/formatting'

import { riderAPI, routeAPI } from '../../../api/saili'

import { useAuth } from '../../../context/user/auth'

import { useUserProfile } from '../../../context/user/profile'

const Index = (props) => {
  const { visible, onHide, routeInfo } = props

  const myIcon = L.icon({
    iconUrl: markerbg,
    iconSize: [51, 83],
    iconAnchor: [25.5, 78],
    popupAnchor: [0, -78],
  })

  var auth = useAuth()
  var user = useUserProfile()

  // console.log(user)

  // === Component States ===

  // Modal State
  const [disableInputs, setDisableInputs] = useState(false)

  //Existing Route Info
  const [routeInfoData, setRouteInfoData] = useState()

  const [mapPosition, setMapPosition] = useState()
  const [availableRiders, setAvailableRiders] = useState([])
  const [riderOptions, setRiderOptions] = useState()
  const [selectedRiders, setSelectedRiders] = useState([])
  const [routesRiderMatch, setRoutesRiderMatch] = useState([])

  // ======
  useEffect(async () => {
    if (routeInfo) {
      // console.log('Route Info', routeInfo)

      setRouteInfoData(routeInfo)
      var matchObj = routeInfo.solution.map((routes, index) => {
        return {
          route_id: routes._id,
          rider_info: {
            rider_id: '',
            rider_name: '',
            rider_mobile: '',
          },
        }
      })

      if (matchObj.length > 0) {
        setRoutesRiderMatch(matchObj)
      }
    }
  }, [routeInfo])

  useEffect(async () => {
    if (user) {
      setMapPosition([
        user.userProfile.business_details[0].warehouses[0].geometry
          .coordinates[1],
        user.userProfile.business_details[0].warehouses[0].geometry
          .coordinates[0],
      ])
    }
  }, [user])

  useEffect(async () => {
    var riderResponse = await riderAPI.getAvailable(auth.user)
    if (riderResponse) {
      setAvailableRiders(riderResponse.data)

      if (routeInfo) {
        var generatedOptions = routeInfo.solution.map((routes, index) => {
          var generatedRiderOptions = riderResponse.data.map(
            (rider, rindex) => {
              // console.log(rider)
              return {
                route_id: routes._id,
                rider_id: rider._id,
                label: rider.full_name + ' - ' + rider.mobile_number,
                value: routes._id + '-' + rider._id,
                rider_full_name: rider.full_name,
                rider_mobile: rider.mobile_number,
              }
            }
          )
          return generatedRiderOptions
        })
        // console.log('GO', generatedOptions)
        setRiderOptions(generatedOptions)
      }
    }
  }, [visible])

  // === Component Handler ===
  const setSelectedRider = (option) => {
    var currentMatch = routesRiderMatch
    for (var i = 0; i < currentMatch.length; i++) {
      if (currentMatch[i].route_id == option.route_id) {
        var rider = {
          rider_id: option.rider_id,
          rider_name: option.rider_full_name,
          rider_mobile: option.rider_mobile,
        }
        currentMatch[i].rider_info = rider
      }
    }
    setRoutesRiderMatch(currentMatch)
  }

  const handleOnAssignRiders = async () => {
    // console.log('Match ', routesRiderMatch)
    var response = validateAssignRider()

    if (response) {
      switch (response.status) {
        case 'OK':
          var response = await routeAPI.assign(
            routesRiderMatch,
            routeInfo._id,
            auth.user
          )

          if (response) {
            // console.log(response)
            if (response.status == 'SUCCESS') {
              setDisableInputs(false)
              onHide()
            } else {
              setDisableInputs(false)
            }
          }
          break
        case 'ERROR':
          alert(response.message)
          break

        default:
          break
      }
    }
  }

  const validateAssignRider = () => {
    var emptyValues = false
    var multipleAssign = false
    routesRiderMatch.map((match) => {
      if (!match.rider_info.rider_id) {
        emptyValues = true
      }
    })

    if (emptyValues) {
      return {
        status: 'ERROR',
        message: 'Please assign riders for all routes.',
      }
    } else {
      for (var i = 0; i < routesRiderMatch.length; i++) {
        for (var j = 0; j < routesRiderMatch.length; j++) {
          if (i != j) {
            if (
              routesRiderMatch[i].rider_info.rider_id ==
              routesRiderMatch[j].rider_info.rider_id
            ) {
              multipleAssign = true
            }
          }
        }
      }

      if (multipleAssign) {
        return {
          status: 'ERROR',
          message: "Single rider can't be assigned in multiple routes",
        }
      } else {
        return {
          status: 'OK',
        }
      }
    }
  }
  return (
    <Modal
      // size='xl'
      backdrop='static'
      keyboard={false}
      show={visible}
      onHide={() => onHide()}
      aria-labelledby='edit-route-title-xl'
      dialogClassName='modal-90w'
      animation={false}
    >
      <Modal.Header closeButton={disableInputs ? false : true}>
        <Modal.Title id='edit-route-title-xl'>Route Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-body'>
        <div className='row'>
          <div className='col-sm-4'>
            <div className='form-wrapper'>
              {routeInfoData ? (
                <ul className='route-info-list'>
                  {routeInfoData.solution.map((routes, index) => {
                    return (
                      <li
                        key={routes._id}
                        style={{ background: routes.route_color }}
                      >
                        <span className='material-icons'>layers</span>
                        <h6>{routes._id}</h6>
                        <p>Destinations : {routes.route_info.length}</p>
                        <p>
                          <strong>Rider</strong>
                        </p>
                        <div className='text-body'>
                          {riderOptions ? (
                            <Select
                              // value={riderOptions[index][1]}
                              onChange={(option) => setSelectedRider(option)}
                              options={riderOptions[index]}
                              isDisabled={disableInputs}
                            />
                          ) : null}
                        </div>
                      </li>
                    )
                  })}
                </ul>
              ) : null}
            </div>
          </div>
          <div className='col-sm-8'>
            <div className='route-container'>
              {mapPosition ? (
                <MapContainer
                  center={mapPosition}
                  zoom={12}
                  scrollWheelZoom={true}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    maxZoom={19}
                  />

                  {routeInfoData
                    ? routeInfoData.solution.map((routes, index) => {
                        return (
                          <MarkerClusterGroup
                            key={index}
                            iconCreateFunction={(cluster) => {
                              return L.divIcon({
                                html: `<div class="custom-cluster-marker" style="background: ${
                                  routes.route_color
                                }"><span>${cluster.getChildCount()}</span></div>`,
                                className: 'marker-cluster-custom',
                                iconSize: L.point(40, 40, true),
                              })
                            }}
                          >
                            {routes.route_info.map((route) => {
                              return (
                                <Marker
                                  position={[
                                    route.recipient_geometry.coordinates[1],
                                    route.recipient_geometry.coordinates[0],
                                  ]}
                                  key={route.order_id}
                                  icon={divIcon({
                                    html: `<div class="marker-div-wrapper"><span class="route-position">${route.delivery_index}</span><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" version="1" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><path d="M18.5,10.2c0,2.57-2.1,5.79-6.16,9.51L12,20.01l-0.34-0.31C7.6,15.99,5.5,12.77,5.5,10.2 c0-3.84,2.82-6.7,6.5-6.7S18.5,6.35,18.5,10.2z" fill-opacity="1" fill="${routes.route_color}"/><path d="M12,2c4.2,0,8,3.22,8,8.2c0,3.32-2.67,7.25-8,11.8c-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2z M18,10.2 C18,6.57,15.35,4,12,4s-6,2.57-6,6.2c0,2.34,1.95,5.44,6,9.14C16.05,15.64,18,12.54,18,10.2z M12,12c-1.1,0-2-0.9-2-2s0.9-2,2-2 s2,0.9,2,2S13.1,12,12,12z" fill-opacity="0.3"/><path d="M0,0h24v24H0V0z" fill="none"/></svg></div>`,
                                  })}
                                >
                                  <Popup>
                                    <strong>{route.recipient_fullname}</strong>
                                    <br />
                                    {route.recipient_address}
                                    <br />
                                    <br />
                                    <strong>ETA : </strong>
                                    {timeFormatting.secondsToHrMin(
                                      route.eta.earliest
                                    )}{' '}
                                    -{' '}
                                    {timeFormatting.secondsToHrMin(
                                      route.eta.latest
                                    )}
                                  </Popup>
                                </Marker>
                              )
                            })}
                          </MarkerClusterGroup>
                        )
                      })
                    : null}

                  {routeInfoData
                    ? routeInfoData.solution.map((routes, index) => {
                        return (
                          <Polyline
                            pathOptions={{
                              color: routes.route_color,
                              weight: 5,
                              smoothFactor: 1,
                            }}
                            positions={polyline.decode(routes.route_polyline)}
                            key={index}
                          />
                        )
                      })
                    : null}

                  <Marker position={mapPosition} icon={myIcon}>
                    <Popup>
                      <strong>
                        {user.userProfile.business_details[0].name}
                      </strong>
                      <br />
                      {
                        user.userProfile.business_details[0].warehouses[0]
                          .formatted_address
                      }
                    </Popup>
                  </Marker>
                </MapContainer>
              ) : null}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-primary btn-lg ml-3'
          onClick={() => handleOnAssignRiders()}
          disabled={disableInputs}
        >
          Assign
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default Index
