import Axios from 'axios'
const querystring = require('qs')

export const WEB_SOCKET_URL = 'http://192.168.1.65:8848'

//Easy
// const API_BASE_URL = 'http://192.168.10.159:8848/api/v3/web'

//SpeedEx
// const API_BASE_URL = 'http://192.168.1.248:8848/api/v3/web'

//Office
// const API_BASE_URL = 'http://192.168.1.65:8848/api/v3/web'

//Home
// const API_BASE_URL = 'http://192.168.1.67:8848/api/v3/web'

//Live
export const API_BASE_URL = 'https://sailidelivers-v3.onrender.com/api/v3/web'

//Internal

// export const API_BASE_URL = 'sailidelivers-v3:10000/api/v3/web'

export const partnerAPI = {
  signup: async (userInputs) => {
    try {
      if (userInputs) {
        var bodyParameters = new FormData()
        bodyParameters.append('full_name', userInputs.full_name)
        bodyParameters.append('mobile_number', userInputs.mobile_number)
        bodyParameters.append('email', userInputs.email)
        bodyParameters.append('password', userInputs.password)
        bodyParameters.append('account_type', userInputs.account_type)
        bodyParameters.append('business_name', userInputs.business_name)
        bodyParameters.append(
          'warehouse_contact_person',
          userInputs.warehouse_contact_person
        )
        bodyParameters.append(
          'warehouse_contact_number',
          userInputs.warehouse_contact_number
        )
        bodyParameters.append(
          'warehouse_address',
          userInputs.warehouse_address.full_address
        )
        bodyParameters.append(
          'warehouse_coords',
          userInputs.warehouse_address.geometry.coords.lat +
            ',' +
            userInputs.warehouse_address.geometry.coords.lng
        )
        var response = await Axios.post(
          API_BASE_URL + '/partner/signup',
          bodyParameters
        )

        // console.log(response)

        if (response.status == 200) {
          switch (response.data.status) {
            case 'SUCCESS':
              return {
                status: 'SUCCESS',
                title: 'Success',
                message: response.data.message,
              }

            case 'CONFLICT':
              return {
                status: 'ERROR',
                title: 'Already Exist',
                message: response.data.message,
              }

            default:
              break
          }
        } else {
          return {
            status: 'ERROR',
            title: 'Error',
            message:
              'Opps! There was an error signing up your account. Please try again or contact an administrator.',
          }
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  verifyEmail: async (partnerId) => {
    try {
      var response = await Axios.get(
        API_BASE_URL + '/partner/verify/email/' + partnerId
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Success',
              message: response.data.message,
            }

          case 'NOT_FOUND':
            return {
              status: 'ERROR',
              title: 'Not Found',
              message: response.data.message,
            }

          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error verifying your submissions. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  login: async (loginDetails) => {
    try {
      if (loginDetails) {
        var bodyParameters = new FormData()
        bodyParameters.append('email', loginDetails.email)
        bodyParameters.append('password', loginDetails.password)
        bodyParameters.append('browser_name', loginDetails.browser_name)
        bodyParameters.append('device_os', loginDetails.device_os)

        var response = await Axios.post(
          API_BASE_URL + '/partner/login',
          bodyParameters
        )
        // console.log(response)

        if (response.status == 200) {
          switch (response.data.status) {
            case 'SUCCESS':
              return {
                status: 'SUCCESS',
                title: 'Success',
                data: response.data.data,
              }

            case 'FORBIDDEN':
              return {
                status: 'ERROR',
                title: 'Forbidden',
                message: response.data.message,
              }

            case 'NOT_FOUND':
              return {
                status: 'ERROR',
                title: 'Not Found',
                message: response.data.message,
              }

            default:
              break
          }
        } else {
          return {
            status: 'ERROR',
            title: 'Error',
            message:
              'Opps! There was an error signing up your account. Please try again or contact an administrator.',
          }
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  getProfile: async (token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var response = await Axios.get(API_BASE_URL + '/partner/profile', config)

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              data: response.data.data,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting profile. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      if (error.response.status == 401) {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting profile. Please try again or contact an administrator.',
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Internal Server Error',
          message:
            'The server encountered an unexpected condition which prevented it from fulfilling the request.',
        }
      }
    }
  },
  addPaymentOption: async (userInputs, token) => {
    try {
      if (userInputs) {
        // console.log(userInputs)
        var config = {
          headers: { Authorization: 'Bearer ' + token },
        }

        var bodyParameters = new FormData()
        bodyParameters.append('service_provider', userInputs.service_provider)
        bodyParameters.append(
          'service_provider_type',
          userInputs.service_provider_type
        )
        bodyParameters.append('account_number', userInputs.account_number)
        bodyParameters.append(
          'account_holder_name',
          userInputs.account_holder_name
        )

        if (userInputs.branch) {
          bodyParameters.append('branch', userInputs.branch)
        }

        var response = await Axios.post(
          API_BASE_URL + '/partner/payment/add',
          bodyParameters,
          config
        )
        // console.log(response)

        if (response.status == 200) {
          switch (response.data.status) {
            case 'SUCCESS':
              return {
                status: 'SUCCESS',
                title: 'Success',
                data: response.data.data,
              }

            default:
              break
          }
        } else {
          return {
            status: 'ERROR',
            title: 'Error',
            message:
              'Opps! There was an error signing up your account. Please try again or contact an administrator.',
          }
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  logout: async (token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = {}

      var response = await Axios.put(
        API_BASE_URL + '/partner/logout',
        querystring.stringify(bodyParameters),
        config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Logged Out',
              message: response.data.message,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error logging out your account. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
}

export const slotAPI = {
  get: async () => {
    try {
      var response = await Axios.get(API_BASE_URL + '/slots')

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              data: response.data.data,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting slots. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
}

export const orderAPI = {
  getAll: async (token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = {}

      var response = await Axios.get(API_BASE_URL + '/partner-order/', config)

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Success',
              data: response.data.data,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting delivery orders. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  getActive: async (token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = {}

      var response = await Axios.get(
        API_BASE_URL + '/partner-order/active',
        config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Success',
              data: response.data.data,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting delivery orders. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },

  getWarehouseOrders: async (token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = {}

      var response = await Axios.get(
        API_BASE_URL + '/partner-order/warehouse',
        config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Success',
              data: response.data.data,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting delivery orders. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  getPerPage: async (page, token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var response = await Axios.get(
        API_BASE_URL + '/partner-order/page/' + page,
        config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Success',
              data: response.data.data,
              totalOrders: response.data.total,
              perPage: response.data.perPage,
              currentPage: response.data.currentPage,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting delivery orders. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  create: async (userInputs, token) => {
    try {
      if (userInputs) {
        // console.log('Inputs', userInputs)
        var config = {
          headers: { Authorization: 'Bearer ' + token },
        }

        var bodyParameters = new FormData()

        bodyParameters.append('sender_id', userInputs.sender.sender_id)
        bodyParameters.append(
          'sender_business_name',
          userInputs.sender.sender_business_name
        )
        bodyParameters.append(
          'sender_business_phone',
          userInputs.sender.sender_business_phone
        )

        bodyParameters.append(
          'recipient_mobile',
          userInputs.recipient.mobile_number
        )
        bodyParameters.append(
          'recipient_alt_contact',
          userInputs.recipient.alt_contact
        )
        bodyParameters.append('recipient_name', userInputs.recipient.full_name)
        bodyParameters.append(
          'recipient_addresstype',
          userInputs.recipient.address_type
        )
        bodyParameters.append('recipient_address', userInputs.recipient.address)
        bodyParameters.append(
          'recipient_latlng',
          userInputs.recipient.geometry.coords.lat +
            ',' +
            userInputs.recipient.geometry.coords.lng
        )
        bodyParameters.append(
          'recipient_placeid',
          userInputs.recipient.place_id
        )
        bodyParameters.append('package_title', userInputs.package.title)
        bodyParameters.append('package_price', userInputs.package.price)
        bodyParameters.append('package_cod', userInputs.package.cod)
        bodyParameters.append('package_weight', userInputs.package.weight)
        bodyParameters.append('charge_base', userInputs.charge.base)
        bodyParameters.append('charge_extra', userInputs.charge.extra)

        var response = await Axios.post(
          API_BASE_URL + '/partner-order/create',
          bodyParameters,
          config
        )

        if (response.status == 200) {
          switch (response.data.status) {
            case 'SUCCESS':
              return {
                status: 'SUCCESS',
                title: 'Success',
                data: response.data.data,
              }

            case 'ROUTE_NOT_FOUND':
              return {
                status: 'ERROR',
                title: 'Route Not Found',
                message: response.data.message,
              }

            default:
              return {
                status: 'ERROR',
                title: 'Error',
                message: response.data.message,
              }
          }
        } else {
          return {
            status: 'ERROR',
            title: 'Error',
            message:
              'Opps! There was an error creating delivery order. Please try again or contact an administrator.',
          }
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  edit: async (orderId, newValues, token) => {
    try {
      // console.log(orderId)
      // console.log(newValues)

      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = new FormData()

      if (newValues.sender) {
        bodyParameters.append('sender_id', newValues.sender.sender_id)
        bodyParameters.append(
          'sender_business_name',
          newValues.sender.sender_business_name
        )
        bodyParameters.append(
          'sender_business_phone',
          newValues.sender.sender_business_phone
        )
      }

      if (newValues.recipient.mobile_number) {
        bodyParameters.append(
          'recipient_mobile',
          newValues.recipient.mobile_number
        )
      }

      if (newValues.recipient.alt_contact) {
        bodyParameters.append(
          'recipient_alt_contact',
          newValues.recipient.alt_contact
        )
      }

      if (newValues.recipient.full_name) {
        bodyParameters.append('recipient_name', newValues.recipient.full_name)
      }

      if (newValues.recipient.address_type) {
        bodyParameters.append(
          'recipient_addresstype',
          newValues.recipient.address_type
        )
      }

      if (newValues.recipient.address) {
        bodyParameters.append('recipient_address', newValues.recipient.address)
        bodyParameters.append(
          'recipient_latlng',
          newValues.recipient.geometry.coords.lat +
            ',' +
            newValues.recipient.geometry.coords.lng
        )
        bodyParameters.append('recipient_placeid', newValues.recipient.place_id)
      }

      if (newValues.package.title) {
        bodyParameters.append('package_title', newValues.package.title)
      }

      if (newValues.package.price) {
        bodyParameters.append('package_price', newValues.package.price)
      }

      if (newValues.package.cod) {
        bodyParameters.append('package_cod', newValues.package.cod)
      }

      if (newValues.package.weight) {
        bodyParameters.append('package_weight', newValues.package.weight)
      }

      if (newValues.charge.base) {
        bodyParameters.append('charge_base', newValues.charge.base)
      }

      if (newValues.charge.extra) {
        bodyParameters.append('charge_extra', newValues.charge.extra)
      }

      // console.log(bodyParameters)

      var response = await Axios.post(
        API_BASE_URL + '/partner-order/update/' + orderId,
        bodyParameters,
        config
      )
      // console.log(response)
      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Updated',
              message: response.data.message,
            }

          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error updating your delivery request. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },

  cancel: async (orderId, token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = {}

      var response = await Axios.post(
        API_BASE_URL + '/partner-order/cancel/' + orderId,
        querystring.stringify(bodyParameters),
        config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Cancelled',
              message: response.data.message,
            }
          case 'NOT_ALLOWED':
            return {
              status: 'ERROR',
              title: 'Slot Locked',
              message: response.data.message,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error cancelling your delivery request. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },

  delete: async (orderId, token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = {}

      var response = await Axios.post(
        API_BASE_URL + '/partner-order/delete/' + orderId,
        querystring.stringify(bodyParameters),
        config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Deleted',
              message: response.data.message,
            }
          case 'NOT_ALLOWED':
            return {
              status: 'ERROR',
              title: 'Slot Locked',
              message: response.data.message,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error deleting your delivery request. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },

  searchOrderByRecipient: async (keywords, token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = {}

      var response = await Axios.get(
        API_BASE_URL + '/partner-order/search/' + keywords,
        config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Success',
              data: response.data.data,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error searching delivery orders. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
}

export const routeAPI = {
  getDeliveryRoute: async () => {
    try {
      // var config = {
      //   headers: { Authorization: 'Bearer ' + token },
      // }

      var response = await Axios.get(
        API_BASE_URL + '/delivery-routes'
        // config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Success',
              data: response.data.data,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting routes orders. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  getPerPage: async (page, token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var response = await Axios.get(
        API_BASE_URL + '/partner-route/page/' + page,
        config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Success',
              data: response.data.data,
              totalRoutes: response.data.total,
              perPage: response.data.perPage,
              currentPage: response.data.currentPage,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting delivery routes. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  create: async (userInputs, token) => {
    try {
      if (userInputs) {
        // console.log('Inputs', userInputs)
        var config = {
          headers: { Authorization: 'Bearer ' + token },
        }

        var bodyParameters = new FormData()

        bodyParameters.append('orders', userInputs.selectedOrders)

        bodyParameters.append('no_of_rider', userInputs.noOfRider)

        bodyParameters.append('rider_capacity', userInputs.riderCapacity)

        bodyParameters.append('start_time', userInputs.tripStart)

        bodyParameters.append('end_time', userInputs.tripEnd)

        var response = await Axios.post(
          API_BASE_URL + '/partner-route/create',
          bodyParameters,
          config
        )

        if (response.status == 200) {
          switch (response.data.status) {
            case 'SUCCESS':
              return {
                status: 'SUCCESS',
                title: 'Success',
                data: response.data.data,
              }

            default:
              return {
                status: 'ERROR',
                title: 'Error',
                message: response.data.message,
              }
          }
        } else {
          return {
            status: 'ERROR',
            title: 'Error',
            message:
              'Opps! There was an error creating route. Please try again or contact an administrator.',
          }
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  assign: async (match, routeId, token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = new FormData()

      bodyParameters.append('route_rider_match', JSON.stringify(match))

      var response = await Axios.post(
        API_BASE_URL + '/partner-route/assign-rider/' + routeId,
        bodyParameters,
        config
      )
      // console.log(response)
      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Assigned',
              message: response.data.message,
            }

          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error assigning rider. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  delete: async (routeId, token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = new FormData()

      var response = await Axios.post(
        API_BASE_URL + '/partner-route/delete/' + routeId,
        bodyParameters,
        config
      )

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Deleted',
              message: response.data.message,
            }

          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error deleting route. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
}

export const customerAPI = {
  getAll: async (token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var response = await Axios.get(
        API_BASE_URL + '/partner-customer/',
        config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Success',
              data: response.data.data,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting customerss. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  getPerPage: async (page, token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var response = await Axios.get(
        API_BASE_URL + '/partner-customer/page/' + page,
        config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Success',
              data: response.data.data,
              totalCustomers: response.data.total,
              perPage: response.data.perPage,
              currentPage: response.data.currentPage,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting customerss. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  create: async (userInputs, token) => {
    try {
      if (userInputs) {
        var config = {
          headers: { Authorization: 'Bearer ' + token },
        }

        var bodyParameters = new FormData()

        bodyParameters.append('contact_number', userInputs.contact_number)
        bodyParameters.append('alt_contact', userInputs.alt_contact)
        bodyParameters.append('business_name', userInputs.business_name)
        bodyParameters.append('business_address', userInputs.business_address)
        bodyParameters.append(
          'business_latlng',
          userInputs.business_latlng.coords.lat +
            ',' +
            userInputs.business_latlng.coords.lng
        )

        var response = await Axios.post(
          API_BASE_URL + '/partner-customer/create',
          bodyParameters,
          config
        )

        if (response.status == 200) {
          switch (response.data.status) {
            case 'SUCCESS':
              return {
                status: 'SUCCESS',
                title: 'Success',
                data: response.data.data,
              }

            default:
              return {
                status: 'ERROR',
                title: 'Error',
                message: response.data.message,
              }
          }
        } else {
          return {
            status: 'ERROR',
            title: 'Error',
            message:
              'Opps! There was an error creating customer. Please try again or contact an administrator.',
          }
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  edit: async (customerId, newValues, token) => {
    try {
      // console.log(customerId)
      // console.log(newValues)

      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = new FormData()

      if (newValues.contact_number) {
        bodyParameters.append('contact_number', newValues.contact_number)
      }

      if (newValues.alt_contact) {
        bodyParameters.append('alt_contact', newValues.alt_contact)
      }

      if (newValues.business_name) {
        bodyParameters.append('business_name', newValues.business_name)
      }

      if (newValues.business_address) {
        bodyParameters.append('business_address', newValues.business_address)
      }

      if (newValues.business_latlng) {
        bodyParameters.append(
          'business_latlng',
          newValues.business_latlng.coords.lat +
            ',' +
            newValues.business_latlng.coords.lng
        )
      }

      var response = await Axios.post(
        API_BASE_URL + '/partner-customer/update/' + customerId,
        bodyParameters,
        config
      )
      // console.log(response)
      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Updated',
              message: response.data.message,
            }

          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error updating customer details. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  delete: async (customerId, token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = new FormData()

      var response = await Axios.post(
        API_BASE_URL + '/partner-customer/delete/' + customerId,
        bodyParameters,
        config
      )

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Deleted',
              message: response.data.message,
            }

          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error updating customer details. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
}

export const riderAPI = {
  getAvailable: async (token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var response = await Axios.get(
        API_BASE_URL + '/partner-rider/available',
        config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Success',
              data: response.data.data,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting riders. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  getPerPage: async (page, token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var response = await Axios.get(
        API_BASE_URL + '/partner-rider/page/' + page,
        config
      )

      // console.log(response)

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Success',
              data: response.data.data,
              totalRiders: response.data.total,
              perPage: response.data.perPage,
              currentPage: response.data.currentPage,
            }
          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error getting riders. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  create: async (userInputs, token) => {
    try {
      if (userInputs) {
        var config = {
          headers: { Authorization: 'Bearer ' + token },
        }

        var bodyParameters = new FormData()

        bodyParameters.append('mobile_number', userInputs.mobile_number)
        bodyParameters.append('alt_contact', userInputs.alt_contact)
        bodyParameters.append('full_name', userInputs.full_name)
        bodyParameters.append('password', userInputs.password)

        var response = await Axios.post(
          API_BASE_URL + '/partner-rider/create',
          bodyParameters,
          config
        )

        if (response.status == 200) {
          switch (response.data.status) {
            case 'SUCCESS':
              return {
                status: 'SUCCESS',
                title: 'Success',
                data: response.data.data,
              }

            default:
              return {
                status: 'ERROR',
                title: 'Error',
                message: response.data.message,
              }
          }
        } else {
          return {
            status: 'ERROR',
            title: 'Error',
            message:
              'Opps! There was an error creating rider. Please try again or contact an administrator.',
          }
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  edit: async (riderId, newValues, token) => {
    try {
      // console.log(riderId)
      // console.log(newValues)

      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = new FormData()

      if (newValues.mobile_number) {
        bodyParameters.append('mobile_number', newValues.mobile_number)
      }

      if (newValues.alt_contact) {
        bodyParameters.append('alt_contact', newValues.alt_contact)
      }

      if (newValues.full_name) {
        bodyParameters.append('full_name', newValues.full_name)
      }

      if (newValues.password) {
        bodyParameters.append('password', newValues.password)
      }

      var response = await Axios.post(
        API_BASE_URL + '/partner-rider/update/' + riderId,
        bodyParameters,
        config
      )
      // console.log(response)
      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Updated',
              message: response.data.message,
            }

          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error updating rider details. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
  delete: async (riderId, token) => {
    try {
      var config = {
        headers: { Authorization: 'Bearer ' + token },
      }

      var bodyParameters = new FormData()

      var response = await Axios.post(
        API_BASE_URL + '/partner-rider/delete/' + riderId,
        bodyParameters,
        config
      )

      if (response.status == 200) {
        switch (response.data.status) {
          case 'SUCCESS':
            return {
              status: 'SUCCESS',
              title: 'Deleted',
              message: response.data.message,
            }

          default:
            break
        }
      } else {
        return {
          status: 'ERROR',
          title: 'Error',
          message:
            'Opps! There was an error updating rider details. Please try again or contact an administrator.',
        }
      }
    } catch (error) {
      // console.log(error)
      return {
        status: 'ERROR',
        title: 'Internal Server Error',
        message:
          'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      }
    }
  },
}
