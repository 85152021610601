export const sailiFormatting = {
  mobileNumber: (value) => {
    let number = value.trim()
    let numberLength = number.length
    if (numberLength > 0) {
      if (number.substring(0, 1) == '9') {
        if (numberLength > 1) {
          if (
            number.substring(1, 2) == '6' ||
            number.substring(1, 2) == '7' ||
            number.substring(1, 2) == '8'
          ) {
            return {
              type: 'MOBILE',
              number: number.replace(/[^0-9]/g, ''),
            }
          } else {
            return {
              type: 'MOBILE',
              number: number.substring(0, 1),
            }
          }
        } else {
          return {
            type: 'MOBILE',
            number: number.replace(/[^0-9]/g, ''),
          }
        }
      } else {
        return {
          type: 'EMPTY',
          number: '',
        }
      }
    } else {
      return {
        type: 'EMPTY',
        number: '',
      }
    }
  },
  altContactNumber: (value) => {
    let number = value.trim()
    let numberLength = number.length
    if (numberLength > 0) {
      // If Mobile Number
      if (number.substring(0, 1) == '9') {
        if (numberLength > 1) {
          if (
            number.substring(1, 2) == '6' ||
            number.substring(1, 2) == '7' ||
            number.substring(1, 2) == '8'
          ) {
            return {
              type: 'MOBILE',
              number: number.replace(/[^0-9]/g, ''),
            }
          } else {
            return {
              type: 'MOBILE',
              number: number.substring(0, 1),
            }
          }
        } else {
          return {
            type: 'MOBILE',
            number: number.replace(/[^0-9]/g, ''),
          }
        }
      } else if (number.substring(0, 1) == '0') {
        //If Landline
        if (numberLength > 1) {
          if (number.substring(1, 2) == '0') {
            return {
              type: 'LANDLINE',
              number: number.substring(0, 1),
              location: 'INSIDE_VALLEY',
            }
          } else if (number.substring(1, 2) == '1') {
            if (numberLength > 2) {
              if (
                number.substring(2, 3) == '4' ||
                number.substring(2, 3) == '5' ||
                number.substring(2, 3) == '6'
              ) {
                return {
                  type: 'LANDLINE',
                  number: number.replace(/[^0-9]/g, ''),
                  location: 'INSIDE_VALLEY',
                }
              } else {
                return {
                  type: 'LANDLINE',
                  number: number.replace(/[^0-9]/g, ''),
                  location: 'OUTSIDE_VALLEY',
                }
              }
            } else {
              return {
                type: 'LANDLINE',
                number: number.replace(/[^0-9]/g, ''),
                location: 'INSIDE_VALLEY',
              }
            }
          } else {
            return {
              type: 'LANDLINE',
              number: number.replace(/[^0-9]/g, ''),
              location: 'OUTSIDE_VALLEY',
            }
          }
        } else {
          return {
            type: 'LANDLINE',
            number: number.replace(/[^0-9]/g, ''),
            location: 'INSIDE_VALLEY',
          }
        }
      } else {
        return {
          type: 'EMPTY',
          number: '',
        }
      }
    } else {
      return {
        type: 'EMPTY',
        number: '',
      }
    }
  },
}

export const googleResponseFormatting = {
  //Extract Address From Google Response
  placeDetails: function (details) {
    // console.log(details)
    var LandMark = ''
    var StreetNumber = ''
    var StreetName = ''
    var Sublocality = ''
    var Locality = ''
    var City = ''
    var District = ''
    var Zone = ''
    var Province = ''
    var Country = ''
    var PostalCode = ''
    var PlaceId = details.results[0].place_id
    var PlusCode = details.plus_code
    var FullAddress = ''
    var MainText = ''
    var SecondaryText = ''

    var placeDetails = details.results
    placeDetails = placeDetails.slice(0).reverse()
    placeDetails.forEach((placeDetail, index) => {
      var addressComponents = placeDetail.address_components
      addressComponents.forEach((addressComponent) => {
        const addressType = addressComponent.types

        if (addressType.includes('street_number')) {
          StreetNumber = addressComponent.long_name
        }

        if (addressType.includes('route')) {
          StreetName = addressComponent.long_name
          if (addressType.includes('street_number')) {
            StreetNumber = addressComponent.long_name
          } else {
            StreetNumber = ''
          }
        }

        if (addressType.includes('neighborhood')) {
          Sublocality = addressComponent.long_name
        }

        if (addressType.includes('sublocality_level_1')) {
          Locality = addressComponent.long_name
        }

        if (addressType.includes('locality')) {
          City = addressComponent.long_name
        }

        if (addressType.includes('administrative_area_level_3')) {
          if (!District) {
            District = addressComponent.long_name
          }
        }

        if (addressType.includes('administrative_area_level_2')) {
          if (!Zone) {
            Zone = addressComponent.long_name
          }
        }

        if (addressType.includes('administrative_area_level_1')) {
          if (!Province) {
            Province = addressComponent.long_name
          }
        }

        if (addressType.includes('postal_code')) {
          PostalCode = addressComponent.long_name
        }

        if (addressType.includes('country')) {
          Country = addressComponent.long_name
        }
      })
    })

    if (StreetName) {
      if (StreetName != 'Unnamed Road') {
        if (StreetNumber) {
          FullAddress += StreetName + ' - ' + StreetNumber + ', '
          MainText += StreetName + ' - ' + StreetNumber
        } else {
          FullAddress += StreetName + ', '
          MainText += StreetName
        }
      }
    }

    if (Sublocality) {
      FullAddress += Sublocality + ', '
      if (MainText) {
        MainText += ', ' + Sublocality
      } else {
        MainText += Sublocality
      }
    }

    if (Locality) {
      FullAddress += Locality + ', '
      SecondaryText += Locality + ', '
    }

    if (City) {
      if (District == City) {
        FullAddress += District + ', '
        SecondaryText += District + ', '
      } else {
        FullAddress += District + ', ' + City + ', '
        SecondaryText += District + ', ' + City + ', '
      }
    } else if (District) {
      FullAddress += District + ', '
      SecondaryText += District + ', '
    }

    if (Zone) {
      FullAddress += Zone + ', '
      SecondaryText += Zone + ', '
    }

    if (Country) {
      FullAddress += Country
      SecondaryText += Country
    }

    var googleAddress = {
      land_mark: LandMark,
      street_number: StreetNumber,
      street_name: StreetName,
      sub_locality: Sublocality,
      locality: Locality,
      city: City,
      district: District,
      zone: Zone,
      province: Province,
      postal_code: PostalCode,
      country: Country,
      place_id: PlaceId,
      plus_code: PlusCode,
      full_address: FullAddress,
      main_text: MainText,
      secondary_text: SecondaryText,
    }
    // console.log(googleAddress)
    return googleAddress
  },

  //Re Arrange Google Place Info
  reArrangePlaceDetails: function (details, newDetails) {
    details.land_mark = newDetails.structured_formatting.main_text
    details['types'] = newDetails.types
    details.full_address =
      newDetails.structured_formatting.main_text + ', ' + details.full_address
    details.main_text =
      newDetails.structured_formatting.main_text + ', ' + details.main_text

    if (details.street_name == 'Unnamed Road') {
      newDetails.terms.forEach((item) => {
        if (
          item.value.search('Road') != -1 ||
          item.value.search('Marg') != -1
        ) {
          details.street_name = item.value
        }
      })
    }
    // console.log(details)
    return details
  },
}

export const timeFormatting = {
  secondsToHrMin: (duration) => {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600)
    var mins = ~~((duration % 3600) / 60)
    var secs = ~~duration % 60

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = ''

    if (hrs > 0) {
      ret += hrs + 'Hrs, '
    }

    if (mins > 0) {
      ret += mins + 'Mins'
    }
    return ret
  },
}
