import { createContext, useContext, useState } from 'react'

const newRiderContext = createContext()

export const ProvideNewRider = ({ children }) => {
  const rider = useProvideNewRider()
  return (
    <newRiderContext.Provider value={rider}>
      {children}
    </newRiderContext.Provider>
  )
}

export const useNewRider = () => {
  return useContext(newRiderContext)
}

function useProvideNewRider() {
  const [newRider, setNewRider] = useState(false)

  const checkNewRider = () => {
    setNewRider(true)
  }

  const resetCheckNewRider = () => {
    setNewRider(false)
  }

  return {
    newRider,
    checkNewRider,
    resetCheckNewRider,
  }
}
