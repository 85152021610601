import { createContext, useContext, useState } from 'react'

const newRouteContext = createContext()

export const ProvideNewRoute = ({ children }) => {
  const route = useProvideNewRoute()
  return (
    <newRouteContext.Provider value={route}>
      {children}
    </newRouteContext.Provider>
  )
}

export const useNewRoute = () => {
  return useContext(newRouteContext)
}

function useProvideNewRoute() {
  const [newRoute, setNewRoute] = useState(false)

  const checkNewRoute = () => {
    setNewRoute(true)
  }

  const resetCheckNewRoute = () => {
    setNewRoute(false)
  }

  return {
    newRoute,
    checkNewRoute,
    resetCheckNewRoute,
  }
}
