import { Base64 } from 'js-base64'
import { reactLocalStorage } from 'reactjs-localstorage'

const TokenKeyName = Base64.encode('TOKEN')

export const userTokens = {
  set: (value) => {
    let keyValue = Base64.encode(value)
    reactLocalStorage.set(TokenKeyName, keyValue)
    return true
  },
  get: () => {
    let keyValue = reactLocalStorage.get(TokenKeyName, false)
    if (keyValue) {
      let value = Base64.decode(keyValue)
      return value
    } else {
      return false
    }
  },

  remove: () => {
    reactLocalStorage.remove(TokenKeyName)
    return true
  },
}
