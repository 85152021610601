function Index(props) {
  const { icon, title, description } = props
  return (
    <div className='info-box info-box-full'>
      <div className='info-box-inner'>
        <div className='info-box-content'>
          <div className='info-box-icon-wrapper'>
            <span className='material-icons'>{icon}</span>
          </div>
          <h6>{title}</h6>
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default Index
