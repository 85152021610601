import { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import momentTz from 'moment-timezone'
import { routeAPI, orderAPI } from '../../../api/saili'

import { useAuth } from '../../../context/user/auth'

const Index = (props) => {
  const { visible, onHide } = props
  var auth = useAuth()

  // === Component States ===

  // Modal State
  const [disableInputs, setDisableInputs] = useState(false)

  //Available Orders
  const [availableOrders, setAvailableOrders] = useState([])
  const [filteredOrders, setFilteredOrders] = useState([])
  const [selectedOrders, setSelectedOrders] = useState([])

  //Route Form State
  const [noOfRider, setNoOfRider] = useState('AUTO')
  const [riderCapacity, setRiderCapacity] = useState('20')
  const [deliveryStartHour, setDeliveryStartHour] = useState('09')
  const [deliveryStartMin, setDeliveryStartMin] = useState('00')
  const [deliveryStartPeriod, setDeliveryStartPeriod] = useState('AM')

  const [deliveryEndHour, setDeliveryEndHour] = useState('06')
  const [deliveryEndMin, setDeliveryEndMin] = useState('00')
  const [deliveryEndPeriod, setDeliveryEndPeriod] = useState('PM')
  const [errorResponse, setErrorResponse] = useState()

  useEffect(async () => {
    var response = await orderAPI.getWarehouseOrders(auth.user)

    if (response.status == 'SUCCESS') {
      setAvailableOrders(response.data)
      setFilteredOrders(response.data)
    }
  }, [visible])

  // === Component Handler ===

  const handleRiderCapacity = (number) => {
    setRiderCapacity(() => {
      return number.replace(/[^0-9]/g, '')
    })
  }

  const handleSelectAllOrder = () => {
    if (availableOrders.length > 0) {
      setSelectedOrders(availableOrders)
      setFilteredOrders([])
    }
  }

  const handleSelectOrder = (orderId) => {
    var selectedOrder = filteredOrders.filter((order) => {
      return orderId == order._id
    })

    var removedOrder = filteredOrders.filter((order) => {
      return orderId != order._id
    })

    setSelectedOrders(() => {
      return [...selectedOrders, selectedOrder[0]]
    })
    setFilteredOrders(removedOrder)
  }

  const handleRemoveSelectedOrder = (orderId) => {
    var selectedOrder = selectedOrders.filter((order) => {
      return orderId == order._id
    })

    var removedOrder = selectedOrders.filter((order) => {
      return orderId != order._id
    })

    setFilteredOrders(() => {
      return [...filteredOrders, selectedOrder[0]]
    })
    setSelectedOrders(removedOrder)
  }

  const handleRemoveAllOrder = () => {
    if (availableOrders.length > 0) {
      setSelectedOrders([])
      setFilteredOrders(availableOrders)
    }
  }

  const handleOnCreateRoute = async () => {
    var userInput = getInput()

    if (userInput) {
      switch (userInput.status) {
        case 'OK':
          setDisableInputs(true)
          // console.log(userInput.data)

          var createResponse = await routeAPI.create(userInput.data, auth.user)

          if (createResponse) {
            // console.log(createResponse)
            if (createResponse.status == 'SUCCESS') {
              setDisableInputs(false)
              onHide()
            } else {
              setErrorResponse(createResponse)
              setDisableInputs(false)
            }
          }
          break

        case 'ERROR':
          alert(userInput.message)
          break

        default:
          break
      }
    }
  }

  const getInput = () => {
    var npDateTime = momentTz.tz(Date.now(), 'Asia/Kathmandu')
    var npDate = npDateTime.format('YYYY-MM-DD')

    var dshr = 0
    var dehr = 0

    if (deliveryStartPeriod == 'PM') {
      if (deliveryStartHour == 12) {
        dshr = 12
      } else {
        dshr = Number(deliveryStartHour) + 12
      }
    }

    if (deliveryEndPeriod == 'PM') {
      if (deliveryEndHour == 12) {
        dehr = 12
      } else {
        dehr = Number(deliveryEndHour) + 12
      }
    }

    var startTime = moment(
      `${npDate} ${dshr}:${deliveryStartMin}:00+05:45`
    ).valueOf()

    var endTime = moment(
      `${npDate} ${dehr}:${deliveryStartMin}:00+05:45`
    ).valueOf()

    // console.log(startTime, endTime)

    var userInput = {
      selectedOrders: [],
      noOfRider: noOfRider,
      riderCapacity: riderCapacity,
      tripStart: startTime,
      tripEnd: endTime,
    }
    userInput.selectedOrders = selectedOrders.map((order) => {
      return order._id
    })
    if (userInput.selectedOrders.length < 1) {
      return {
        status: 'ERROR',
        message: 'Please select the orders to generate route.',
      }
    } else {
      if (!userInput.riderCapacity > 0) {
        return {
          status: 'ERROR',
          message: 'Please check rider capacity.',
        }
      } else {
        if (!userInput.tripStart || !userInput.tripEnd) {
          return {
            status: 'ERROR',
            message: 'Please check trip timing.',
          }
        } else {
          return {
            status: 'OK',
            data: userInput,
          }
        }
      }
    }
  }

  return (
    <Modal
      size='xl'
      backdrop='static'
      keyboard={false}
      show={visible}
      onHide={() => onHide()}
      aria-labelledby='create-route-title-xl'
      animation={false}
    >
      <Modal.Header closeButton={disableInputs ? false : true}>
        <Modal.Title id='create-route-title-xl'>New Route</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-body'>
        {errorResponse ? (
          <div className='alert alert-danger' role='alert'>
            <h4 className='alert-heading'>{errorResponse.title}</h4>
            <p>{errorResponse.message}</p>
          </div>
        ) : null}
        <div className='form-wrapper'>
          <h6 className='mb-3'>Route Settings</h6>
          <div className='row'>
            <div className='col-sm-2'>
              <div className='form-group'>
                <label htmlFor='inputRiderCapacity'>No. Of Riders</label>
                <select
                  value={noOfRider}
                  onChange={(e) => setNoOfRider(e.target.value)}
                  className='form-control form-control-lg'
                >
                  <option value='AUTO'>Auto</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                  <option value='7'>7</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                  <option value='10'>10</option>
                </select>
                <small className='form-text text-muted'>
                  Number of available riders for delivery.
                </small>
              </div>
            </div>
            <div className='col-sm-2'>
              <div className='form-group'>
                <label htmlFor='inputRiderCapacity'>Rider Capacity</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  id='inputRiderCapacity'
                  maxLength='50'
                  value={riderCapacity}
                  onChange={(e) => handleRiderCapacity(e.target.value)}
                  readOnly={disableInputs}
                  required
                />
                <small className='form-text text-muted'>
                  Number of packages a rider can carry per trip.
                </small>
              </div>
            </div>
            <div className='col-sm-8'>
              <div className='row'>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='inputRiderCapacity'>Trip Starts</label>
                    <div className='row no-gutters'>
                      <div className='col'>
                        <select
                          value={deliveryStartHour}
                          onChange={(e) => setDeliveryStartHour(e.target.value)}
                          className='form-control form-control-lg'
                        >
                          <option value='01'>01</option>
                          <option value='02'>02</option>
                          <option value='03'>03</option>
                          <option value='04'>04</option>
                          <option value='05'>05</option>
                          <option value='06'>06</option>
                          <option value='07'>07</option>
                          <option value='08'>08</option>
                          <option value='09'>09</option>
                          <option value='10'>10</option>
                          <option value='11'>11</option>
                          <option value='12'>12</option>
                        </select>
                      </div>
                      <div className='col'>
                        <select
                          value={deliveryStartMin}
                          onChange={(e) => setDeliveryStartMin(e.target.value)}
                          className='form-control form-control-lg'
                        >
                          <option value='00'>00</option>
                          <option value='05'>05</option>
                          <option value='10'>10</option>
                          <option value='15'>15</option>
                          <option value='20'>20</option>
                          <option value='25'>25</option>
                          <option value='30'>30</option>
                          <option value='35'>35</option>
                          <option value='40'>40</option>
                          <option value='45'>45</option>
                          <option value='50'>50</option>
                          <option value='55'>55</option>
                          <option value='60'>60</option>
                        </select>
                      </div>
                      <div className='col'>
                        <select
                          value={deliveryStartPeriod}
                          onChange={(e) =>
                            setDeliveryStartPeriod(e.target.value)
                          }
                          className='form-control form-control-lg'
                        >
                          <option value='AM'>AM</option>
                          <option value='PM'>PM</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='inputRiderCapacity'>Trip Ends</label>
                    <div className='row no-gutters'>
                      <div className='col'>
                        <select
                          value={deliveryEndHour}
                          onChange={(e) => setDeliveryEndHour(e.target.value)}
                          className='form-control form-control-lg'
                        >
                          <option value='01'>01</option>
                          <option value='02'>02</option>
                          <option value='03'>03</option>
                          <option value='04'>04</option>
                          <option value='05'>05</option>
                          <option value='06'>06</option>
                          <option value='07'>07</option>
                          <option value='08'>08</option>
                          <option value='09'>09</option>
                          <option value='10'>10</option>
                          <option value='11'>11</option>
                          <option value='12'>12</option>
                        </select>
                      </div>
                      <div className='col'>
                        <select
                          value={deliveryEndMin}
                          onChange={(e) => setDeliveryEndMin(e.target.value)}
                          className='form-control form-control-lg'
                        >
                          <option value='00'>00</option>
                          <option value='05'>05</option>
                          <option value='10'>10</option>
                          <option value='15'>15</option>
                          <option value='20'>20</option>
                          <option value='25'>25</option>
                          <option value='30'>30</option>
                          <option value='35'>35</option>
                          <option value='40'>40</option>
                          <option value='45'>45</option>
                          <option value='50'>50</option>
                          <option value='55'>55</option>
                          <option value='60'>60</option>
                        </select>
                      </div>
                      <div className='col'>
                        <select
                          value={deliveryEndPeriod}
                          onChange={(e) => setDeliveryEndPeriod(e.target.value)}
                          className='form-control form-control-lg'
                        >
                          <option value='AM'>AM</option>
                          <option value='PM'>PM</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col'>
            <button
              className='btn btn-light btn-block mt-4'
              onClick={() => handleSelectAllOrder()}
              disabled={disableInputs}
            >
              Select All
            </button>
          </div>
          <div className='col'>
            <button
              className='btn btn-light btn-block mt-4'
              onClick={() => handleRemoveAllOrder()}
              disabled={disableInputs}
            >
              Remove All
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='form-wrapper mt-4 '>
              <h6 className='mb-3'>My Orders ({filteredOrders.length})</h6>
              <div className='mo-so-eq'>
                {filteredOrders.length > 0 ? (
                  <>
                    <ul className='mo-bordered-list'>
                      {filteredOrders.map((order) => {
                        return (
                          <li
                            key={order._id}
                            onClick={() => handleSelectOrder(order._id)}
                          >
                            <h6>{order.sender.name}</h6>
                            <p>
                              {order.recipient.full_name}
                              {' - '}
                              {order.recipient.mobile_number}
                              <br />
                              {order.recipient.address}
                            </p>
                          </li>
                        )
                      })}
                    </ul>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='form-wrapper mt-4'>
              <h6 className='mb-3'>
                Selected Orders ({selectedOrders.length})
              </h6>
              <div className='mo-so-eq'>
                {selectedOrders.length > 0 ? (
                  <>
                    <ul className='mo-bordered-list'>
                      {selectedOrders.map((order) => {
                        return (
                          <li
                            key={order._id}
                            onClick={() => handleRemoveSelectedOrder(order._id)}
                          >
                            <h6>{order.sender.name}</h6>
                            <p>
                              {order.recipient.full_name}
                              {' - '}
                              {order.recipient.mobile_number}
                              <br />
                              {order.recipient.address}
                            </p>
                          </li>
                        )
                      })}
                    </ul>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-primary btn-lg ml-3'
          onClick={() => handleOnCreateRoute()}
          disabled={disableInputs}
        >
          Create Routes
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default Index
