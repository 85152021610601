import { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { BeatLoader } from 'react-spinners'
import moment from 'moment'
import polyline from '@mapbox/polyline'
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
  Circle,
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import L, { divIcon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import markerbg from '../../../../assets/images/ic_loc_white.png'

import AppContainer from '../../../app-container'
import AppHeader from '../../../app-header'
import AppSidebar from '../../../app-sidebar'
import AppBody from '../../../app-body'
import AppPageContainer from '../../../app-page-container'
import { ProvideUserProfile } from '../../../../context/user/profile'

import { useAuth } from '../../../../context/user/auth'

import { routeAPI } from '../../../../api/saili'

import { timeFormatting } from '../../../../functions/formatting'

const momentTz = require('moment-timezone')

const Index = () => {
  return (
    <>
      <ProvideUserProfile>
        <AppContainer>
          <AppHeader
            get_estimate_visibility={true}
            delivery_slot_visibility={true}
            delivery_feature_visibility={true}
          />
          <AppBody>
            <AppSidebar active='' create_delivery_visibility={false} />
            <AppPageContainer>
              <DisplayRoute />
              <div className='pb-3'></div>
            </AppPageContainer>
          </AppBody>
        </AppContainer>
      </ProvideUserProfile>
    </>
  )
}

const DisplayRoute = () => {
  const myIcon = L.icon({
    iconUrl: markerbg,
    iconSize: [51, 83],
    iconAnchor: [25.5, 78],
    popupAnchor: [0, -78],
  })

  const mapPosition = [27.713829518379224, 85.30633672784037]
  const [deliveryRoutesResponse, setDeliveryRoutesResponse] = useState()

  const handleGetDeliveryRoute = async () => {
    var response = await routeAPI.getDeliveryRoute()

    if (response) {
      setDeliveryRoutesResponse(response.data)
    }
  }

  const getRandomColor = () => {
    var r = Math.floor(Math.random() * 255)
    var g = Math.floor(Math.random() * 255)
    var b = Math.floor(Math.random() * 255)
    return 'rgb(' + r + ' ,' + g + ',' + b + ')'
  }

  return (
    <>
      <div className='route-container'>
        <div
          className='btn btn-primary btn-block mb-3'
          onClick={() => handleGetDeliveryRoute()}
        >
          Generate Delivery Route
        </div>
        <div className='row mb-3'>
          <div className='col'>
            Total Orders :{' '}
            {deliveryRoutesResponse
              ? deliveryRoutesResponse.total_orders
              : null}
          </div>
          <div className='col'>
            Total Routes :{' '}
            {deliveryRoutesResponse
              ? deliveryRoutesResponse.total_routes.length
              : null}
          </div>
          <div className='col'>
            Total Duration In Field :{' '}
            {deliveryRoutesResponse
              ? timeFormatting.secondsToHrMin(deliveryRoutesResponse.total_cost)
              : null}
          </div>
        </div>

        <MapContainer center={mapPosition} zoom={12} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            maxZoom={19}
          />

          {deliveryRoutesResponse
            ? deliveryRoutesResponse.total_routes.map((routes, index) => {
                return (
                  <MarkerClusterGroup
                    key={index}
                    iconCreateFunction={(cluster) => {
                      return L.divIcon({
                        html: `<div class="custom-cluster-marker" style="background: ${
                          routes.route_color
                        }"><span>${cluster.getChildCount()}</span></div>`,
                        className: 'marker-cluster-custom',
                        iconSize: L.point(40, 40, true),
                      })
                    }}
                  >
                    {routes.route_info.map((route) => {
                      return (
                        <Marker
                          position={[
                            route.recipient_geometry.coordinates[1],
                            route.recipient_geometry.coordinates[0],
                          ]}
                          key={route.order_id}
                          icon={divIcon({
                            html: `<div class="marker-div-wrapper"><span class="route-position">${route.delivery_index}</span><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" version="1" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><path d="M18.5,10.2c0,2.57-2.1,5.79-6.16,9.51L12,20.01l-0.34-0.31C7.6,15.99,5.5,12.77,5.5,10.2 c0-3.84,2.82-6.7,6.5-6.7S18.5,6.35,18.5,10.2z" fill-opacity="1" fill="${routes.route_color}"/><path d="M12,2c4.2,0,8,3.22,8,8.2c0,3.32-2.67,7.25-8,11.8c-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2z M18,10.2 C18,6.57,15.35,4,12,4s-6,2.57-6,6.2c0,2.34,1.95,5.44,6,9.14C16.05,15.64,18,12.54,18,10.2z M12,12c-1.1,0-2-0.9-2-2s0.9-2,2-2 s2,0.9,2,2S13.1,12,12,12z" fill-opacity="0.3"/><path d="M0,0h24v24H0V0z" fill="none"/></svg></div>`,
                          })}
                        >
                          <Popup>
                            <strong>{route.recipient_fullname}</strong>
                            <br />
                            {route.recipient_address}
                            <br />
                            <br />
                            <strong>ETA : </strong>
                            {timeFormatting.secondsToHrMin(
                              route.eta.earliest
                            )}{' '}
                            - {timeFormatting.secondsToHrMin(route.eta.latest)}
                          </Popup>
                        </Marker>
                      )
                    })}
                  </MarkerClusterGroup>
                )
              })
            : null}

          {deliveryRoutesResponse
            ? deliveryRoutesResponse.total_routes.map((routes, index) => {
                return (
                  <Polyline
                    pathOptions={{
                      color: routes.route_color,
                      weight: 5,
                      smoothFactor: 1,
                    }}
                    positions={polyline.decode(routes.route_polyline)}
                    key={index}
                  />
                )
              })
            : null}

          <Marker position={mapPosition} icon={myIcon}>
            <Popup>
              <strong>Partner Warehouse</strong>
              <br />
              Yapikhya Marg, Kathmandu, Bagmati, Nepal
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </>
  )
}

export default Index
