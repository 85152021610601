import { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import CreateDeliveryModal from '../../components/page-components/create-delivery'
import { useNewOrder } from '../../context/order/new'
const Index = (props) => {
  var newOrder = useNewOrder()
  const { active, create_delivery_visibility } = props

  const [newDeliveryModalShow, setNewDeliveryModalShow] = useState(false)

  const handleOnCreateDeliveryModalHide = () => {
    newOrder.checkNewOrder()
    setNewDeliveryModalShow(false)
  }

  return (
    <div className='app-sidebar'>
      {/* {create_delivery_visibility == true ? (
        <>
          <div className='sidebar-button-container'>
            <div
              className='btn btn-primary btn-block'
              onClick={() => setNewDeliveryModalShow(true)}
            >
              New Delivery Order
            </div>
          </div>
          {newDeliveryModalShow ? (
            <CreateDeliveryModal
              visible={newDeliveryModalShow}
              onHide={handleOnCreateDeliveryModalHide}
            />
          ) : null}
        </>
      ) : null} */}
      <div className='sidebar-body'>
        <div className='sidebar-nav-container'>
          <ul className='slidebar-nav-list'>
            <li className={active == 'orders' ? 'active' : ''}>
              <Link to='/orders'>
                <span className='material-icons'>local_mall</span> Delivery
                Orders
              </Link>
            </li>
            <li className={active == 'routes' ? 'active' : ''}>
              <Link to='/routes'>
                <span className='material-icons'>alt_route</span> Delivery
                Routes
              </Link>
            </li>
            <li className={active == 'customers' ? 'active' : ''}>
              <Link to='/customers'>
                <span className='material-icons'>people</span> My Customers
              </Link>
            </li>

            <li className={active == 'riders' ? 'active' : ''}>
              <Link to='/riders'>
                <span className='material-icons'>delivery_dining</span> My
                Riders
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='sidebar-footer'>
        <div className='sidebar-copyright-info'>
          <p>&copy; 2021 Saili. All Rights Reserved.</p>
          <p>Version 3.0.0</p>
        </div>
      </div>
    </div>
  )
}

export default Index
