import { useState, useRef, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { css } from '@emotion/core'
import { BarLoader } from 'react-spinners'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import validator from 'validator'

import {
  sailiFormatting,
  googleResponseFormatting,
} from '../../../functions/formatting'
import {
  googleAPI,
  mapSettings,
  generateSessionToken,
} from '../../../api/google'

import { partnerAPI } from '../../../api/saili'

import icon from 'leaflet/dist/images/marker-icon.png'
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

let DefaultIcon = L.icon({
  iconRetinaUrl: iconRetina,
  iconUrl: icon,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [-2, -41],
  shadowUrl: iconShadow,
  shadowSize: [41, 41],
})

const overrideSpinner = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

L.Marker.prototype.options.icon = DefaultIcon

const Index = () => {
  const [signupResponse, setSignupResponse] = useState()
  const [fullName, setFullname] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [warehouseAddress, setWarehouseAddress] = useState()
  const [warehouseContactNumber, setWarehouseContactNumber] = useState('')
  const [warehouseContactPerson, setWarehouseContactPerson] = useState('')
  const [accountType, setAccountType] = useState('BUSINESS')
  const [agree, setAgree] = useState(false)

  const [warehouseLocationModalVisible, setWarehouseLocationModal] = useState(
    false
  )
  const [markerDraggable, setMarkerDraggable] = useState(true)
  const [markerPosition, setMarkerPosition] = useState(
    mapSettings.insideValley.mapCenter
  )
  const [sessionToken, setSessionToken] = useState(generateSessionToken)
  const [markerAddress, setMarkerAddress] = useState()
  const [fetchingAddressDetails, setFetchingAddressDetails] = useState(false)
  const [disableInputs, setDisableInputs] = useState(false)
  const markerRef = useRef()

  useEffect(() => {
    document.title = 'Signup | Saili Partner'
  }, [])

  const handleRegisterSubmit = async (e) => {
    e.preventDefault()

    var userInput = getUserInputs()
    if (userInput) {
      switch (userInput.status) {
        case 'OK':
          setDisableInputs(true)
          var response = await partnerAPI.signup(userInput.data)
          if (response) {
            setSignupResponse(response)
          }
          break

        case 'ERROR':
          alert(userInput.message)
          break

        default:
          break
      }
    }
  }

  const getUserInputs = () => {
    var userInput = {
      full_name: fullName,
      mobile_number: mobileNumber,
      email: emailAddress,
      password: password,
      account_type: accountType,
      business_name: businessName,
      warehouse_contact_person: warehouseContactPerson,
      warehouse_contact_number: warehouseContactNumber,
      warehouse_address: warehouseAddress,
      agree: agree,
    }
    if (!userInput.full_name || userInput.full_name.length < 3) {
      return {
        status: 'ERROR',
        message: 'Please enter a valid full name.',
      }
    } else {
      if (!userInput.mobile_number || userInput.mobile_number.length < 10) {
        return {
          status: 'ERROR',
          message: 'Please enter a valid mobile number.',
        }
      } else {
        if (!validator.isEmail(userInput.email)) {
          return {
            status: 'ERROR',
            message: 'Please enter a valid email address.',
          }
        } else {
          if (!userInput.password || userInput.password.length < 8) {
            return {
              status: 'ERROR',
              message: 'Password must be at lest 8 characters long.',
            }
          } else {
            if (
              !userInput.business_name ||
              userInput.business_name.length < 3
            ) {
              return {
                status: 'ERROR',
                message: 'Please enter a valid business name.',
              }
            } else {
              if (
                !userInput.warehouse_contact_number ||
                userInput.warehouse_contact_number.length < 9
              ) {
                return {
                  status: 'ERROR',
                  message: 'Warehouse contact number is missing.',
                }
              } else {
                if (
                  !userInput.warehouse_contact_person ||
                  userInput.warehouse_contact_person.length < 3
                ) {
                  return {
                    status: 'ERROR',
                    message: 'Warehouse contact name is missing.',
                  }
                } else {
                  if (!userInput.warehouse_address) {
                    return {
                      status: 'ERROR',
                      message: 'Warehouse address is missing.',
                    }
                  } else {
                    return {
                      status: 'OK',
                      data: userInput,
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  const handleFullName = (name) => {
    setFullname(name)
    if (!warehouseContactPerson || warehouseContactPerson == fullName) {
      setWarehouseContactPerson(name)
    }
  }

  const handleMobileNumber = (number) => {
    var formatted_mobile_number = sailiFormatting.mobileNumber(number)
    setMobileNumber(formatted_mobile_number.number)
    if (!warehouseContactNumber || warehouseContactNumber == mobileNumber) {
      setWarehouseContactNumber(formatted_mobile_number.number)
    }
  }

  const handleWarehouseContactNumber = (number) => {
    var formatted_number = sailiFormatting.altContactNumber(number)
    setWarehouseContactNumber(formatted_number.number)
  }

  const handleSetOnMap = () => {
    if (!disableInputs) {
      setWarehouseLocationModal(true)
    }
  }

  const handleMarkerEvents = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          // console.log(marker.getLatLng())
          setMarkerPosition(marker.getLatLng())
          marker.openPopup()
        }
      },
    }),
    []
  )

  const handleConfirmLatLng = async () => {
    setMarkerDraggable(false)
    setFetchingAddressDetails(true)
    var locationDetails = await googleAPI.getLocationDetailsFromLatLng(
      markerPosition.lat,
      markerPosition.lng,
      sessionToken
    )
    if (locationDetails) {
      var formatedLocationDetails = googleResponseFormatting.placeDetails(
        locationDetails
      )
      if (formatedLocationDetails) {
        formatedLocationDetails['geometry'] = {
          coords: {
            lat: markerPosition.lat,
            lng: markerPosition.lng,
          },
        }
        setMarkerAddress(formatedLocationDetails)
        setFetchingAddressDetails(false)
      }
    }
  }

  const handleCancelWarehouseLocation = () => {
    setMarkerDraggable(true)
    setMarkerAddress()
    markerRef.current.closePopup()
  }

  const handleConfirmWarehouseLocation = () => {
    setMarkerDraggable(true)
    setMarkerAddress()
    setMarkerPosition(mapSettings.insideValley.mapCenter)
    setWarehouseAddress(markerAddress)
    setWarehouseLocationModal(false)
  }

  return (
    <>
      <main className='signup-outer-wrapper bg-primary'>
        <div className='signup-container'>
          <section className='signup-inner-wrapper'>
            <div className='signup-header-wrapper'>
              <img
                src={`${process.env.PUBLIC_URL}/images/logo-white.svg`}
                alt='Saili'
                height='50'
              />
              <h3>Register for Saili Partner</h3>
            </div>

            <div className='signup-body-wrapper'>
              <div
                className={
                  signupResponse
                    ? 'signup-form-wrapper d-none'
                    : 'signup-form-wrapper'
                }
              >
                <h6 className='mb-3'>Personal Details</h6>
                <div className='form-group'>
                  <label htmlFor='inputFullName'>Full Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    minLength='3'
                    value={fullName}
                    id='inputFullName'
                    name='inputFullName'
                    maxLength='50'
                    readOnly={disableInputs ? true : false}
                    onChange={(e) => handleFullName(e.target.value)}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='inputMobileNumber'>Mobile Number</label>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text'>+977</span>
                    </div>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      minLength='10'
                      maxLength='10'
                      pattern='\d*'
                      value={mobileNumber}
                      id='inputMobileNumber'
                      name='inputMobileNumber'
                      readOnly={disableInputs ? true : false}
                      onChange={(e) => handleMobileNumber(e.target.value)}
                    />
                  </div>
                </div>
                <h6 className='mb-3'>Login Details</h6>
                <div className='form-group'>
                  <label htmlFor='inputEmail'>Email</label>
                  <input
                    type='email'
                    className='form-control form-control-lg'
                    value={emailAddress}
                    id='inputEmail'
                    name='inputEmail'
                    readOnly={disableInputs ? true : false}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='inputPassword'>Create Password</label>
                  <input
                    type='password'
                    className='form-control form-control-lg'
                    minLength='8'
                    id='inputPassword'
                    name='inputPassword'
                    value={password}
                    readOnly={disableInputs ? true : false}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <small className='form-text text-muted'>
                    The password should be at least 8 characters long. To make
                    it stronger use upper and lower case letters, numbers, and
                    symbols like ! " $ % ^.
                  </small>
                </div>
                <h6 className='mb-3'>Business Details</h6>
                <div className='form-group'>
                  <label htmlFor='inputBusinessName'>Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    minLength='3'
                    value={businessName}
                    id='inputBusinessName'
                    name='inputBusinessName'
                    maxLength='50'
                    readOnly={disableInputs ? true : false}
                    onChange={(e) => setBusinessName(e.target.value)}
                  />
                </div>

                <div className='form-group'>
                  <label htmlFor='inputWarehouseAddress'>
                    Warehouse Address
                  </label>
                  <div
                    className='warehouse-address-wrapper'
                    onClick={() => {
                      handleSetOnMap()
                    }}
                  >
                    {warehouseAddress ? (
                      warehouseAddress.full_address
                    ) : (
                      <div className='set-address-wrapper'>
                        <span className='material-icons'>location_on</span>Set
                        on Map
                      </div>
                    )}
                  </div>
                </div>
                <div className='form-group'>
                  <label htmlFor='inputWarehouseContactPersonName'>
                    Warehouse Contact Name
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    minLength='3'
                    value={warehouseContactPerson}
                    id='inputWarehouseContactPersonName'
                    name='inputWarehouseContactPersonName'
                    maxLength='50'
                    readOnly={disableInputs ? true : false}
                    onChange={(e) => setWarehouseContactPerson(e.target.value)}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='inputWarehouseContactNumber'>
                    Warehouse Contact Number
                  </label>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text'>+977</span>
                    </div>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      minLength='10'
                      maxLength='10'
                      pattern='\d*'
                      value={warehouseContactNumber}
                      id='inputWarehouseContactNumber'
                      name='inputWarehouseContactNumber'
                      readOnly={disableInputs ? true : false}
                      onChange={(e) =>
                        handleWarehouseContactNumber(e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      type='checkbox'
                      checked={agree}
                      onChange={(e) => setAgree(e.target.checked)}
                      className='custom-control-input'
                      id='inputAgree'
                      // disabled={disableInputs ? true : false}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='inputAgree'
                    >
                      <small>
                        I agree to Saili's<a href='/#'> Terms of Use</a> and
                        acknowledge that I have read the
                        <a href='/#'> Privacy Policy</a>.
                      </small>
                    </label>
                  </div>
                </div>
                <button
                  type='button'
                  className='btn btn-lg btn-primary btn-block'
                  disabled={agree && !disableInputs ? false : true}
                  onClick={(e) => handleRegisterSubmit(e)}
                >
                  Register
                </button>
                <div className='login-links-wrapper mt-3'>
                  <p>
                    Already have an account? <Link to='/login'>Login Here</Link>
                  </p>
                </div>
              </div>
              {signupResponse && (
                <SignupSucess visible={true} data={signupResponse} />
              )}
            </div>
          </section>
        </div>
      </main>
      {warehouseLocationModalVisible ? (
        <Modal
          size='xl'
          backdrop='static'
          keyboard={false}
          show={warehouseLocationModalVisible}
          onHide={() => setWarehouseLocationModal(false)}
          aria-labelledby='set-warehouse-location-title-xl'
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id='set-warehouse-location-title-xl'>
              Set Warehouse Location
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='bg-body'>
            <div className='bg-white mb-2 p-3'>
              <strong>Help</strong> : Drag and drop the marker near the front
              gate of your warehouse.
            </div>
            <div className='warehouse-location-map-container'>
              <MapContainer
                center={mapSettings.insideValley.mapCenter}
                zoom={mapSettings.zoom}
                scrollWheelZoom={true}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  maxZoom={19}
                />
                <Marker
                  draggable={markerDraggable}
                  eventHandlers={handleMarkerEvents}
                  position={markerPosition}
                  ref={markerRef}
                >
                  <Popup minWidth={90}>
                    {fetchingAddressDetails ? (
                      <>
                        <h6 className='mb-3'>Fetching Address Details</h6>
                        <div className='text-center pt-4 pb-4'>
                          <BarLoader
                            color='#0098e3'
                            loading={true}
                            css={overrideSpinner}
                          />
                        </div>
                      </>
                    ) : markerAddress ? (
                      <>
                        <h6 className='mb-3'>Location Address</h6>
                        <p className='mb-3'>{markerAddress.full_address}</p>
                        <div className='row justify-content-center'>
                          <div className='col'>
                            <span
                              className='btn btn-sm btn-block btn-danger'
                              onClick={() => handleCancelWarehouseLocation()}
                            >
                              Retry
                            </span>
                          </div>
                          <div className='col'>
                            <span
                              className='btn btn-sm btn-block btn-primary'
                              onClick={() => handleConfirmWarehouseLocation()}
                            >
                              Confirm
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <h6 className='mb-3'>
                          Is this your warehouse location?
                        </h6>
                        <div className='row justify-content-center'>
                          <div className='col-sm-5'>
                            <span
                              className='btn btn-sm btn-block btn-primary'
                              onClick={() => handleConfirmLatLng()}
                            >
                              Yes
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  )
}

const SignupSucess = (props) => {
  const { visible, data } = props
  return (
    <div className={visible ? 'signup-response-message-wrapper' : 'd-none'}>
      <h5 className='mb-3'>{data.title}</h5>
      <p>{data.message}</p>
    </div>
  )
}

export default Index
