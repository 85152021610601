import { useEffect, useState } from 'react'
import moment from 'moment'
import Select from 'react-select'
import { useHistory, useLocation } from 'react-router-dom'
import AppContainer from '../../app-container'
import AppHeader from '../../app-header'
import AppBody from '../../app-body'
import AppCenterBody from '../../app-center-container'
import {
  ProvideUserProfile,
  useUserProfile,
} from '../../../context/user/profile'
import { useAuth } from '../../../context/user/auth'
import { partnerAPI } from '../../../api/saili'

const Index = (props) => {
  useEffect(() => {
    document.title = 'My Profile | Saili'
  }, [])

  return (
    <>
      <ProvideUserProfile>
        <AppContainer>
          <AppHeader
            get_estimate_visibility={false}
            delivery_slot_visibility={false}
            delivery_feature_visibility={false}
          />
          <AppCenterBody>
            <MyProfile />
          </AppCenterBody>
        </AppContainer>
      </ProvideUserProfile>
    </>
  )
}

const MyProfile = (props) => {
  const paymentProviders = [
    { value: 'Nepal Bank Ltd', label: 'Nepal Bank Ltd', type: 'Bank' },
    {
      value: 'Rastriya Banijya Bank Ltd',
      label: 'Rastriya Banijya Bank Ltd',
      type: 'Bank',
    },
    { value: 'Nabil Bank Ltd', label: 'Nabil Bank Ltd', type: 'Bank' },
    {
      value: 'Nepal Investment Bank Ltd',
      label: 'Nepal Investment Bank Ltd',
      type: 'Bank',
    },
    {
      value: 'Standard Chartered Bank Nepal Ltd',
      label: 'Standard Chartered Bank Nepal Ltd',
      type: 'Bank',
    },
    { value: 'Himalayan Bank Ltd', label: 'Himalayan Bank Ltd', type: 'Bank' },
    { value: 'Nepal SBI Bank Ltd', label: 'Nepal SBI Bank Ltd', type: 'Bank' },
    { value: 'Everest Bank Ltd', label: 'Everest Bank Ltd', type: 'Bank' },
    { value: 'Laxmi Bank Ltd', label: 'Laxmi Bank Ltd', type: 'Bank' },
    {
      value: 'Global IME Bank Ltd',
      label: 'Global IME Bank Ltd',
      type: 'Bank',
    },
    { value: 'Prabhu Bank Ltd', label: 'Prabhu Bank Ltd', type: 'Bank' },
    {
      value: 'Bank of Kathmandu Ltd',
      label: 'Bank of Kathmandu Ltd',
      type: 'Bank',
    },
    { value: 'Sanima Bank Ltd', label: 'Sanima Bank Ltd', type: 'Bank' },
  ]

  var history = useHistory()
  var location = useLocation()
  var auth = useAuth()
  var user = useUserProfile()
  // console.log(user)

  const [activeTab, setActiveTab] = useState('personal')
  const [disableInputs, setDisableInputs] = useState(false)
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState()
  const [accountNumber, setAccountNumber] = useState('')
  const [accountHolderName, setAccountHolderName] = useState('')
  const [branch, setBranch] = useState('')
  const [errorResponse, setErrorResponse] = useState()

  const toggleActiveTab = (tab_name) => {
    setActiveTab(tab_name)
  }

  const handleBack = () => {
    var { from } = location.state || { from: { pathname: '/orders' } }
    history.replace(from)
  }

  const handleAccountNumber = (number) => {
    setAccountNumber(number.replace(/[^0-9]/g, ''))
  }

  const handleOnSavePaymentOption = async () => {
    // console.log('Save')
    var userInput = getInputForAddPayment()

    if (userInput) {
      switch (userInput.status) {
        case 'OK':
          // console.log(userInput)
          setDisableInputs(true)

          var addPaymentResponse = await partnerAPI.addPaymentOption(
            userInput.data,
            auth.user
          )

          if (addPaymentResponse) {
            if (addPaymentResponse.status == 'SUCCESS') {
              setErrorResponse()
              setDisableInputs(false)
              window.location.reload()
            } else {
              setDisableInputs(false)
              setErrorResponse(addPaymentResponse)
            }
          }

          break

        case 'ERROR':
          alert(userInput.message)
          break

        default:
          break
      }
    }
  }

  const getInputForAddPayment = () => {
    var userInput = {
      service_provider: selectedPaymentProvider
        ? selectedPaymentProvider.value
        : null,
      service_provider_type: selectedPaymentProvider
        ? selectedPaymentProvider.type
        : null,
      account_number: accountNumber,
      account_holder_name: accountHolderName,
      branch: branch != '' ? branch : null,
    }

    if (!userInput.service_provider) {
      return {
        status: 'ERROR',
        message: 'Please select a service provider.',
      }
    } else {
      if (!userInput.service_provider_type) {
        return {
          status: 'ERROR',
          message: 'Service provider details are missing.',
        }
      } else {
        if (!userInput.account_number) {
          return {
            status: 'ERROR',
            message: 'Account number is missing.',
          }
        } else {
          if (!userInput.account_holder_name) {
            return {
              status: 'ERROR',
              message: 'Account holder name is missing.',
            }
          } else {
            if (
              userInput.service_provider_type == 'Bank' &&
              !userInput.branch
            ) {
              return {
                status: 'ERROR',
                message: 'Branch name is missing.',
              }
            } else {
              return {
                status: 'OK',
                data: userInput,
              }
            }
          }
        }
      }
    }
  }

  return (
    <>
      <div
        className='btn btn-outline-dark btn-back-to'
        onClick={() => handleBack()}
      >
        <span className='material-icons'>west</span>
        Back
      </div>
      <div className='row'>
        <div className='col-md-3'>
          <ul className='profile-nav-list'>
            <li
              className={activeTab == 'personal' ? 'active' : ''}
              onClick={() => toggleActiveTab('personal')}
            >
              <span className='material-icons'>person</span>
              Personal Profile
            </li>
            <li
              className={activeTab == 'business' ? 'active' : ''}
              onClick={() => toggleActiveTab('business')}
            >
              <span className='material-icons'>business_center</span>
              Business Profile
            </li>
            <li
              className={activeTab == 'billing' ? 'active' : ''}
              onClick={() => toggleActiveTab('billing')}
            >
              <span className='material-icons'>credit_card</span>
              Billing Method
            </li>
            <li
              className={activeTab == 'warehouse' ? 'active' : ''}
              onClick={() => toggleActiveTab('warehouse')}
            >
              <span className='material-icons'>store</span>
              Warehouse
            </li>
          </ul>
        </div>
        <div className='col-md-9'>
          {errorResponse ? (
            <div className='alert alert-danger' role='alert'>
              <h4 className='alert-heading'>{errorResponse.title}</h4>
              <p>{errorResponse.message}</p>
            </div>
          ) : null}

          {/* Personal Profile */}
          <div className={activeTab == 'personal' ? '' : 'd-none'}>
            {user.userProfile ? (
              <>
                <div className='profile-card'>
                  <div className='data-group'>
                    <p>Mobile Number</p>
                    <h6>{user.userProfile.mobile_number}</h6>
                  </div>
                  <div className='data-group'>
                    <p>Full Name</p>
                    <h6>{user.userProfile.personal_details.full_name}</h6>
                  </div>
                  <div className='data-group'>
                    <p>Signup Date</p>
                    <h6>{moment(user.userProfile.creation_date).calendar()}</h6>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          {/* Business Profile */}
          <div className={activeTab == 'business' ? '' : 'd-none'}>
            {user.userProfile ? (
              <div className='profile-card'>
                <div className='data-group'>
                  <p>Business Name</p>
                  <h6>
                    {user.userProfile.business_details.length > 0
                      ? user.userProfile.business_details[0].name
                      : ''}
                  </h6>
                </div>
                <div className='data-group'>
                  <p>Contact Number</p>
                  <h6>
                    {user.userProfile.business_details.length > 0
                      ? user.userProfile.business_details[0].phone
                      : ''}
                  </h6>
                </div>
                <div className='data-group'>
                  <p>Email Address</p>
                  <h6>
                    {user.userProfile.business_details.length > 0
                      ? user.userProfile.business_details[0].email
                      : ''}
                  </h6>
                </div>
                <div className='data-group'>
                  <p>Website</p>
                  <h6>
                    {user.userProfile.business_details.length > 0
                      ? user.userProfile.business_details[0].website
                      : ''}
                  </h6>
                </div>
                <div className='data-group'>
                  <p>Submission Date</p>
                  <h6>
                    {user.userProfile.business_details.length > 0
                      ? moment(
                          user.userProfile.business_details[0].submission_date
                        ).calendar()
                      : ''}
                  </h6>
                </div>
                <div className='data-group'>
                  <p>Verification Date</p>
                  <h6>
                    {user.userProfile.business_details.length > 0
                      ? user.userProfile.business_details[0].verification_date
                        ? moment(
                            user.userProfile.business_details[0].submission_date
                          ).calendar()
                        : ''
                      : ''}
                  </h6>
                </div>
              </div>
            ) : null}
          </div>
          {/* Billing */}
          <div className={activeTab == 'billing' ? '' : 'd-none'}>
            {user.userProfile ? (
              <>
                {user.userProfile.payment_options.length > 0 ? (
                  <>
                    <div className='profile-card'>
                      <div className='data-group'>
                        <p>Service Provider</p>
                        <h6>
                          {user.userProfile.payment_options[0].service_provider}
                        </h6>
                      </div>
                      <div className='data-group'>
                        <p>Type</p>
                        <h6>
                          {
                            user.userProfile.payment_options[0]
                              .service_provider_type
                          }
                        </h6>
                      </div>
                      <div className='data-group'>
                        <p>Account Number</p>
                        <h6>
                          {user.userProfile.payment_options[0].account_number}
                        </h6>
                      </div>
                      <div className='data-group'>
                        <p>Account Holder Name</p>
                        <h6>
                          {
                            user.userProfile.payment_options[0]
                              .account_holder_name
                          }
                        </h6>
                      </div>
                      <div className='data-group'>
                        <p>Branch</p>
                        <h6>
                          {user.userProfile.payment_options[0].branch
                            ? user.userProfile.payment_options[0].branch
                            : ''}
                        </h6>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='profile-card'>
                      <div className='form-group'>
                        <label htmlFor='inputServiceProvider'>
                          Service Provider
                        </label>
                        <div className='react-select-lg'>
                          <Select
                            isClearable
                            value={selectedPaymentProvider}
                            id='inputServiceProvider'
                            onChange={(option) =>
                              setSelectedPaymentProvider(option)
                            }
                            isDisabled={disableInputs}
                            options={paymentProviders}
                          />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputAccountNumber'>
                          Account Number
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-lg'
                          minLength='5'
                          pattern='\d*'
                          maxLength='25'
                          value={accountNumber}
                          id='inputAccountNumber'
                          name='inputAccountNumber'
                          readOnly={disableInputs ? true : false}
                          onChange={(e) => handleAccountNumber(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputAccountHolderName'>
                          Account Holder Name
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-lg'
                          minLength='3'
                          value={accountHolderName}
                          id='inputAccountHolderName'
                          name='inputAccountHolderName'
                          maxLength='50'
                          readOnly={disableInputs ? true : false}
                          onChange={(e) => setAccountHolderName(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputBranch'>Branch</label>
                        <input
                          type='text'
                          className='form-control form-control-lg'
                          minLength='3'
                          value={branch}
                          id='inputBranch'
                          name='inputBranch'
                          maxLength='25'
                          readOnly={disableInputs ? true : false}
                          onChange={(e) => setBranch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-9'></div>
                      <div className='col-sm-3'>
                        <div
                          className='btn btn-primary btn-block'
                          onClick={() => handleOnSavePaymentOption()}
                        >
                          Save
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : null}
          </div>
          {/* Warehouse */}
          <div className={activeTab == 'warehouse' ? '' : 'd-none'}>
            {user.userProfile ? (
              <div className='profile-card'>
                <div className='data-group'>
                  <p>Warehouse Address</p>
                  <h6>
                    {user.userProfile.business_details.length > 0
                      ? user.userProfile.business_details[0].warehouses.length >
                        0
                        ? user.userProfile.business_details[0].warehouses[0]
                            .formatted_address
                        : ''
                      : ''}
                  </h6>
                </div>
                <div className='data-group'>
                  <p>Warehouse Contact Person</p>
                  <h6>
                    {user.userProfile.business_details.length > 0
                      ? user.userProfile.business_details[0].warehouses.length >
                        0
                        ? user.userProfile.business_details[0].warehouses[0]
                            .contact_person
                        : ''
                      : ''}
                  </h6>
                </div>
                <div className='data-group'>
                  <p>Warehouse Contact Number</p>
                  <h6>
                    {user.userProfile.business_details.length > 0
                      ? user.userProfile.business_details[0].warehouses.length >
                        0
                        ? user.userProfile.business_details[0].warehouses[0]
                            .contact_number
                        : ''
                      : ''}
                  </h6>
                </div>
                <div className='data-group'>
                  <p>Status</p>
                  <h6>
                    {user.userProfile.business_details.length > 0
                      ? user.userProfile.business_details[0].warehouses.length >
                        0
                        ? user.userProfile.business_details[0].warehouses[0]
                            .verified
                          ? 'Verified'
                          : 'Pending'
                        : ''
                      : ''}
                  </h6>
                </div>
                <div className='data-group'>
                  <p>Submission Date</p>
                  <h6>
                    {user.userProfile.business_details.length > 0
                      ? user.userProfile.business_details[0].warehouses.length >
                        0
                        ? user.userProfile.business_details[0].warehouses[0]
                            .submission_date
                          ? moment(
                              user.userProfile.business_details[0].warehouses[0]
                                .submission_date
                            ).calendar()
                          : ''
                        : ''
                      : ''}
                  </h6>
                </div>
                <div className='data-group'>
                  <p>Verification Date</p>
                  <h6>
                    {user.userProfile.business_details.length > 0
                      ? user.userProfile.business_details[0].warehouses.length >
                        0
                        ? user.userProfile.business_details[0].warehouses[0]
                            .verification_date
                          ? moment(
                              user.userProfile.business_details[0].warehouses[0]
                                .verification_date
                            ).calendar()
                          : ''
                        : ''
                      : ''}
                  </h6>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
