import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AppContainer from '../../app-container'
import AppHeader from '../../app-header'
import AppSidebar from '../../app-sidebar'
import AppBody from '../../app-body'
import AppPageContainer from '../../app-page-container'

import polyline from '@mapbox/polyline'
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
  Circle,
  GeoJSON,
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import L, { divIcon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'

import * as turf from '@turf/turf'

const Index = () => {
  const mapPosition = [27.713829518379224, 85.30633672784037]

  const options = {
    bbox: [
      85.27451179737328,
      27.65804700066453,
      85.36232496843434,
      27.748165608382124,
    ],
  }

  const points = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [85.3291266846783, 27.742817150581836],
        },
      },
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [85.35043924443121, 27.718306973069613],
        },
      },
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [85.34837930806384, 27.69201431264227],
        },
      },
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [85.34408777396514, 27.680613792023394],
        },
      },
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [85.33945291713853, 27.67088440667004],
        },
      },
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [85.32417505574715, 27.687150236095157],
        },
      },
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [85.31713693982528, 27.677573452183307],
        },
      },
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [85.28091639203218, 27.69338229512616],
        },
      },
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [85.311386284133, 27.703413642832658],
        },
      },
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [85.30632227389654, 27.714507847338865],
        },
      },
    ],
  }

  const [featureCollection, setFeatureCollection] = useState()

  useEffect(() => {
    document.title = 'Zoning | Saili'

    // var points = turf.randomPoint(2, options)
    var voronoiPolygons = turf.voronoi(points, options)

    setFeatureCollection(voronoiPolygons)

    console.log(points)
    // console.log(turf)
  }, [])

  return (
    <>
      <AppContainer>
        <AppHeader
          get_estimate_visibility={false}
          delivery_slot_visibility={false}
          delivery_feature_visibility={false}
        />
        <AppBody>
          <AppPageContainer>
            <div className='large-map'>
              <MapContainer
                center={mapPosition}
                zoom={13}
                scrollWheelZoom={true}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  maxZoom={19}
                />
                {featureCollection ? (
                  <GeoJSON data={featureCollection}></GeoJSON>
                ) : null}

                {points
                  ? points.features.map((feature, index) => {
                      return (
                        <Marker
                          position={[
                            feature.geometry.coordinates[1],
                            feature.geometry.coordinates[0],
                          ]}
                          key={index}
                          icon={divIcon({
                            html: `<div class="marker-div-wrapper"><span class="route-position bg-success text-white">🗸</span><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" version="1" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><path d="M18.5,10.2c0,2.57-2.1,5.79-6.16,9.51L12,20.01l-0.34-0.31C7.6,15.99,5.5,12.77,5.5,10.2 c0-3.84,2.82-6.7,6.5-6.7S18.5,6.35,18.5,10.2z" fill-opacity="1" fill="#202020"/><path d="M12,2c4.2,0,8,3.22,8,8.2c0,3.32-2.67,7.25-8,11.8c-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2z M18,10.2 C18,6.57,15.35,4,12,4s-6,2.57-6,6.2c0,2.34,1.95,5.44,6,9.14C16.05,15.64,18,12.54,18,10.2z M12,12c-1.1,0-2-0.9-2-2s0.9-2,2-2 s2,0.9,2,2S13.1,12,12,12z" fill-opacity="0.3"/><path d="M0,0h24v24H0V0z" fill="none"/></svg></div>`,
                          })}
                        ></Marker>
                      )
                    })
                  : null}
              </MapContainer>
            </div>
            <div className='pb-3'></div>
          </AppPageContainer>
        </AppBody>
      </AppContainer>
    </>
  )
}

export default Index
