import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { BeatLoader } from 'react-spinners'

import { partnerAPI } from '../../../api/saili'
import { useAuth } from '../../../context/user/auth'
import { userTokens } from '../../../functions/local-storage'

const Index = () => {
  var auth = useAuth()
  var history = useHistory()
  const overrideSpinner = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `

  const [logoutError, setLogoutError] = useState()

  useEffect(async () => {
    document.title = 'Logout | Saili'
    let token = auth.user
    var response = await partnerAPI.logout(token)
    if (response) {
      if (response.status == 'SUCCESS') {
        userTokens.remove()
        auth.signout(() => {
          history.push('/login')
        })
      } else {
        setLogoutError(response)
      }
    }
  }, [])

  return (
    <>
      <main className='slv-outer-wrapper bg-primary'>
        <div className='slv-container'>
          <section className='slv-inner-wrapper'>
            <div className='slv-header-wrapper'>
              <img
                src={`${process.env.PUBLIC_URL}/images/logo-white.svg`}
                alt='Saili'
                height='50'
              />
              <h3>Logout from Saili</h3>
            </div>

            <div className='slv-body-wrapper'>
              {logoutError ? (
                <div className='slv-response-message-wrapper'>
                  <h5 className='mb-3'>{logoutError.title}</h5>
                  <p>{logoutError.message}</p>
                </div>
              ) : (
                <div className='slv-response-message-wrapper'>
                  <h5 className='mb-3'>Logging Out...</h5>
                  <p>Please wait your request is being processed</p>
                  <BeatLoader
                    color='#0098e3'
                    loading={true}
                    css={overrideSpinner}
                  />
                </div>
              )}
            </div>
          </section>
        </div>
      </main>
    </>
  )
}

export default Index
