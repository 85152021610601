import React from 'react'

const Index = (props) => {
  return (
    <div className='app-center-container'>
      <div className='container'>{props.children}</div>
    </div>
  )
}

export default Index
