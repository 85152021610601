import { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import moment from 'moment'
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
  Circle,
} from 'react-leaflet'
import validator from 'validator'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { css } from '@emotion/core'
import { BeatLoader } from 'react-spinners'

import {
  googleAPI,
  mapSettings,
  generateSessionToken,
} from '../../../api/google'

import { googleHandler } from '../../../functions/handler'
import { serviceArea } from '../../../functions/service-area'
import {
  sailiFormatting,
  googleResponseFormatting,
} from '../../../functions/formatting'
import { customerAPI, orderAPI } from '../../../api/saili'

import { useAuth } from '../../../context/user/auth'
import { useUserProfile } from '../../../context/user/profile'

import markerbg from '../../../assets/images/ic_loc_white_blue.png'

const myIcon = L.icon({
  iconUrl: markerbg,
  iconSize: [51, 83],
  iconAnchor: [25.5, 78],
  popupAnchor: [0, -78],
})

const Index = (props) => {
  const { visible, onHide, orderInfo } = props
  var myProfile = useUserProfile()
  var auth = useAuth()

  const codRequiredOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ]

  // === Component States ===

  // Modal State
  const [sessionToken, setSessionToken] = useState(generateSessionToken)

  const [disableInputs, setDisableInputs] = useState(false)

  //Existing Order Info
  const [orderInfoData, setOrderInfoData] = useState()

  // Sender Form State
  const [userProfile, setUserProfile] = useState()
  const [availableSenders, setAvailableSenders] = useState([])
  const [selectedSender, setSelectedSender] = useState()

  // Recipient Form State
  const [recipientFullName, setRecipientFullName] = useState('')
  const [recipientMobileNumber, setRecipientMobileNumber] = useState('')
  const [recipientAltContact, setRecipientAltContact] = useState('')
  const [recipientAddress, setRecipientAddress] = useState('')
  const [locationType, setLocationType] = useState('INSIDE_VALLEY')

  const [searchKeywords, setSearchKeywords] = useState('')
  const [searchPredictions, setSearchPredictions] = useState([])
  const [searchCoordsDetails, setSearchCoordsDetails] = useState()
  const [mapRef, setMapRef] = useState()
  const markerRef = useRef()

  // Package Form State
  const [productName, setProductName] = useState('')
  const [productValue, setProductValue] = useState('')
  const [selectedCodOption, setSelectedCodOption] = useState()
  const [productWeight, setProductWeight] = useState('')

  // Delivery Charge
  const [baseCharge, setBaseCharge] = useState('0')
  const [extraCharge, setExtraCharge] = useState('0')

  const [updatingDelivery, setUpdatingDelivery] = useState(false)
  const [updatingError, setUpdatingError] = useState()
  const [deliveryResponse, setDeliveryResponse] = useState()

  // ======
  useEffect(async () => {
    if (orderInfo) {
      // console.log(orderInfo)

      var myCustomers = await customerAPI.getAll(auth.user)
      if (myCustomers.status == 'SUCCESS') {
        var availableCustomers = myCustomers.data.map((customer) => {
          return {
            sender_id: customer._id,
            sender_name: customer.business_name,
            sender_contact: customer.contact_number,
            label:
              customer.business_name +
              ' - ' +
              customer.address.formatted_address,
            value: customer._id,
          }
        })
        setAvailableSenders(availableCustomers)
      }
      setUserProfile(myProfile.userProfile)

      setOrderInfoData(orderInfo)

      //Set Sender Info

      if (myCustomers.status == 'SUCCESS') {
        // console.log(myCustomers)
        let senderInExistingOrder = myCustomers.data.filter((customer) => {
          return customer._id == orderInfo.sender._id
        })
        if (senderInExistingOrder.length > 0) {
          setSelectedSender(() => {
            return {
              sender_id: senderInExistingOrder[0]._id,
              sender_name: senderInExistingOrder[0].business_name,
              sender_contact: senderInExistingOrder[0].contact_number,
              label:
                senderInExistingOrder[0].business_name +
                ' - ' +
                senderInExistingOrder[0].address.formatted_address,
              value: senderInExistingOrder[0]._id,
            }
          })
          // setSelectedSender(senderInExistingOrder[0])
        }
      }

      //Set Recipient Info
      setRecipientFullName(orderInfo.recipient.full_name)
      setRecipientMobileNumber(orderInfo.recipient.mobile_number)
      setRecipientAltContact(() => {
        return orderInfo.recipient.alt_contact_number
          ? orderInfo.recipient.alt_contact_number
          : ''
      })
      setRecipientAddress(() => {
        return {
          full_address: orderInfo.recipient.address,
          place_id: orderInfo.recipient.place_id,
          geometry: {
            coords: {
              lat: orderInfo.recipient.geometry.coordinates[1],
              lng: orderInfo.recipient.geometry.coordinates[0],
            },
          },
        }
      })
      setLocationType(orderInfo.recipient.address_type)

      //Set Package Info
      setProductName(orderInfo.package.title)
      setProductValue(orderInfo.package.price.value)
      if (orderInfo.cod.requested) {
        setSelectedCodOption(codRequiredOptions[0])
      } else {
        setSelectedCodOption(codRequiredOptions[1])
      }
      setProductWeight(orderInfo.package.weight.value)

      setBaseCharge(orderInfo.charge.base)
      setExtraCharge(orderInfo.charge.extra)
    }
  }, [orderInfo])

  // === Component Handler ===

  // Sender Form Handler

  // Recipient Form Handler

  const handleToggleLocationType = (location_type) => {
    setLocationType(location_type)
    if (location_type == 'INSIDE_VALLEY') {
      mapRef.flyTo(mapSettings.insideValley.mapCenter, mapSettings.zoom)
    } else {
    }
  }

  const handleRecipientMobileNumber = (number) => {
    var formatted_mobile_number = sailiFormatting.mobileNumber(number)
    setRecipientMobileNumber(formatted_mobile_number.number)
  }

  const handleRecipientAltContact = (number) => {
    var formatted_number = sailiFormatting.altContactNumber(number)
    // console.log(formatted_number)
    if (formatted_number.type == 'LANDLINE') {
      setRecipientAltContact(formatted_number.number)
      handleToggleLocationType(formatted_number.location)
    } else {
      setRecipientAltContact(formatted_number.number)
    }
  }

  const handleSearch = async (keyword) => {
    var searchResponse = await googleHandler.search(keyword, sessionToken)
    if (searchResponse) {
      if (searchResponse.status == 'OK') {
        if (searchResponse.type == 'GEOCODING') {
          setSearchCoordsDetails(searchResponse.response)
        } else if (searchResponse.type == 'AUTOCOMPLETE') {
          setSearchPredictions(searchResponse.response)
        } else if (searchResponse.type == 'PLUSCODE') {
        }
      } else if (searchResponse.status == 'SERVICE_NOT_AVAILABLE') {
        alert(searchResponse.message)
      }
    }
  }

  const handleSearchKeywordChange = (keyword) => {
    setSearchKeywords(keyword)
    setSearchCoordsDetails()
    setSearchPredictions([])

    if (keyword.length > 0) {
      handleSearch(keyword)
    }
  }

  const handleSelectLocation = async (selectedLocation) => {
    if (selectedLocation) {
      switch (selectedLocation.from) {
        case 'GEOCODING':
          setRecipientAddress(selectedLocation.location_details)
          mapRef.flyTo(
            [
              selectedLocation.location_details.geometry.coords.lat,
              selectedLocation.location_details.geometry.coords.lng,
            ],
            mapSettings.pointZoom
          )
          const marker = markerRef.current
          if (marker != null) {
            setTimeout(() => {
              marker.openPopup()
            }, 300)
          }
          break
        case 'AUTOCOMPLETE':
          var selectedPlaceCoordsResponse = await googleAPI.getLatLngFromPlaceId(
            selectedLocation.location_details.place_id
          )

          if (selectedPlaceCoordsResponse) {
            var response = serviceArea.check(
              selectedPlaceCoordsResponse.result.geometry.location.lat,
              selectedPlaceCoordsResponse.result.geometry.location.lng
            )
            if (response) {
              var locationDetails = await googleAPI.getLocationDetailsFromLatLng(
                selectedPlaceCoordsResponse.result.geometry.location.lat,
                selectedPlaceCoordsResponse.result.geometry.location.lng,
                sessionToken
              )
              if (locationDetails) {
                var formatedLocationDetails = googleResponseFormatting.placeDetails(
                  locationDetails
                )

                if (formatedLocationDetails) {
                  formatedLocationDetails['geometry'] = {
                    coords: {
                      lat:
                        selectedPlaceCoordsResponse.result.geometry.location
                          .lat,
                      lng:
                        selectedPlaceCoordsResponse.result.geometry.location
                          .lng,
                    },
                  }
                  var reArrangedLocationDetails = googleResponseFormatting.reArrangePlaceDetails(
                    formatedLocationDetails,
                    selectedLocation.location_details
                  )
                  // console.log(reArrangedLocationDetails)
                  setRecipientAddress(reArrangedLocationDetails)
                  mapRef.flyTo(
                    [
                      selectedPlaceCoordsResponse.result.geometry.location.lat,
                      selectedPlaceCoordsResponse.result.geometry.location.lng,
                    ],
                    mapSettings.pointZoom
                  )
                  const marker = markerRef.current
                  // console.log(marker)
                  if (marker != null) {
                    setTimeout(() => {
                      marker.openPopup()
                    }, 300)
                  }
                  setSearchKeywords('')
                  setSearchPredictions([])
                }
              }
            } else {
              alert(
                'Unfortunately, Saili is currently unavailable in this area.'
              )
            }
          }
          break
        default:
          break
      }
    }
  }

  // Package Form Handler
  const handleProductValueChange = (value) => {
    setProductValue(() => {
      return value.replace(/[^0-9]/g, '')
    })
  }

  const handleProductWeightChange = (value) => {
    setProductWeight(() => {
      return value.replace(/[^0-9]/g, '')
    })
  }

  const handleBaseChargeChange = (value) => {
    setBaseCharge(() => {
      return value.replace(/[^0-9]/g, '')
    })
  }

  const handleExtraChargeChange = (value) => {
    setExtraCharge(() => {
      return value.replace(/[^0-9]/g, '')
    })
  }

  //Form Button Handler

  const handleOnApplyUpdates = async (orderId) => {
    if (locationType == 'INSIDE_VALLEY') {
      var userInput = getInputForInsideValley()

      if (userInput) {
        switch (userInput.status) {
          case 'OK':
            setDisableInputs(true)
            setUpdatingDelivery(true)

            // Look For Changes
            var changesResponse = lookForChanges(userInput.data)

            // console.log('Updated Values', changesResponse)

            var editResponse = await orderAPI.edit(
              orderId,
              changesResponse,
              auth.user
            )

            if (editResponse) {
              // console.log(editResponse)
              if (editResponse.status == 'SUCCESS') {
                setUpdatingError()
                setDisableInputs(false)
                setUpdatingDelivery(false)
                onHide()
              } else {
                setDisableInputs(false)
                setUpdatingDelivery(false)
                setUpdatingError(editResponse)
              }
            }
            break

          case 'ERROR':
            alert(userInput.message)
            break

          default:
            break
        }
      }
    }
  }

  const lookForChanges = (newValues) => {
    // console.log('Old', orderInfo)
    // console.log('New', newValues)
    if (orderInfo.sender._id == newValues.sender.sender_id) {
      newValues.sender = undefined
    }

    if (orderInfo.recipient.full_name == newValues.recipient.full_name) {
      newValues.recipient.full_name = undefined
    }

    if (
      orderInfo.recipient.mobile_number == newValues.recipient.mobile_number
    ) {
      newValues.recipient.mobile_number = undefined
    }

    if (
      orderInfo.recipient.alt_contact_number == newValues.recipient.alt_contact
    ) {
      newValues.recipient.alt_contact = undefined
    }

    if (orderInfo.recipient.address_type == newValues.recipient.address_type) {
      if (
        orderInfo.recipient.geometry.coordinates[0] ==
          newValues.recipient.geometry.coords.lng &&
        orderInfo.recipient.geometry.coordinates[1] ==
          newValues.recipient.geometry.coords.lat
      ) {
        newValues.recipient.address_type = undefined
        newValues.recipient.geometry = undefined
        newValues.recipient.address = undefined
        newValues.recipient.place_id = undefined
      }
    }

    if (orderInfo.package.title == newValues.package.title) {
      newValues.package.title = undefined
    }

    if (orderInfo.package.price.value == newValues.package.price) {
      newValues.package.price = undefined
    }

    if (orderInfo.package.weight.value == newValues.package.weight) {
      newValues.package.weight = undefined
    }

    let codBooleanValue = newValues.package.cod == 'Yes' ? true : false

    if (orderInfo.cod.requested == codBooleanValue) {
      newValues.package.cod = undefined
    }

    if (Object.keys(newValues.package).length == 0) {
      newValues.package = undefined
    }

    if (Object.keys(newValues.recipient).length == 0) {
      newValues.recipient = undefined
    }

    newValues = JSON.parse(JSON.stringify(newValues))

    return newValues
  }

  const getInputForInsideValley = () => {
    var userInput = {
      sender: {
        sender_id: selectedSender ? selectedSender.sender_id : null,
        sender_business_name: selectedSender
          ? selectedSender.sender_name
          : null,
        sender_business_phone: selectedSender
          ? selectedSender.sender_contact
          : null,
      },
      recipient: {
        full_name: recipientFullName,
        mobile_number: recipientMobileNumber,
        alt_contact: recipientAltContact,
        place_id: recipientAddress ? recipientAddress.place_id : null,
        address: recipientAddress ? recipientAddress.full_address : null,
        geometry: recipientAddress ? recipientAddress.geometry : null,
        address_type: locationType,
      },
      package: {
        title: productName,
        weight: productWeight,
        price: productValue,
        cod: selectedCodOption ? selectedCodOption.value : null,
      },
      charge: {
        base: baseCharge,
        extra: extraCharge,
      },
    }

    // console.log(userInput)

    if (
      !userInput.sender.sender_id ||
      !userInput.sender.sender_business_name ||
      !userInput.sender.sender_business_phone
    ) {
      return {
        status: 'ERROR',
        message: 'Sender details are missing.',
      }
    } else {
      if (
        !userInput.recipient.full_name ||
        userInput.recipient.full_name.length < 3
      ) {
        return {
          status: 'ERROR',
          message: 'Recipient name is missing.',
        }
      } else {
        if (
          !userInput.recipient.mobile_number ||
          userInput.recipient.mobile_number.length < 10
        ) {
          return {
            status: 'ERROR',
            message: 'Recipient mobile number is missing.',
          }
        } else {
          if (!userInput.recipient.address || !userInput.recipient.geometry) {
            return {
              status: 'ERROR',
              message: 'Recipient address details are missing.',
            }
          } else {
            if (
              !userInput.package.title ||
              userInput.package.title.length < 3
            ) {
              return {
                status: 'ERROR',
                message: 'Product name is missing.',
              }
            } else {
              if (
                userInput.package.price == undefined ||
                userInput.package.price == null
              ) {
                return {
                  status: 'ERROR',
                  message: 'Product value is missing.',
                }
              } else {
                if (!userInput.package.weight) {
                  return {
                    status: 'ERROR',
                    message: 'Package weight is missing.',
                  }
                } else {
                  if (!userInput.package.cod) {
                    return {
                      status: 'ERROR',
                      message: 'Please check cod option.',
                    }
                  } else {
                    return {
                      status: 'OK',
                      data: userInput,
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return (
    <Modal
      size='xl'
      backdrop='static'
      keyboard={false}
      show={visible}
      onHide={() => onHide()}
      aria-labelledby='edit-delivery-title-xl'
      animation={false}
    >
      <Modal.Header closeButton={disableInputs ? false : true}>
        <Modal.Title id='edit-delivery-title-xl'>Edit Delivery</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-body'>
        {updatingError ? (
          <div className='alert alert-danger' role='alert'>
            <h4 className='alert-heading'>{updatingError.title}</h4>
            <p>{updatingError.message}</p>
          </div>
        ) : null}
        {/* Form State Screen */}
        <div className=''>
          {/* Sender Form */}
          <div className='store-info-wrapper'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='form-group'>
                  <h6 className='mb-3'>Sender</h6>
                  {availableSenders.length > 0 ? (
                    <div className='react-select-lg'>
                      <Select
                        value={selectedSender}
                        onChange={(option) => setSelectedSender(option)}
                        options={availableSenders}
                        isDisabled={disableInputs}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* Recipient Form */}
          <div className='order-form-wrapper'>
            <div className=''>
              <div className='row'>
                <div className='col-sm-12'>
                  <h6 className='mb-3'>Recipient Details</h6>
                  <div className='form-group'>
                    <label htmlFor='inputRecipientName'>Full Name</label>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      id='inputRecipientName'
                      maxLength='50'
                      value={recipientFullName}
                      onChange={(e) => setRecipientFullName(e.target.value)}
                      readOnly={disableInputs}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='inputRecipientMobile'>Mobile Number</label>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      id='inputRecipientMobile'
                      minLength='10'
                      maxLength='10'
                      pattern='\d*'
                      value={recipientMobileNumber}
                      onChange={(e) =>
                        handleRecipientMobileNumber(e.target.value)
                      }
                      readOnly={disableInputs}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='inputRecipientAltContact'>
                      Alternative Contact Number
                      <small className='form-text text-muted'>(Optional)</small>
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      id='inputRecipientAltContact'
                      maxLength='10'
                      pattern='\d*'
                      value={recipientAltContact}
                      onChange={(e) =>
                        handleRecipientAltContact(e.target.value)
                      }
                      readOnly={disableInputs}
                    />
                  </div>
                  <div className='form-group mb-0'>
                    <label>Address</label>
                    <div className='delivery-address-map-wrapper'>
                      <div className='search-address-input-wrapper'>
                        <div className='search-address-input'>
                          <input
                            value={searchKeywords}
                            type='text'
                            className='form-control form-control-lg'
                            placeholder='Search'
                            onChange={(e) =>
                              handleSearchKeywordChange(e.target.value)
                            }
                            readOnly={disableInputs}
                          />
                        </div>
                        <div
                          className={
                            searchCoordsDetails || searchPredictions
                              ? 'search-address-prediction-wrapper'
                              : 'search-address-prediction-wrapper d-none'
                          }
                        >
                          {searchCoordsDetails ? (
                            <ul className='prediction-list'>
                              <li
                                onClick={() =>
                                  handleSelectLocation({
                                    from: 'GEOCODING',
                                    location_details: searchCoordsDetails,
                                  })
                                }
                              >
                                <span className='material-icons'>
                                  location_on
                                </span>
                                <h6>{searchCoordsDetails.main_text}</h6>
                                <p>{searchCoordsDetails.secondary_text}</p>
                              </li>
                            </ul>
                          ) : null}

                          {searchPredictions ? (
                            <ul className='prediction-list'>
                              {searchPredictions.map((place) => {
                                return (
                                  <li
                                    key={place.place_id}
                                    onClick={() =>
                                      handleSelectLocation({
                                        from: 'AUTOCOMPLETE',
                                        location_details: place,
                                      })
                                    }
                                  >
                                    <span className='material-icons'>
                                      location_on
                                    </span>
                                    <h6>
                                      {place.structured_formatting.main_text}
                                    </h6>
                                    <p>
                                      {
                                        place.structured_formatting
                                          .secondary_text
                                      }
                                    </p>
                                  </li>
                                )
                              })}
                            </ul>
                          ) : null}
                        </div>
                      </div>
                      <MapContainer
                        center={mapSettings.insideValley.mapCenter}
                        zoom={mapSettings.zoom}
                        scrollWheelZoom={true}
                        zoomControl={false}
                        whenCreated={(map) => setMapRef(map)}
                      >
                        <TileLayer
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                          maxZoom={19}
                        />
                        {recipientAddress ? (
                          <>
                            <Marker
                              position={[
                                recipientAddress.geometry.coords.lat,
                                recipientAddress.geometry.coords.lng,
                              ]}
                              icon={myIcon}
                              ref={markerRef}
                            >
                              <Popup>
                                {recipientAddress.main_text ? (
                                  <>
                                    <strong>
                                      {recipientAddress.main_text}
                                    </strong>
                                    <br />
                                    {recipientAddress.secondary_text}
                                  </>
                                ) : (
                                  <>
                                    <strong>
                                      {recipientAddress.full_address}
                                    </strong>
                                  </>
                                )}
                              </Popup>
                            </Marker>
                          </>
                        ) : null}
                      </MapContainer>
                      {recipientAddress ? (
                        <div className='recipient-address-wrapper'>
                          <div className='recipient-address-inner'>
                            <span className='material-icons'>location_on</span>
                            {recipientAddress.main_text ? (
                              <>
                                <h5>{recipientAddress.main_text}</h5>
                                <p>{recipientAddress.secondary_text}</p>
                              </>
                            ) : (
                              <>
                                <h5>{recipientAddress.full_address}</h5>
                              </>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Package Form */}
          <div className='order-form-wrapper mt-4'>
            <div className=''>
              <h6 className='mb-3'>Package Details</h6>
              <div className='form-group'>
                <label htmlFor='inputPackageName'>Product Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  id='inputPackageName'
                  maxLength='50'
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  readOnly={disableInputs}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor='inputPackageWeight'>Weight</label>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    minLength='1'
                    id='inputPackageWeight'
                    value={productWeight}
                    onChange={(e) => handleProductWeightChange(e.target.value)}
                    readOnly={disableInputs}
                    required
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text'>K.G.</span>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <label>COD Option</label>
                <div className='react-select-lg'>
                  <Select
                    value={selectedCodOption}
                    onChange={(option) => setSelectedCodOption(option)}
                    options={codRequiredOptions}
                    isDisabled={disableInputs}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='inputPackageValue'>COD Value</label>
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text'>NPR.</span>
                  </div>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    id='inputPackageValue'
                    value={productValue}
                    onChange={(e) => handleProductValueChange(e.target.value)}
                    readOnly={disableInputs}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Delivery Charge Form */}
          <div className='order-form-wrapper mt-4'>
            <div className=''>
              <h6 className='mb-3'>Delivery Charge</h6>
              <div className='form-group'>
                <label htmlFor='inputBaseCharge'>Base</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  id='inputBaseCharge'
                  maxLength='50'
                  value={baseCharge}
                  onChange={(e) => handleBaseChargeChange(e.target.value)}
                  readOnly={disableInputs}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor='inputExtraCharge'>Extra</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  id='inputExtraCharge'
                  maxLength='50'
                  value={extraCharge}
                  onChange={(e) => handleExtraChargeChange(e.target.value)}
                  readOnly={disableInputs}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor='inputTotalCharge'>Total</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  id='inputTotalCharge'
                  maxLength='50'
                  value={'' + (Number(baseCharge) + Number(extraCharge))}
                  readOnly={true}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-primary btn-lg ml-3'
          onClick={() => handleOnApplyUpdates(orderInfo._id)}
          disabled={disableInputs}
        >
          Apply Changes
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default Index
