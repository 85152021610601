import validator from 'validator'
import { serviceArea } from '../service-area'
import { googleAPI } from '../../api/google'
import { googleResponseFormatting } from '../formatting'
import { sailiValidate } from '../validate'
import { degreeConverter } from '../converter'

export const googleHandler = {
  search: async (keyword, sessionToken) => {
    let searchKeyword = keyword.trim()

    //If input is lat/lng
    var ifLatLng = validator.isLatLong(searchKeyword)
    if (ifLatLng) {
      var formattedLatLng = searchKeyword.replace(/ /g, '')
      let inputLat = parseFloat(formattedLatLng.split(',')[0])
      let inputLng = parseFloat(formattedLatLng.split(',')[1])

      var response = await serviceArea.check(inputLat, inputLng)
      if (response) {
        var locationDetails = await googleAPI.getLocationDetailsFromLatLng(
          inputLat,
          inputLng,
          sessionToken
        )
        if (locationDetails) {
          var formatedLocationDetails = googleResponseFormatting.placeDetails(
            locationDetails
          )
          if (formatedLocationDetails) {
            formatedLocationDetails['geometry'] = {
              coords: {
                lat: inputLat,
                lng: inputLng,
              },
            }
            // console.log(formatedLocationDetails)
            return {
              status: 'OK',
              type: 'GEOCODING',
              response: formatedLocationDetails,
            }
          }
        }
      } else {
        return {
          status: 'SERVICE_NOT_AVAILABLE',
          message:
            'Unfortunately, Saili is currently unavailable in this area.',
        }
      }
    } else {
      //If string is DMS
      if (
        searchKeyword.includes(`°`) &&
        (searchKeyword.includes(`'`) ||
          (searchKeyword.includes(`′`) &&
            (searchKeyword.includes(`"`) || searchKeyword.includes(`″`)) &&
            (searchKeyword.includes(`E`) ||
              searchKeyword.includes(`W`) ||
              searchKeyword.includes(`N`) ||
              searchKeyword.includes(`S`))))
      ) {
        //Convert to Latlong
        var convertedResponse = degreeConverter.dmsToDd(searchKeyword)
        if (convertedResponse) {
          var response = await serviceArea.check(
            convertedResponse.Latitude,
            convertedResponse.Longitude
          )
          if (response) {
            var locationDetails = await googleAPI.getLocationDetailsFromLatLng(
              convertedResponse.Latitude,
              convertedResponse.Longitude,
              sessionToken
            )
            if (locationDetails) {
              var formatedLocationDetails = googleResponseFormatting.placeDetails(
                locationDetails
              )
              if (formatedLocationDetails) {
                formatedLocationDetails['geometry'] = {
                  coords: {
                    lat: convertedResponse.Latitude,
                    lng: convertedResponse.Longitude,
                  },
                }
                return {
                  status: 'OK',
                  type: 'GEOCODING',
                  response: formatedLocationDetails,
                }
              }
            }
          } else {
            return {
              status: 'SERVICE_NOT_AVAILABLE',
              message:
                'Unfortunately, Saili is currently unavailable in this area.',
            }
          }
        }
      } else {
        var isPlusCode = sailiValidate.isValidPlusCode(searchKeyword)
        if (isPlusCode) {
          return {
            status: 'OK',
            type: 'PLUSCODE',
            message: '',
          }
        } else {
          // Search Keyword
          var result = await googleAPI.searchInsideValleyLocation(
            searchKeyword,
            sessionToken
          )
          if (result) {
            return {
              status: 'OK',
              type: 'AUTOCOMPLETE',
              response: result,
            }
          }
        }
      }
    }
  },
}
