import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AppContainer from '../../app-container'
import AppHeader from '../../app-header'
import AppSidebar from '../../app-sidebar'
import AppBody from '../../app-body'
import AppPageContainer from '../../app-page-container'
import Customers from '../../page-components/customers'
import {
  ProvideUserProfile,
  useUserProfile,
} from '../../../context/user/profile'

import { ProvideNewCustomer } from '../../../context/customer/new'

const Index = () => {
  useEffect(() => {
    document.title = 'Customers | Saili'
  }, [])

  return (
    <>
      <ProvideUserProfile>
        <AppContainer>
          <AppHeader
            get_estimate_visibility={false}
            delivery_slot_visibility={false}
            delivery_feature_visibility={false}
          />
          <AppBody>
            <ProvideNewCustomer>
              <AppSidebarController active='customers' />
              <AppPageContainer>
                <WelcomeCard />

                <Customers />

                <div className='pb-3'></div>
              </AppPageContainer>
            </ProvideNewCustomer>
          </AppBody>
        </AppContainer>
      </ProvideUserProfile>
    </>
  )
}

const AppSidebarController = (props) => {
  var user = useUserProfile()
  return (
    <>
      {user.userProfile ? (
        <AppSidebar
          active={props.active}
          create_delivery_visibility={
            user.userProfile.payment_options.length > 0 ? true : false
          }
        />
      ) : null}
    </>
  )
}

const WelcomeCard = () => {
  var user = useUserProfile()
  return (
    <>
      {user.userProfile ? (
        <>
          {user.userProfile.payment_options.length < 1 ? (
            <div className='welcome-card'>
              <div className='row'>
                <div className='col-sm-7'>
                  <h4>
                    Welcome, {user.userProfile.personal_details.full_name} !
                  </h4>
                  <p>
                    In order to continue deliverying orders, please add your
                    billing method in your profile settings.
                  </p>
                  <Link
                    to='/my-profile'
                    className='btn btn-sm pl-4 pr-4 bg-white btn-rounded'
                  >
                    Proceed
                  </Link>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  )
}

export default Index
