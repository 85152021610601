import { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { sailiFormatting } from '../../../functions/formatting'
import { riderAPI } from '../../../api/saili'

import { useAuth } from '../../../context/user/auth'

const Index = (props) => {
  const { visible, onHide } = props
  var auth = useAuth()

  // === Component States ===

  // Modal State
  const [disableInputs, setDisableInputs] = useState(false)

  // Rider Form State
  const [fullName, setFullName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [altContact, setAltContact] = useState('')
  const [password, setPassword] = useState('')
  const [errorResponse, setErrorResponse] = useState()

  // === Component Handler ===

  const handleRiderContact = (number) => {
    var formatted_mobile_number = sailiFormatting.altContactNumber(number)
    setMobileNumber(formatted_mobile_number.number)
  }

  const handleRiderAltContact = (number) => {
    var formatted_number = sailiFormatting.altContactNumber(number)
    setAltContact(formatted_number.number)
  }

  const handleOnCreateRider = async () => {
    var userInput = getInput()

    if (userInput) {
      switch (userInput.status) {
        case 'OK':
          setDisableInputs(true)
          // console.log(userInput.data)

          var createResponse = await riderAPI.create(userInput.data, auth.user)

          if (createResponse) {
            // console.log(createResponse)
            if (createResponse.status == 'SUCCESS') {
              setDisableInputs(false)
              onHide()
            } else {
              setErrorResponse(createResponse)
              setDisableInputs(false)
            }
          }
          break

        case 'ERROR':
          alert(userInput.message)
          break

        default:
          break
      }
    }
  }

  const getInput = () => {
    var userInput = {
      full_name: fullName,
      mobile_number: mobileNumber,
      alt_contact: altContact,
      password: password,
    }

    if (!userInput.full_name) {
      return {
        status: 'ERROR',
        message: 'Full name is missing.',
      }
    } else {
      if (!userInput.mobile_number || userInput.mobile_number.length < 10) {
        return {
          status: 'ERROR',
          message: 'Contact details are missing.',
        }
      } else {
        if (!userInput.password || userInput.password.length < 8) {
          return {
            status: 'ERROR',
            message: 'Password must be at lest 8 characters long.',
          }
        } else {
          return {
            status: 'OK',
            data: userInput,
          }
        }
      }
    }
  }

  return (
    <Modal
      size='xl'
      backdrop='static'
      keyboard={false}
      show={visible}
      onHide={() => onHide()}
      aria-labelledby='create-rider-title-xl'
      animation={false}
    >
      <Modal.Header closeButton={disableInputs ? false : true}>
        <Modal.Title id='create-rider-title-xl'>New Rider</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-body'>
        {errorResponse ? (
          <div className='alert alert-danger' role='alert'>
            <h4 className='alert-heading'>{errorResponse.title}</h4>
            <p>{errorResponse.message}</p>
          </div>
        ) : null}
        <div className='form-wrapper'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='form-group'>
                <label htmlFor='inputFullName'>Full Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  id='inputFullName'
                  maxLength='50'
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  readOnly={disableInputs}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor='inputMobileNumber'>Mobile Number</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  id='inputMobileNumber'
                  maxLength='10'
                  pattern='\d*'
                  value={mobileNumber}
                  onChange={(e) => handleRiderContact(e.target.value)}
                  readOnly={disableInputs}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor='inputAltContact'>
                  Alternative Contact Number
                  <small className='form-text text-muted'>(Optional)</small>
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  id='inputAltContact'
                  maxLength='10'
                  pattern='\d*'
                  value={altContact}
                  onChange={(e) => handleRiderAltContact(e.target.value)}
                  readOnly={disableInputs}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='inputPassword'>Create Password</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  minLength='8'
                  id='inputPassword'
                  name='inputPassword'
                  value={password}
                  readOnly={disableInputs}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <small className='form-text text-muted'>
                  The password should be at least 8 characters long. To make it
                  stronger use upper and lower case letters, numbers, and
                  symbols like ! " $ % ^.
                </small>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-primary btn-lg ml-3'
          onClick={() => handleOnCreateRider()}
          disabled={disableInputs}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default Index
