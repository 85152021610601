import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { css } from '@emotion/core'
import { BeatLoader } from 'react-spinners'

import { partnerAPI } from '../../../api/saili'

const Index = () => {
  const overrideSpinner = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `

  const { id } = useParams()

  const [verifying, setVerifying] = useState(false)
  const [verificationResponse, setVerificationResponse] = useState()

  useEffect(async () => {
    document.title = 'Email Verification | Saili'
    if (id) {
      setVerifying(true)
      var response = await partnerAPI.verifyEmail(id)
      if (response) {
        setVerificationResponse(response)
        setVerifying(false)
      }
    }
  }, [])

  return (
    <>
      <main className='slv-outer-wrapper bg-primary'>
        <div className='slv-container'>
          <section className='slv-inner-wrapper'>
            <div className='slv-header-wrapper'>
              <img
                src={`${process.env.PUBLIC_URL}/images/logo-white.svg`}
                alt='Saili'
                height='50'
              />
              <h3>Verification for Saili</h3>
            </div>

            <div className='slv-body-wrapper'>
              {verifying ? (
                <div className='slv-response-message-wrapper'>
                  <h5 className='mb-3'>Confirming Registration</h5>
                  <p>
                    Please wait, we are verifying your submissions for Saili Web
                    App.
                  </p>
                  <BeatLoader
                    color='#0098e3'
                    loading={true}
                    css={overrideSpinner}
                  />
                </div>
              ) : null}

              {verificationResponse ? (
                <div className='slv-response-message-wrapper'>
                  <h5 className='mb-3'>{verificationResponse.title}</h5>
                  <p>{verificationResponse.message}</p>
                </div>
              ) : null}
            </div>
          </section>
        </div>
      </main>
    </>
  )
}

export default Index
