import { createContext, useContext, useState } from 'react'

const newOrderContext = createContext()

export const ProvideNewOrder = ({ children }) => {
  const order = useProvideNewOrder()
  return (
    <newOrderContext.Provider value={order}>
      {children}
    </newOrderContext.Provider>
  )
}

export const useNewOrder = () => {
  return useContext(newOrderContext)
}

function useProvideNewOrder() {
  const [newOrder, setNewOrder] = useState(false)

  const checkNewOrder = () => {
    setNewOrder(true)
  }

  const resetCheckNewOrder = () => {
    setNewOrder(false)
  }

  return {
    newOrder,
    checkNewOrder,
    resetCheckNewOrder,
  }
}
