import React, { useState, useEffect } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import moment from 'moment'
import { css } from '@emotion/core'
import { BeatLoader } from 'react-spinners'

import CreateCustomerModal from '../../page-components/create-customers'

import EditCustomerModal from '../../../components/page-components/edit-customers'

import InfoBoxFull from '../../page-components/cards/info-box'

import { customerAPI } from '../../../api/saili'
import { useAuth } from '../../../context/user/auth'
import { useNewCustomer } from '../../../context/customer/new'

const momentTz = require('moment-timezone')

const Index = () => {
  var auth = useAuth()
  var newCustomer = useNewCustomer()
  // console.log('Has New Customer', newCustomer)

  const overrideSpinner = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `

  const [filterCustomers, setFilterCustomers] = useState([])
  const [customers, setCustomers] = useState([])
  const [disableInputs, setDisableInputs] = useState(false)
  const [processModalShow, setProcessModalShow] = useState(false)
  const [deletingCustomer, setDeletingCustomer] = useState(false)
  const [errorResponse, setErrorResponse] = useState()

  const [totalCustomersCount, setTotalCustomersCount] = useState()
  const [perPageCustomer, setPerPageCustomer] = useState()
  const [currentCustomersPage, setCurrrentCustomersPage] = useState()
  const [maxPageCount, setMaxPageCount] = useState()

  const [newModalShow, setNewModalShow] = useState(false)
  const [editModalShow, setEditModalShow] = useState(false)
  const [editModalProps, setEditModalProps] = useState()

  useEffect(async () => {
    await getSetCustomers(1)
  }, [processModalShow])

  useEffect(async () => {
    if (newCustomer.newCustomer) {
      await getSetCustomers(1)
      setTimeout(() => {
        newCustomer.resetCheckNewCustomer()
      }, 500)
    }
  }, [newCustomer])

  const handleOnCreateCustomerModalHide = () => {
    newCustomer.checkNewCustomer()
    setNewModalShow(false)
  }

  const handleOnEditModalHide = () => {
    newCustomer.checkNewCustomer()
    setEditModalShow(false)
  }

  const getSetCustomers = async (page) => {
    var customerResponse = await customerAPI.getPerPage(page, auth.user)
    if (customerResponse) {
      setFilterCustomers(customerResponse.data)
      setCustomers(customerResponse.data)

      setTotalCustomersCount(customerResponse.totalCustomers)
      setPerPageCustomer(customerResponse.perPage)
      setCurrrentCustomersPage(customerResponse.currentPage)
      setMaxPageCount(
        Math.ceil(customerResponse.totalCustomers / customerResponse.perPage)
      )
    }
  }

  const handleCustomerEdit = async (customerId) => {
    var customer = customers.filter((customer) => {
      return customer._id == customerId
    })
    // console.log('Edit', customer)
    if (customer.length > 0) {
      setEditModalProps(customer[0])
      setEditModalShow(true)
    }
  }

  const handleCustomerDelete = async (customerId) => {
    setDisableInputs(true)
    setProcessModalShow(true)
    setDeletingCustomer(true)

    var deleteResponse = await customerAPI.delete(customerId, auth.user)

    if (deleteResponse) {
      // console.log('Delete Response ', deleteResponse)
      if (deleteResponse.status == 'SUCCESS') {
        setDisableInputs(false)
        setProcessModalShow(false)
        setDeletingCustomer(false)
      } else {
        setDisableInputs(false)
        setDeletingCustomer(false)
        setErrorResponse(deleteResponse)
      }
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col-sm-12'>
          <article className='current-orders-list-wrapper'>
            <header className='current-orders-list-header'>
              <div
                className='btn btn-primary'
                onClick={() => setNewModalShow(true)}
              >
                New Customer
              </div>
              {newModalShow ? (
                <CreateCustomerModal
                  visible={newModalShow}
                  onHide={handleOnCreateCustomerModalHide}
                />
              ) : null}
            </header>
            <section className='current-orders-list-body'>
              {filterCustomers ? (
                <>
                  {filterCustomers.length > 0 ? (
                    <>
                      <div className='orders-header'>
                        <div className='row no-gutters'>
                          <div className='col-sm-2'>
                            <div className='orders-column-header'>Name</div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='orders-column-header'>
                              Contact Number
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='orders-column-header'>Address</div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='orders-column-header'>Date</div>
                          </div>
                          <div className='col-sm-1'>
                            <div className='orders-column-header'></div>
                          </div>
                        </div>
                      </div>
                      <div className='orders-body'>
                        {filterCustomers.map((customer, index) => {
                          let firstItem = index == 0 ? 'order-item-first' : ''
                          return (
                            <div
                              className={`order-item ${firstItem}`}
                              key={customer._id}
                            >
                              <div className='row no-gutters'>
                                <div className='col-md-2'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        <strong>
                                          {customer.business_name}
                                        </strong>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className='col-sm-3'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        {customer.contact_number}
                                        {customer.alt_contact
                                          ? ', ' + customer.alt_contact
                                          : ''}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className='col-sm-3'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        {customer.address.formatted_address}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className='col-sm-3'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        {moment(
                                          customer.date.creation
                                        ).calendar()}
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div className='col-sm-1'>
                                  <div className='action-group'>
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant='outline-dark btn-sm'
                                        id='dropdown-basic'
                                      >
                                        <span className='material-icons'>
                                          menu
                                        </span>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleCustomerEdit(customer._id)
                                          }
                                        >
                                          Edit Customer
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleCustomerDelete(customer._id)
                                          }
                                        >
                                          Delete Customer
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  ) : (
                    <InfoBoxFull
                      icon='people'
                      title='No Customers'
                      description="You don't have any customers."
                    />
                  )}
                </>
              ) : null}
            </section>
            {maxPageCount ? (
              <div className='pagination-wrapper'>
                <nav>
                  <ul className='pagination justify-content-center'>
                    {currentCustomersPage ? (
                      <>
                        {currentCustomersPage == 1 ? (
                          <>
                            <li className='page-item disabled'>
                              <a className='page-link' href='#page-1'>
                                First
                              </a>
                            </li>
                            <li className='page-item disabled'>
                              <a className='page-link' href='#page-prev'>
                                Previous
                              </a>
                            </li>
                          </>
                        ) : (
                          <>
                            <li className='page-item'>
                              <a
                                className='page-link'
                                href='#page-1'
                                onClick={() => getSetCustomers(1)}
                              >
                                First
                              </a>
                            </li>
                            <li className='page-item'>
                              <a
                                className='page-link'
                                href='#page-prev'
                                onClick={() =>
                                  getSetCustomers(currentCustomersPage - 1)
                                }
                              >
                                Previous
                              </a>
                            </li>
                          </>
                        )}

                        {currentCustomersPage > 2 ? (
                          <li className='page-item'>
                            <span className='page-link'>...</span>
                          </li>
                        ) : null}
                        <li className='page-item active'>
                          <a
                            className='page-link'
                            href={'#page-' + currentCustomersPage}
                          >
                            {currentCustomersPage}
                          </a>
                        </li>
                        {currentCustomersPage != maxPageCount ? (
                          <li className='page-item'>
                            <a
                              className='page-link'
                              href={'#page-' + (currentCustomersPage + 1)}
                              onClick={() =>
                                getSetCustomers(currentCustomersPage + 1)
                              }
                            >
                              {currentCustomersPage + 1}
                            </a>
                          </li>
                        ) : null}
                        {maxPageCount > currentCustomersPage + 1 ? (
                          <li className='page-item'>
                            <span className='page-link'>...</span>
                          </li>
                        ) : null}

                        {currentCustomersPage == maxPageCount ? (
                          <>
                            <li className='page-item disabled'>
                              <a className='page-link' href='#page-next'>
                                Next
                              </a>
                            </li>
                            <li className='page-item disabled'>
                              <a
                                className='page-link'
                                href={'#page-' + maxPageCount}
                              >
                                Last
                              </a>
                            </li>
                          </>
                        ) : (
                          <>
                            <li className='page-item'>
                              <a
                                className='page-link'
                                href='#page-next'
                                onClick={() =>
                                  getSetCustomers(currentCustomersPage + 1)
                                }
                              >
                                Next
                              </a>
                            </li>
                            <li className='page-item'>
                              <a
                                className='page-link'
                                href={'#page-' + maxPageCount}
                                onClick={() => getSetCustomers(maxPageCount)}
                              >
                                Last
                              </a>
                            </li>
                          </>
                        )}
                      </>
                    ) : null}
                  </ul>
                </nav>
              </div>
            ) : null}
          </article>
        </div>
      </div>
      <Modal
        size='xl'
        backdrop='static'
        keyboard={false}
        show={processModalShow}
        onHide={() => setProcessModalShow(false)}
        aria-labelledby='action-customer-title-xl'
        animation={false}
      >
        <Modal.Header closeButton={disableInputs ? false : true}>
          <Modal.Title id='action-customer-title-xl'>
            {deletingCustomer ? 'Deleting Customer' : ''}
            {errorResponse ? 'Error' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-body'>
          {deletingCustomer ? (
            <div className='dc-processing-indicator text-center'>
              <h6>Deleting</h6>
              <p>Please wait, your request is being processed.</p>
              <BeatLoader
                color='#0098e3'
                loading={true}
                css={overrideSpinner}
              />
            </div>
          ) : null}
          {errorResponse ? (
            <div className='alert alert-danger' role='alert'>
              <h4 className='alert-heading'>{errorResponse.title}</h4>
              <p>{errorResponse.message}</p>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>

      {editModalShow ? (
        <EditCustomerModal
          customerInfo={editModalProps}
          visible={editModalShow}
          onHide={handleOnEditModalHide}
        />
      ) : null}
    </>
  )
}

export default Index
