import React, { useState, useEffect } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import moment from 'moment'
import { css } from '@emotion/core'
import { BeatLoader } from 'react-spinners'

import CreateRouteModal from '../../page-components/create-routes'

import ViewRouteModal from '../../page-components/view-routes'

import AssignRiderRouteModal from '../../page-components/assign-riders'

import InfoBoxFull from '../../page-components/cards/info-box'

import { routeAPI } from '../../../api/saili'
import { useAuth } from '../../../context/user/auth'
import { useNewRoute } from '../../../context/route/new'

import { timeFormatting } from '../../../functions/formatting'

const momentTz = require('moment-timezone')

const Index = () => {
  var auth = useAuth()
  var newRoute = useNewRoute()
  // console.log('Has New Route', newRoute)

  const overrideSpinner = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `

  const [filterRoutes, setFilterRoutes] = useState([])
  const [routes, setRoutes] = useState([])
  const [disableInputs, setDisableInputs] = useState(false)
  const [processModalShow, setProcessModalShow] = useState(false)
  const [deletingRoute, setDeletingRoute] = useState(false)
  const [errorResponse, setErrorResponse] = useState()

  const [totalRoutesCount, setTotalRoutesCount] = useState()
  const [perPageRoute, setPerPageRoute] = useState()
  const [currentRoutesPage, setCurrrentRoutesPage] = useState()
  const [maxPageCount, setMaxPageCount] = useState()

  const [newModalShow, setNewModalShow] = useState(false)
  const [viewModalShow, setViewModalShow] = useState(false)
  const [assignModalShow, setAssignModalShow] = useState(false)
  const [viewModalProps, setViewModalProps] = useState()

  useEffect(async () => {
    await getSetRoutes(1)
  }, [processModalShow])

  useEffect(async () => {
    if (newRoute.newRoute) {
      await getSetRoutes(1)
      setTimeout(() => {
        newRoute.resetCheckNewRoute()
      }, 500)
    }
  }, [newRoute])

  const handleOnCreateRouteModalHide = () => {
    newRoute.checkNewRoute()
    setNewModalShow(false)
  }

  const handleOnViewModalHide = () => {
    setViewModalShow(false)
  }

  const handleOnAssignRouteModalHide = () => {
    newRoute.checkNewRoute()
    setAssignModalShow(false)
  }

  const getSetRoutes = async (page) => {
    var routeResponse = await routeAPI.getPerPage(page, auth.user)
    if (routeResponse) {
      setFilterRoutes(routeResponse.data)
      setRoutes(routeResponse.data)

      setTotalRoutesCount(routeResponse.totalRoutes)
      setPerPageRoute(routeResponse.perPage)
      setCurrrentRoutesPage(routeResponse.currentPage)
      setMaxPageCount(
        Math.ceil(routeResponse.totalRoutes / routeResponse.perPage)
      )
    }
  }

  const handleRouteView = async (routeId) => {
    // console.log('View Route', routeId)
    var route = routes.filter((route) => {
      return route._id == routeId
    })
    if (route.length > 0) {
      setViewModalProps(route[0])
      setViewModalShow(true)
    }
  }

  const handleRouteAssign = async (routeId) => {
    // console.log('Assign Route', routeId)
    var route = routes.filter((route) => {
      return route._id == routeId
    })
    if (route.length > 0) {
      setViewModalProps(route[0])
      setAssignModalShow(true)
    }
  }

  const handleRouteDelete = async (routeId) => {
    setDisableInputs(true)
    setProcessModalShow(true)
    setDeletingRoute(true)

    var deleteResponse = await routeAPI.delete(routeId, auth.user)

    if (deleteResponse) {
      // console.log('Delete Response ', deleteResponse)
      if (deleteResponse.status == 'SUCCESS') {
        setDisableInputs(false)
        setProcessModalShow(false)
        setDeletingRoute(false)
      } else {
        setDisableInputs(false)
        setDeletingRoute(false)
        setErrorResponse(deleteResponse)
      }
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col-sm-12'>
          <article className='current-orders-list-wrapper'>
            <header className='current-orders-list-header'>
              <div
                className='btn btn-primary'
                onClick={() => setNewModalShow(true)}
              >
                Generate Routes
              </div>
              {newModalShow ? (
                <CreateRouteModal
                  visible={newModalShow}
                  onHide={handleOnCreateRouteModalHide}
                />
              ) : null}
            </header>
            <section className='current-orders-list-body'>
              {filterRoutes ? (
                <>
                  {filterRoutes.length > 0 ? (
                    <>
                      <div className='orders-header'>
                        <div className='row no-gutters'>
                          <div className='col-sm-2'>
                            <div className='orders-column-header'>
                              Trip Start/End
                            </div>
                          </div>
                          <div className='col-sm-2'>
                            <div className='orders-column-header'>
                              Total Orders
                            </div>
                          </div>
                          <div className='col-sm-2'>
                            <div className='orders-column-header'>
                              Total Routes
                            </div>
                          </div>
                          {/* <div className='col-sm-2'>
                            <div className='orders-column-header'>
                              Time Cost
                            </div>
                          </div>
                          */}
                          <div className='col-sm-2'>
                            <div className='orders-column-header'>
                              Distance Cost
                            </div>
                          </div>

                          <div className='col-sm-2'>
                            <div className='orders-column-header'>Status</div>
                          </div>
                          <div className='col-sm-2'>
                            <div className='orders-column-header'></div>
                          </div>
                        </div>
                      </div>
                      <div className='orders-body'>
                        {filterRoutes.map((route, index) => {
                          let firstItem = index == 0 ? 'order-item-first' : ''
                          return (
                            <div
                              className={`order-item ${firstItem}`}
                              key={route._id}
                            >
                              <div className='row no-gutters'>
                                <div className='col-md-2'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        {moment(
                                          Number(route.start_time)
                                        ).format('Do MMM YYYY')}
                                        <br />
                                        <strong>
                                          {moment(
                                            Number(route.start_time)
                                          ).format('hh:mm A')}
                                        </strong>
                                        {' - '}
                                        <strong>
                                          {moment(
                                            Number(route.end_time)
                                          ).format('hh:mm A')}
                                        </strong>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className='col-sm-2'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>{route.total_orders}</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className='col-sm-2'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>{route.solution.length}</li>
                                    </ul>
                                  </div>
                                </div>
                                {/* <div className='col-sm-2'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        {timeFormatting.secondsToHrMin(
                                          route.total_cost
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                </div> */}

                                <div className='col-sm-2'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>{route.total_cost / 1000}</li>
                                    </ul>
                                  </div>
                                </div>

                                <div className='col-sm-2'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>{route.status}</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className='col-sm-2'>
                                  <div className='action-group'>
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant='outline-dark btn-sm'
                                        id='dropdown-basic'
                                      >
                                        <span className='material-icons'>
                                          menu
                                        </span>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleRouteView(route._id)
                                          }
                                        >
                                          View Route
                                        </Dropdown.Item>
                                        {route.status == 'PENDING' ||
                                        route.status == 'RIDER_ASSIGNED' ? (
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleRouteAssign(route._id)
                                            }
                                          >
                                            Assign Rider
                                          </Dropdown.Item>
                                        ) : null}
                                        {route.status == 'PENDING' ||
                                        route.status == 'RIDER_ASSIGNED' ? (
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleRouteDelete(route._id)
                                            }
                                          >
                                            Delete Route
                                          </Dropdown.Item>
                                        ) : null}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  ) : (
                    <InfoBoxFull
                      icon='alt_route'
                      title='No Routes'
                      description="You don't have any routes."
                    />
                  )}
                </>
              ) : null}
            </section>
            {maxPageCount ? (
              <div className='pagination-wrapper'>
                <nav>
                  <ul className='pagination justify-content-center'>
                    {currentRoutesPage ? (
                      <>
                        {currentRoutesPage == 1 ? (
                          <>
                            <li className='page-item disabled'>
                              <a className='page-link' href='#page-1'>
                                First
                              </a>
                            </li>
                            <li className='page-item disabled'>
                              <a className='page-link' href='#page-prev'>
                                Previous
                              </a>
                            </li>
                          </>
                        ) : (
                          <>
                            <li className='page-item'>
                              <a
                                className='page-link'
                                href='#page-1'
                                onClick={() => getSetRoutes(1)}
                              >
                                First
                              </a>
                            </li>
                            <li className='page-item'>
                              <a
                                className='page-link'
                                href='#page-prev'
                                onClick={() =>
                                  getSetRoutes(currentRoutesPage - 1)
                                }
                              >
                                Previous
                              </a>
                            </li>
                          </>
                        )}

                        {currentRoutesPage > 2 ? (
                          <li className='page-item'>
                            <span className='page-link'>...</span>
                          </li>
                        ) : null}
                        <li className='page-item active'>
                          <a
                            className='page-link'
                            href={'#page-' + currentRoutesPage}
                          >
                            {currentRoutesPage}
                          </a>
                        </li>
                        {currentRoutesPage != maxPageCount ? (
                          <li className='page-item'>
                            <a
                              className='page-link'
                              href={'#page-' + (currentRoutesPage + 1)}
                              onClick={() =>
                                getSetRoutes(currentRoutesPage + 1)
                              }
                            >
                              {currentRoutesPage + 1}
                            </a>
                          </li>
                        ) : null}
                        {maxPageCount > currentRoutesPage + 1 ? (
                          <li className='page-item'>
                            <span className='page-link'>...</span>
                          </li>
                        ) : null}

                        {currentRoutesPage == maxPageCount ? (
                          <>
                            <li className='page-item disabled'>
                              <a className='page-link' href='#page-next'>
                                Next
                              </a>
                            </li>
                            <li className='page-item disabled'>
                              <a
                                className='page-link'
                                href={'#page-' + maxPageCount}
                              >
                                Last
                              </a>
                            </li>
                          </>
                        ) : (
                          <>
                            <li className='page-item'>
                              <a
                                className='page-link'
                                href='#page-next'
                                onClick={() =>
                                  getSetRoutes(currentRoutesPage + 1)
                                }
                              >
                                Next
                              </a>
                            </li>
                            <li className='page-item'>
                              <a
                                className='page-link'
                                href={'#page-' + maxPageCount}
                                onClick={() => getSetRoutes(maxPageCount)}
                              >
                                Last
                              </a>
                            </li>
                          </>
                        )}
                      </>
                    ) : null}
                  </ul>
                </nav>
              </div>
            ) : null}
          </article>
        </div>
      </div>
      <Modal
        size='xl'
        backdrop='static'
        keyboard={false}
        show={processModalShow}
        onHide={() => setProcessModalShow(false)}
        aria-labelledby='action-route-title-xl'
        animation={false}
      >
        <Modal.Header closeButton={disableInputs ? false : true}>
          <Modal.Title id='action-route-title-xl'>
            {deletingRoute ? 'Deleting Route' : ''}
            {errorResponse ? 'Error' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-body'>
          {deletingRoute ? (
            <div className='dc-processing-indicator text-center'>
              <h6>Deleting</h6>
              <p>Please wait, your request is being processed.</p>
              <BeatLoader
                color='#0098e3'
                loading={true}
                css={overrideSpinner}
              />
            </div>
          ) : null}
          {errorResponse ? (
            <div className='alert alert-danger' role='alert'>
              <h4 className='alert-heading'>{errorResponse.title}</h4>
              <p>{errorResponse.message}</p>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
      {viewModalShow ? (
        <ViewRouteModal
          routeInfo={viewModalProps}
          visible={viewModalShow}
          onHide={handleOnViewModalHide}
        />
      ) : null}

      {assignModalShow ? (
        <AssignRiderRouteModal
          routeInfo={viewModalProps}
          visible={assignModalShow}
          onHide={handleOnAssignRouteModalHide}
        />
      ) : null}
    </>
  )
}

export default Index
