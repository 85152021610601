import { createContext, useContext, useState } from 'react'
import { userTokens } from '../../../functions/local-storage'

const userAuth = {
  isAuthenticated: false,
  signin(cb) {
    userAuth.isAuthenticated = true
    setTimeout(cb, 100)
  },
  signout(cb) {
    userAuth.isAuthenticated = false
    setTimeout(cb, 100)
  },
}

const authContext = createContext()

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

function useProvideAuth() {
  var token = null
  // userTokens.remove()
  const tokenResponse = userTokens.get()

  if (tokenResponse) {
    token = tokenResponse
  }
  const [user, setUser] = useState(token)

  const signin = (cb) => {
    return userAuth.signin(() => {
      let token = userTokens.get()
      setUser(token)
      cb()
    })
  }

  const signout = (cb) => {
    return userAuth.signout(() => {
      userTokens.remove()
      setUser(null)
      cb()
    })
  }

  return {
    user,
    signin,
    signout,
  }
}
