import { createContext, useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../auth'
import { partnerAPI } from '../../../api/saili'

const userProfileContext = createContext()

export const ProvideUserProfile = ({ children }) => {
  const profile = useProvideUserProfile()
  return (
    <userProfileContext.Provider value={profile}>
      {children}
    </userProfileContext.Provider>
  )
}

export const useUserProfile = () => {
  return useContext(userProfileContext)
}

function useProvideUserProfile() {
  var history = useHistory()
  var auth = useAuth()
  var token = auth.user
  var profile = null

  const [userProfile, setUserProfile] = useState(profile)

  useEffect(async () => {
    if (token) {
      var response = await partnerAPI.getProfile(token)
      if (response.status == 'SUCCESS') {
        if (response.data.account.status == 'ACTIVE') {
          setUserProfile(response.data)
        }
      } else {
        auth.signout(() => {
          history.push('/login')
        })
      }
    }
  }, [])

  return {
    userProfile,
  }
}
