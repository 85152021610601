import { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { browserName, osName } from 'react-device-detect'
import { userTokens } from '../../../functions/local-storage'

import { partnerAPI } from '../../../api/saili'
import { useAuth } from '../../../context/user/auth'

import validator from 'validator'

const Index = () => {
  var history = useHistory()
  var auth = useAuth()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [disableInputs, setDisableInputs] = useState(false)
  const [loginError, setLoginError] = useState()

  useEffect(() => {
    document.title = 'Login | Saili'
    if (auth.user) {
      history.push('/orders')
    }
  }, [])

  const handleLoginSubmit = async (e) => {
    e.preventDefault()
    var loginDetails = getUserInputs()
    if (loginDetails) {
      switch (loginDetails.status) {
        case 'OK':
          // console.log(loginDetails)
          setDisableInputs(true)
          var response = await partnerAPI.login(loginDetails.data)
          if (response) {
            if (response.status == 'SUCCESS') {
              let token = response.data.token
              userTokens.set(token)
              auth.signin(() => {
                history.push('/orders')
              })
            } else {
              setLoginError(response)
              setDisableInputs(false)
            }
          }
          break

        case 'ERROR':
          alert(loginDetails.message)
          break

        default:
          break
      }
    }
  }

  const getUserInputs = () => {
    var userInput = {
      email: email,
      password: password,
      browser_name: browserName,
      device_os: osName,
    }

    if (!validator.isEmail(userInput.email)) {
      return {
        status: 'ERROR',
        message: 'Please enter a valid email address.',
      }
    } else {
      if (!userInput.password || userInput.password.length < 8) {
        return {
          status: 'ERROR',
          message: 'Please enter a valid password.',
        }
      } else {
        if (!userInput.browser_name) {
          userInput.browser_name = 'Unknown'
        } else {
          if (!userInput.device_os) {
            userInput.device_os = 'Unknown'
          } else {
            return {
              status: 'OK',
              data: userInput,
            }
          }
        }
      }
    }
  }

  return (
    <main className='login-outer-wrapper bg-primary'>
      <div className='login-container'>
        <section className='login-inner-wrapper'>
          <div className='login-header-wrapper'>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo-white.svg`}
              alt='Saili'
              height='50'
            />
            <h3>Login to Saili Partner</h3>
          </div>

          <div className='login-body-wrapper'>
            <div className='login-form-wrapper'>
              {loginError ? (
                <div className='alert alert-danger' role='alert'>
                  <h6>{loginError.title}</h6>
                  <p>{loginError.message}</p>
                </div>
              ) : null}

              <div className='form-group'>
                <label htmlFor='inputEmail'>Email</label>
                <input
                  type='email'
                  className='form-control form-control-lg'
                  value={email}
                  id='inputEmail'
                  name='inputEmail'
                  readOnly={disableInputs ? true : false}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='inputPassword'>Password</label>
                <input
                  type='password'
                  className='form-control form-control-lg'
                  id='inputPassword'
                  name='inputPassword'
                  value={password}
                  readOnly={disableInputs ? true : false}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                type='button'
                className='btn btn-lg btn-primary btn-block'
                onClick={(e) => handleLoginSubmit(e)}
                disabled={disableInputs ? true : false}
              >
                Login
              </button>

              <div className='login-links-wrapper mt-3'>
                <p>
                  Need an account? <Link to='/register'>Register Here</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  )
}

export default Index
