import { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import {
  googleAPI,
  mapSettings,
  generateSessionToken,
} from '../../../api/google'

import { googleHandler } from '../../../functions/handler'
import {
  sailiFormatting,
  googleResponseFormatting,
} from '../../../functions/formatting'
import { customerAPI } from '../../../api/saili'

import { useAuth } from '../../../context/user/auth'
import { useUserProfile } from '../../../context/user/profile'

import markerbg from '../../../assets/images/ic_loc_white_blue.png'

const myIcon = L.icon({
  iconUrl: markerbg,
  iconSize: [51, 83],
  iconAnchor: [25.5, 78],
  popupAnchor: [0, -78],
})

const Index = (props) => {
  const { visible, onHide, customerInfo } = props
  var myProfile = useUserProfile()
  var auth = useAuth()

  // === Component States ===

  // Modal State
  const [sessionToken, setSessionToken] = useState(generateSessionToken)
  const [disableInputs, setDisableInputs] = useState(false)

  //Existing Customer Info
  const [customerInfoData, setCustomerInfoData] = useState()

  // Customer Form State
  const [customerBusinessName, setCustomerBusinessName] = useState('')
  const [customerContact, setCustomerContact] = useState('')
  const [customerAltContact, setCustomerAltContact] = useState('')
  const [businessAddress, setBusinessAddress] = useState()

  const [searchKeywords, setSearchKeywords] = useState('')
  const [searchPredictions, setSearchPredictions] = useState([])
  const [searchCoordsDetails, setSearchCoordsDetails] = useState()
  const [mapRef, setMapRef] = useState()
  const markerRef = useRef()

  const [updatingError, setUpdatingError] = useState()

  // ======
  useEffect(async () => {
    if (customerInfo) {
      // console.log('Customer Info', customerInfo)

      setCustomerInfoData(customerInfo)

      // Customer Form State
      setCustomerBusinessName(customerInfo.business_name)
      setCustomerContact(customerInfo.contact_number)
      setCustomerAltContact(() => {
        return customerInfo.alt_contact ? customerInfo.alt_contact : ''
      })
      setBusinessAddress(() => {
        return {
          full_address: customerInfo.address.formatted_address,
          geometry: {
            coords: {
              lat: customerInfo.address.geometry.coordinates[1],
              lng: customerInfo.address.geometry.coordinates[0],
            },
          },
        }
      })
    }
  }, [customerInfo])

  // === Component Handler ===

  const handleCustomerContact = (number) => {
    var formatted_mobile_number = sailiFormatting.altContactNumber(number)
    setCustomerContact(formatted_mobile_number.number)
  }

  const handleCustomerAltContact = (number) => {
    var formatted_number = sailiFormatting.altContactNumber(number)
    setCustomerAltContact(formatted_number.number)
  }

  const handleSearch = async (keyword) => {
    var searchResponse = await googleHandler.search(keyword, sessionToken)
    if (searchResponse) {
      if (searchResponse.status == 'OK') {
        if (searchResponse.type == 'GEOCODING') {
          setSearchCoordsDetails(searchResponse.response)
        } else if (searchResponse.type == 'AUTOCOMPLETE') {
          setSearchPredictions(searchResponse.response)
        } else if (searchResponse.type == 'PLUSCODE') {
        }
      } else if (searchResponse.status == 'SERVICE_NOT_AVAILABLE') {
        alert(searchResponse.message)
      }
    }
  }

  const handleSearchKeywordChange = (keyword) => {
    setSearchKeywords(keyword)
    setSearchCoordsDetails()
    setSearchPredictions([])

    if (keyword.length > 0) {
      handleSearch(keyword)
    }
  }

  const handleSelectLocation = async (selectedLocation) => {
    if (selectedLocation) {
      switch (selectedLocation.from) {
        case 'GEOCODING':
          setBusinessAddress(selectedLocation.location_details)
          mapRef.flyTo(
            [
              selectedLocation.location_details.geometry.coords.lat,
              selectedLocation.location_details.geometry.coords.lng,
            ],
            mapSettings.pointZoom
          )
          const marker = markerRef.current
          if (marker != null) {
            setTimeout(() => {
              marker.openPopup()
            }, 300)
          }
          break
        case 'AUTOCOMPLETE':
          var selectedPlaceCoordsResponse = await googleAPI.getLatLngFromPlaceId(
            selectedLocation.location_details.place_id
          )

          if (selectedPlaceCoordsResponse) {
            var locationDetails = await googleAPI.getLocationDetailsFromLatLng(
              selectedPlaceCoordsResponse.result.geometry.location.lat,
              selectedPlaceCoordsResponse.result.geometry.location.lng,
              sessionToken
            )
            if (locationDetails) {
              var formatedLocationDetails = googleResponseFormatting.placeDetails(
                locationDetails
              )

              if (formatedLocationDetails) {
                formatedLocationDetails['geometry'] = {
                  coords: {
                    lat:
                      selectedPlaceCoordsResponse.result.geometry.location.lat,
                    lng:
                      selectedPlaceCoordsResponse.result.geometry.location.lng,
                  },
                }
                var reArrangedLocationDetails = googleResponseFormatting.reArrangePlaceDetails(
                  formatedLocationDetails,
                  selectedLocation.location_details
                )
                setBusinessAddress(reArrangedLocationDetails)
                mapRef.flyTo(
                  [
                    selectedPlaceCoordsResponse.result.geometry.location.lat,
                    selectedPlaceCoordsResponse.result.geometry.location.lng,
                  ],
                  mapSettings.pointZoom
                )
                const marker = markerRef.current
                // console.log(marker)
                if (marker != null) {
                  setTimeout(() => {
                    marker.openPopup()
                  }, 300)
                }
                setSearchKeywords('')
                setSearchPredictions([])
              }
            }
          }
          break
        default:
          break
      }
    }
  }

  //Form Button Handler

  const handleOnApplyUpdates = async (customerId) => {
    var userInput = getInput()

    if (userInput) {
      switch (userInput.status) {
        case 'OK':
          setDisableInputs(true)

          // Look For Changes
          var changesResponse = lookForChanges(userInput.data)

          // console.log('Updated Values', changesResponse)

          var editResponse = await customerAPI.edit(
            customerId,
            changesResponse,
            auth.user
          )

          if (editResponse) {
            // console.log(editResponse)
            if (editResponse.status == 'SUCCESS') {
              setUpdatingError()
              setDisableInputs(false)
              onHide()
            } else {
              setDisableInputs(false)
              setUpdatingError(editResponse)
            }
          }
          break

        case 'ERROR':
          alert(userInput.message)
          break

        default:
          break
      }
    }
  }

  const lookForChanges = (newValues) => {
    // console.log('Old', customerInfo)
    // console.log('New', newValues)
    if (customerInfo.business_name == newValues.business_name) {
      newValues.business_name = undefined
    }

    if (
      customerInfo.address.geometry.coordinates[0] ==
        newValues.business_latlng.coords.lng &&
      customerInfo.address.geometry.coordinates[1] ==
        newValues.business_latlng.coords.lat
    ) {
      newValues.business_address = undefined
      newValues.business_latlng = undefined
    }

    if (customerInfo.contact_number == newValues.contact_number) {
      newValues.contact_number = undefined
    }

    if (customerInfo.alt_contact == newValues.alt_contact) {
      newValues.alt_contact = undefined
    }

    newValues = JSON.parse(JSON.stringify(newValues))

    return newValues
  }

  const getInput = () => {
    var userInput = {
      business_name: customerBusinessName,
      business_address: businessAddress ? businessAddress.full_address : null,
      business_latlng: businessAddress ? businessAddress.geometry : null,
      contact_number: customerContact,
      alt_contact: customerAltContact ? customerAltContact : '',
    }

    if (!userInput.business_name) {
      return {
        status: 'ERROR',
        message: 'Business name is missing.',
      }
    } else {
      if (!userInput.contact_number || userInput.contact_number.length < 9) {
        return {
          status: 'ERROR',
          message: 'Business contact details are missing.',
        }
      } else {
        if (!userInput.business_address || !userInput.business_latlng) {
          return {
            status: 'ERROR',
            message: 'Business address details are missing.',
          }
        } else {
          return {
            status: 'OK',
            data: userInput,
          }
        }
      }
    }
  }

  return (
    <Modal
      size='xl'
      backdrop='static'
      keyboard={false}
      show={visible}
      onHide={() => onHide()}
      aria-labelledby='edit-customer-title-xl'
      animation={false}
    >
      <Modal.Header closeButton={disableInputs ? false : true}>
        <Modal.Title id='edit-customer-title-xl'>Edit Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-body'>
        {updatingError ? (
          <div className='alert alert-danger' role='alert'>
            <h4 className='alert-heading'>{updatingError.title}</h4>
            <p>{updatingError.message}</p>
          </div>
        ) : null}
        <div className='form-wrapper'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='form-group'>
                <label htmlFor='inputBusinessName'>Business Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  id='inputBusinessName'
                  maxLength='50'
                  value={customerBusinessName}
                  onChange={(e) => setCustomerBusinessName(e.target.value)}
                  readOnly={disableInputs}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor='inputBusinessContact'>Contact Number</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  id='inputBusinessContact'
                  maxLength='10'
                  pattern='\d*'
                  value={customerContact}
                  onChange={(e) => handleCustomerContact(e.target.value)}
                  readOnly={disableInputs}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor='inputBusinessAltContact'>
                  Alternative Contact Number
                  <small className='form-text text-muted'>(Optional)</small>
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  id='inputBusinessAltContact'
                  maxLength='10'
                  pattern='\d*'
                  value={customerAltContact}
                  onChange={(e) => handleCustomerAltContact(e.target.value)}
                  readOnly={disableInputs}
                />
              </div>
            </div>
            <div className='col-sm-12'>
              {/* <h6 className='mb-3 mt-4'>Business Address</h6> */}
              <label>Business Address</label>

              <div className='delivery-address-map-wrapper'>
                <div className='search-address-input-wrapper'>
                  <div className='search-address-input'>
                    <input
                      value={searchKeywords}
                      type='text'
                      className='form-control form-control-lg'
                      placeholder='Search'
                      onChange={(e) =>
                        handleSearchKeywordChange(e.target.value)
                      }
                      readOnly={disableInputs}
                    />
                  </div>
                  <div
                    className={
                      searchCoordsDetails || searchPredictions
                        ? 'search-address-prediction-wrapper'
                        : 'search-address-prediction-wrapper d-none'
                    }
                  >
                    {searchCoordsDetails ? (
                      <ul className='prediction-list'>
                        <li
                          onClick={() =>
                            handleSelectLocation({
                              from: 'GEOCODING',
                              location_details: searchCoordsDetails,
                            })
                          }
                        >
                          <span className='material-icons'>location_on</span>
                          <h6>{searchCoordsDetails.main_text}</h6>
                          <p>{searchCoordsDetails.secondary_text}</p>
                        </li>
                      </ul>
                    ) : null}

                    {searchPredictions ? (
                      <ul className='prediction-list'>
                        {searchPredictions.map((place) => {
                          return (
                            <li
                              key={place.place_id}
                              onClick={() =>
                                handleSelectLocation({
                                  from: 'AUTOCOMPLETE',
                                  location_details: place,
                                })
                              }
                            >
                              <span className='material-icons'>
                                location_on
                              </span>
                              <h6>{place.structured_formatting.main_text}</h6>
                              <p>
                                {place.structured_formatting.secondary_text}
                              </p>
                            </li>
                          )
                        })}
                      </ul>
                    ) : null}
                  </div>
                </div>
                <MapContainer
                  center={mapSettings.insideValley.mapCenter}
                  zoom={mapSettings.zoom}
                  scrollWheelZoom={true}
                  zoomControl={false}
                  whenCreated={(map) => setMapRef(map)}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    maxZoom={19}
                  />
                  {businessAddress ? (
                    <>
                      <Marker
                        position={[
                          businessAddress.geometry.coords.lat,
                          businessAddress.geometry.coords.lng,
                        ]}
                        icon={myIcon}
                        ref={markerRef}
                      >
                        <Popup>
                          {businessAddress.main_text ? (
                            <>
                              <strong>{businessAddress.main_text}</strong>
                              <br />
                              {businessAddress.secondary_text}
                            </>
                          ) : (
                            <>
                              <strong>{businessAddress.full_address}</strong>
                            </>
                          )}
                        </Popup>
                      </Marker>
                    </>
                  ) : null}
                </MapContainer>
                {businessAddress ? (
                  <div className='recipient-address-wrapper'>
                    <div className='recipient-address-inner'>
                      <span className='material-icons'>location_on</span>
                      {businessAddress.main_text ? (
                        <>
                          <h5>{businessAddress.main_text}</h5>
                          <p>{businessAddress.secondary_text}</p>
                        </>
                      ) : (
                        <>
                          <h5>{businessAddress.full_address}</h5>
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-primary btn-lg ml-3'
          onClick={() => handleOnApplyUpdates(customerInfo._id)}
          disabled={disableInputs}
        >
          Apply Changes
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default Index
