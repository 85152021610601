import React, { useState, useEffect } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import moment from 'moment'
import { css } from '@emotion/core'
import { BeatLoader } from 'react-spinners'

import CreateRiderModal from '../../page-components/create-riders'

import EditRiderModal from '../../../components/page-components/edit-riders'

import InfoBoxFull from '../../page-components/cards/info-box'

import { riderAPI } from '../../../api/saili'
import { useAuth } from '../../../context/user/auth'
import { useNewRider } from '../../../context/rider/new'

const momentTz = require('moment-timezone')

const Index = () => {
  var auth = useAuth()
  var newRider = useNewRider()
  // console.log('Has New Rider', newRider)

  const overrideSpinner = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `

  const [filterRiders, setFilterRiders] = useState([])
  const [riders, setRiders] = useState([])
  const [disableInputs, setDisableInputs] = useState(false)
  const [processModalShow, setProcessModalShow] = useState(false)
  const [deletingRider, setDeletingRider] = useState(false)
  const [errorResponse, setErrorResponse] = useState()

  const [totalRidersCount, setTotalRidersCount] = useState()
  const [perPageRider, setPerPageRider] = useState()
  const [currentRidersPage, setCurrrentRidersPage] = useState()
  const [maxPageCount, setMaxPageCount] = useState()

  const [newModalShow, setNewModalShow] = useState(false)
  const [editModalShow, setEditModalShow] = useState(false)
  const [editModalProps, setEditModalProps] = useState()

  useEffect(async () => {
    await getSetRiders(1)
  }, [processModalShow])

  useEffect(async () => {
    if (newRider.newRider) {
      await getSetRiders(1)
      setTimeout(() => {
        newRider.resetCheckNewRider()
      }, 500)
    }
  }, [newRider])

  const handleOnCreateRiderModalHide = () => {
    newRider.checkNewRider()
    setNewModalShow(false)
  }

  const handleOnEditModalHide = () => {
    newRider.checkNewRider()
    setEditModalShow(false)
  }

  const getSetRiders = async (page) => {
    var riderResponse = await riderAPI.getPerPage(page, auth.user)
    if (riderResponse) {
      setFilterRiders(riderResponse.data)
      setRiders(riderResponse.data)

      setTotalRidersCount(riderResponse.totalRiders)
      setPerPageRider(riderResponse.perPage)
      setCurrrentRidersPage(riderResponse.currentPage)
      setMaxPageCount(
        Math.ceil(riderResponse.totalRiders / riderResponse.perPage)
      )
    }
  }

  const handleRiderEdit = async (riderId) => {
    var rider = riders.filter((rider) => {
      return rider._id == riderId
    })
    // console.log('Edit', rider)
    if (rider.length > 0) {
      setEditModalProps(rider[0])
      setEditModalShow(true)
    }
  }

  const handleRiderDelete = async (riderId) => {
    setDisableInputs(true)
    setProcessModalShow(true)
    setDeletingRider(true)

    var deleteResponse = await riderAPI.delete(riderId, auth.user)

    if (deleteResponse) {
      // console.log('Delete Response ', deleteResponse)
      if (deleteResponse.status == 'SUCCESS') {
        setDisableInputs(false)
        setProcessModalShow(false)
        setDeletingRider(false)
      } else {
        setDisableInputs(false)
        setDeletingRider(false)
        setErrorResponse(deleteResponse)
      }
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col-sm-12'>
          <article className='current-orders-list-wrapper'>
            <header className='current-orders-list-header'>
              <div
                className='btn btn-primary'
                onClick={() => setNewModalShow(true)}
              >
                New Rider
              </div>
              {newModalShow ? (
                <CreateRiderModal
                  visible={newModalShow}
                  onHide={handleOnCreateRiderModalHide}
                />
              ) : null}
            </header>
            <section className='current-orders-list-body'>
              {filterRiders ? (
                <>
                  {filterRiders.length > 0 ? (
                    <>
                      <div className='orders-header'>
                        <div className='row no-gutters'>
                          <div className='col-sm-2'>
                            <div className='orders-column-header'>Name</div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='orders-column-header'>
                              Mobile Number
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='orders-column-header'>Status</div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='orders-column-header'>Date</div>
                          </div>
                          <div className='col-sm-1'>
                            <div className='orders-column-header'></div>
                          </div>
                        </div>
                      </div>
                      <div className='orders-body'>
                        {filterRiders.map((rider, index) => {
                          let firstItem = index == 0 ? 'order-item-first' : ''
                          return (
                            <div
                              className={`order-item ${firstItem}`}
                              key={rider._id}
                            >
                              <div className='row no-gutters'>
                                <div className='col-md-2'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        <strong>{rider.full_name}</strong>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className='col-sm-3'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        {rider.mobile_number}
                                        {rider.alt_contact
                                          ? ', ' + rider.alt_contact
                                          : ''}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className='col-sm-3'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>{rider.available_status}</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className='col-sm-3'>
                                  <div className='column-group'>
                                    <ul className='order-item-detail-list'>
                                      <li>
                                        {moment(rider.date.creation).calendar()}
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div className='col-sm-1'>
                                  <div className='action-group'>
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant='outline-dark btn-sm'
                                        id='dropdown-basic'
                                      >
                                        <span className='material-icons'>
                                          menu
                                        </span>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleRiderEdit(rider._id)
                                          }
                                        >
                                          Edit Rider
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleRiderDelete(rider._id)
                                          }
                                        >
                                          Delete Rider
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  ) : (
                    <InfoBoxFull
                      icon='delivery_dining'
                      title='No Riders'
                      description="You don't have any riders."
                    />
                  )}
                </>
              ) : null}
            </section>
            {maxPageCount ? (
              <div className='pagination-wrapper'>
                <nav>
                  <ul className='pagination justify-content-center'>
                    {currentRidersPage ? (
                      <>
                        {currentRidersPage == 1 ? (
                          <>
                            <li className='page-item disabled'>
                              <a className='page-link' href='#page-1'>
                                First
                              </a>
                            </li>
                            <li className='page-item disabled'>
                              <a className='page-link' href='#page-prev'>
                                Previous
                              </a>
                            </li>
                          </>
                        ) : (
                          <>
                            <li className='page-item'>
                              <a
                                className='page-link'
                                href='#page-1'
                                onClick={() => getSetRiders(1)}
                              >
                                First
                              </a>
                            </li>
                            <li className='page-item'>
                              <a
                                className='page-link'
                                href='#page-prev'
                                onClick={() =>
                                  getSetRiders(currentRidersPage - 1)
                                }
                              >
                                Previous
                              </a>
                            </li>
                          </>
                        )}

                        {currentRidersPage > 2 ? (
                          <li className='page-item'>
                            <span className='page-link'>...</span>
                          </li>
                        ) : null}
                        <li className='page-item active'>
                          <a
                            className='page-link'
                            href={'#page-' + currentRidersPage}
                          >
                            {currentRidersPage}
                          </a>
                        </li>
                        {currentRidersPage != maxPageCount ? (
                          <li className='page-item'>
                            <a
                              className='page-link'
                              href={'#page-' + (currentRidersPage + 1)}
                              onClick={() =>
                                getSetRiders(currentRidersPage + 1)
                              }
                            >
                              {currentRidersPage + 1}
                            </a>
                          </li>
                        ) : null}
                        {maxPageCount > currentRidersPage + 1 ? (
                          <li className='page-item'>
                            <span className='page-link'>...</span>
                          </li>
                        ) : null}

                        {currentRidersPage == maxPageCount ? (
                          <>
                            <li className='page-item disabled'>
                              <a className='page-link' href='#page-next'>
                                Next
                              </a>
                            </li>
                            <li className='page-item disabled'>
                              <a
                                className='page-link'
                                href={'#page-' + maxPageCount}
                              >
                                Last
                              </a>
                            </li>
                          </>
                        ) : (
                          <>
                            <li className='page-item'>
                              <a
                                className='page-link'
                                href='#page-next'
                                onClick={() =>
                                  getSetRiders(currentRidersPage + 1)
                                }
                              >
                                Next
                              </a>
                            </li>
                            <li className='page-item'>
                              <a
                                className='page-link'
                                href={'#page-' + maxPageCount}
                                onClick={() => getSetRiders(maxPageCount)}
                              >
                                Last
                              </a>
                            </li>
                          </>
                        )}
                      </>
                    ) : null}
                  </ul>
                </nav>
              </div>
            ) : null}
          </article>
        </div>
      </div>
      <Modal
        size='xl'
        backdrop='static'
        keyboard={false}
        show={processModalShow}
        onHide={() => setProcessModalShow(false)}
        aria-labelledby='action-rider-title-xl'
        animation={false}
      >
        <Modal.Header closeButton={disableInputs ? false : true}>
          <Modal.Title id='action-rider-title-xl'>
            {deletingRider ? 'Deleting Rider' : ''}
            {errorResponse ? 'Error' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-body'>
          {deletingRider ? (
            <div className='dc-processing-indicator text-center'>
              <h6>Deleting</h6>
              <p>Please wait, your request is being processed.</p>
              <BeatLoader
                color='#0098e3'
                loading={true}
                css={overrideSpinner}
              />
            </div>
          ) : null}
          {errorResponse ? (
            <div className='alert alert-danger' role='alert'>
              <h4 className='alert-heading'>{errorResponse.title}</h4>
              <p>{errorResponse.message}</p>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>

      {editModalShow ? (
        <EditRiderModal
          riderInfo={editModalProps}
          visible={editModalShow}
          onHide={handleOnEditModalHide}
        />
      ) : null}
    </>
  )
}

export default Index
