import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

//Page Components
import Login from './components/pages/login'
import Logout from './components/pages/logout'
import Register from './components/pages/register'
import VerifyEmail from './components/pages/verify-email'
import PersonalProfile from './components/pages/my-profile'
import { useAuth, ProvideAuth } from './context/user/auth'

import TestingRoute from './components/pages/testing/routes'

import PartnerOrders from './components/pages/partner-orders'
import PartnerCustomers from './components/pages/partner-customers'
import PartnerRiders from './components/pages/partner-riders'
import PartnerRoutes from './components/pages/partner-routes'

import Zoning from './components/pages/zoning'

//Error Page Components
import Error404 from './components/pages/errors/404'

function App() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route path='/register'>
            <Register />
          </Route>
          <Route path='/verify/partner/email/:id'>
            <VerifyEmail />
          </Route>
          <Route path='/login'>
            <Login />
          </Route>
          <PrivateRoute path='/my-profile'>
            <PersonalProfile />
          </PrivateRoute>
          <PrivateRoute path='/orders'>
            <PartnerOrders />
          </PrivateRoute>
          <PrivateRoute path='/customers'>
            <PartnerCustomers />
          </PrivateRoute>
          <PrivateRoute path='/riders'>
            <PartnerRiders />
          </PrivateRoute>
          <PrivateRoute path='/routes'>
            <PartnerRoutes />
          </PrivateRoute>

          <PrivateRoute path='/logout'>
            <Logout />
          </PrivateRoute>
          <Route path='/zoning'>
            <Zoning />
          </Route>
          <Route path='*'>
            <Error404 />
          </Route>
        </Switch>
      </Router>
    </ProvideAuth>
  )
}

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default App
