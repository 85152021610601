export const serviceArea = {
  check: (lat, lng) => {
    // var response = isPointWithinRadius(
    //   {
    //     latitude: parseFloat(lat),
    //     longitude: parseFloat(lng),
    //   },
    //   {
    //     latitude: parseFloat(googleSettings.mapSearchCenter.split(',')[0]),
    //     longitude: parseFloat(googleSettings.mapSearchCenter.split(',')[1]),
    //   },
    //   parseInt(googleSettings.mapSearchRadius)
    // )
    // return response

    // TODO
    return true
  },
}
