import React from 'react'

const Index = (props) => {
  return (
    <div className='app-page-container'>
      <div className='container-fluid'>{props.children}</div>
    </div>
  )
}

export default Index
