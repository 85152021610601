import Axios from 'axios'
import validator from 'validator'
import { getDistance, isPointWithinRadius } from 'geolib'

export const generateSessionToken = () => {
  //create UUID
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    }
  )
  return uuid
}

//Generate Random Number
export const generateRandomNumber = (min, max) => {
  const random = Math.floor(Math.random() * (max - min + 1)) + min
  return random
}

//Get Random Google Key
const getKey = () => {
  const keyList = [
    {
      key: 'AIzaSyAxImDjAj6BZWk7-cm22oxISUoHNM2rbmE',
    },
  ]
  let keyLength = keyList.length
  let randomNum = generateRandomNumber(0, keyLength - 1)
  return keyList[randomNum].key
}

export const googleSettings = {
  key: getKey(),
}

export const mapSettings = {
  zoom: 12,
  zoomRingRoad: 13,
  pointZoom: 17,
  insideValley: {
    searchCenter: '27.695179,85.345356',
    mapCenter: [27.695179, 85.345356],
    searchRadius: '12345',
  },
}

export const googleAPI = {
  //Get Lat/Lng From Place Id
  getLatLngFromPlaceId: async function (placeId) {
    try {
      let locationDetailsResponse = await Axios.get(
        `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=geometry&key=${googleSettings.key}`,
        { headers: { 'Access-Control-Allow-Origin': '*' } }
      )
      if (locationDetailsResponse.status == 200) {
        return locationDetailsResponse.data
      } else {
        return false
      }
    } catch (error) {
      // console.log('Error Getting Place Lat/Lng : ' + error)
    }
  },

  //Get Location Details From Lat/Lng
  getLocationDetailsFromLatLng: async function (lat, lng, sessionToken) {
    try {
      let locationDetailsResponse = await Axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleSettings.key}&sessiontoken=${sessionToken}`,
        { headers: { 'Access-Control-Allow-Origin': '*' } }
      )
      if (locationDetailsResponse.status == 200) {
        return locationDetailsResponse.data
      } else {
        return false
      }
    } catch (error) {
      // console.log('Error Getting Place Details : ' + error)
    }
  },

  //Get Location Details From Place Id
  getLocationDetailsFromPlaceId: async function (placeId) {
    try {
      let locationDetailsResponse = await Axios.get(
        `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=address_component,formatted_address,geometry,place_id,plus_code&key=${googleSettings.key}`,
        { headers: { 'Access-Control-Allow-Origin': '*' } }
      )
      if (locationDetailsResponse.status == 200) {
        return locationDetailsResponse.data
      } else {
        return false
      }
    } catch (error) {
      // console.log('Error Getting Place Details : ' + error)
    }
  },

  //Search Inside Valley Location
  searchInsideValleyLocation: async function (searchInput, sessionToken) {
    try {
      // console.log('Session Token : ' + sessionToken)
      let searchLocationResponse = await Axios.get(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${googleSettings.key}&input=${searchInput}&location=${mapSettings.insideValley.searchCenter}&radius=${mapSettings.insideValley.searchRadius}&strictbounds&components=country:np&sessiontoken=${sessionToken}`,
        { headers: { 'Access-Control-Allow-Origin': '*' } }
      )
      if (searchLocationResponse.status == 200) {
        return searchLocationResponse.data.predictions
      } else {
        return false
      }
    } catch (error) {
      // console.log('Error Searching Location : ' + error)
    }
  },
}

export const sailiLibrary = {
  //Check if the address in within service area
  isServiceAvailable: async function (lat, lng) {
    // var response = isPointWithinRadius(
    //   {
    //     latitude: parseFloat(lat),
    //     longitude: parseFloat(lng),
    //   },
    //   {
    //     latitude: parseFloat(googleSettings.mapSearchCenter.split(',')[0]),
    //     longitude: parseFloat(googleSettings.mapSearchCenter.split(',')[1]),
    //   },
    //   parseInt(googleSettings.mapSearchRadius)
    // )
    // return response

    // TODO
    return true
  },

  //Convert to decimal degree
  convertToDecimalDegree: async function (degree, minutes, seconds, direction) {
    var dd =
      parseFloat(degree) + parseFloat(minutes) / 60 + parseFloat(seconds) / 3600
    if (direction == 'S' || direction == 'W') {
      dd *= -1
    }

    return dd
  },

  //Parse & Convert to decimal degree
  ParseAndConvertToDecimalDegree: async function (value) {
    var parts = value.split(/[^\d\w\.]+/)
    // console.log(parts);
    var lat = await this.convertToDecimalDegree(
      parts[0],
      parts[1],
      parts[2],
      parts[3]
    )
    var lng = await this.convertToDecimalDegree(
      parts[4],
      parts[5],
      parts[6],
      parts[7]
    )

    return {
      Latitude: parseFloat(lat).toFixed(6),
      Longitude: parseFloat(lng).toFixed(6),
      Position: parseFloat(lat).toFixed(6) + ',' + parseFloat(lng).toFixed(6),
    }
  },

  isValidPlusCodeCharacters: function (string) {
    return /^[a-z0-9]+$/i.test(string)
  },

  isValidPlusCode: function (plusCode) {
    plusCode = plusCode.trim()
    var plusCodeLength = plusCode.length
    if (plusCodeLength > 6) {
      var plusCharIndex = plusCode.indexOf('+')
      if (plusCharIndex == 4 || plusCharIndex == 8) {
        var beforePlus = ''
        var afterPlus = ''
        var plusCodeType = ''

        switch (plusCharIndex) {
          case 4:
            beforePlus = plusCode.substring(0, 4)
            afterPlus = plusCode.substring(5, 7)
            plusCodeType = 'COMPOUND'
            break

          case 8:
            beforePlus = plusCode.substring(0, 8)
            afterPlus = plusCode.substring(9, 11)
            plusCodeType = 'GLOBAL'
            break

          default:
            break
        }

        if (
          this.isValidPlusCodeCharacters(beforePlus) &&
          this.isValidPlusCodeCharacters(afterPlus)
        ) {
          return { status: true, type: plusCodeType }
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  },
}
